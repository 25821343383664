<template>
  <div class="app-home-wrapper">
    <section class="section-item section-one">
      <div class="inner-container">
        <div class="title-text-container">
          {{ `Technological innovation drives the future` }}
        </div>
        <div class="summary-text-container">
          {{
            `We provide leading design software and customized development services, one-stop system integration and information technology solutions`
          }}
        </div>
        <a class="contact-us-button no-user-select" href="mailto:bi.tech@hotmail.com">{{ `Contact Us` }}</a>
      </div>
    </section>
    <section class="section-item section-two" id="about-us">
      <div class="inner-container">
        <div class="title-text-container">
          {{ `About Us` }}
        </div>
        <div class="summary-text-container">
          {{
            `We are BITECH Service PTE.LTD, established in 2023, a company focusing on technological innovation and information technology services.`
          }}
        </div>
        <div class="content-container">
          <div class="content-left-part">
            <div class="paragraph-wrapper">
              <div class="paragraph-title">{{ `Our Mission and Vision` }}</div>
              <div class="paragraph-content">
                <div class="content-row-item">
                  {{
                    `Our mission is to promote the digitalization of enterprises through innovative scientific and technological means, provide customers with excellent technical solutions and enhance corporate competitiveness.`
                  }}
                </div>
                <div class="content-row-item">
                  {{
                    `Our vision is to become a leading global information technology service provider, creating a smarter and more efficient future for customers with intelligent and automated technologies.`
                  }}
                </div>
              </div>
            </div>
            <div class="paragraph-wrapper">
              <div class="paragraph-title">{{ `Core Values` }}</div>
              <div class="paragraph-content">
                <div class="content-row-item">
                  {{
                    `Innovation: Continuously break through the limits of technology and lead the industry trend.`
                  }}
                </div>
                <div class="content-row-item">
                  {{
                    `Professional: Provide customers with professional solutions with deep technical accumulation.`
                  }}
                </div>
                <div class="content-row-item">
                  {{
                    `Integrity: Establish a long-term and stable trust relationship with customers to ensure that every delivery exceeds expectations.`
                  }}
                </div>
                <div class="content-row-item">
                  {{
                    `Collaboration: We work closely with customers to face challenges together and share success.`
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="content-right-part"></div>
        </div>
      </div>
    </section>
    <section class="section-item section-three" id="our-services">
      <div class="inner-container">
        <div class="title-text-container">
          {{ `OUR Services` }}
        </div>
        <div class="summary-text-container">
          {{
            `We offer a range of professional services, covering design, development, system integration and technical support, dedicated to providing tailor-made solutions for customers in different industries.`
          }}
        </div>
        <div class="content-container">
          <div class="content-list-wrapper">
            <div class="list-item-wrapper bg-one image-h"></div>
            <div class="list-item-wrapper">
              <div class="part-title">{{ `Software Design Services` }}</div>
              <div class="part-summary">
                {{
                  `We have a strong UI/UX design team that provides professional software design solutions to help companies create product interfaces and experiences that meet user needs.`
                }}
              </div>
              <div class="detail-info-wrapper">
                <div class="detail-title">{{ `Service Content:` }}</div>
                <ul class="detail-content">
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `UI Design` }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `UX Design` }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `APP and Web Design` }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="content-list-wrapper">
            <div class="list-item-wrapper rtl-row">
              <div class="part-title">{{ `Custom development services` }}</div>
              <div class="part-summary">
                {{
                  `Our development team is dedicated to providing personalized software development services for enterprises. Whether it is web applications, mobile applications, or enterprise-level system development, we can provide high-quality solutions.`
                }}
              </div>
              <div class="detail-info-wrapper">
                <div class="detail-title">{{ `Service Content:` }}</div>
                <ul class="detail-content">
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{
                        `Software Customization Development (Web & Mobile App)`
                      }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{
                        `Enterprise application development (ERP, CRM, MIS systems)`
                      }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `Server development and data processing` }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="list-item-wrapper bg-two rtl-row image-h"></div>
          </div>
          <div class="content-list-wrapper">
            <div class="list-item-wrapper bg-three image-h"></div>
            <div class="list-item-wrapper">
              <div class="part-title">{{ `System Integration` }}</div>
              <div class="part-summary">
                {{
                  `We provide professional system integration services to help enterprises integrate different information systems and hardware to improve overall operational efficiency.`
                }}
              </div>
              <div class="detail-info-wrapper">
                <div class="detail-title">{{ `Service Content:` }}</div>
                <ul class="detail-content">
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `System planning and design` }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `Information system integration` }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `Network architecture and security solutions` }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="content-list-wrapper">
            <div class="list-item-wrapper rtl-row">
              <div class="part-title">
                {{ `Information Technology Services` }}
              </div>
              <div class="part-summary">
                {{
                  `Our IT services help enterprises cope with technological changes and challenges, provide continuous technical support and maintenance, and ensure high availability and security of systems.`
                }}
              </div>
              <div class="detail-info-wrapper">
                <div class="detail-title">{{ `Service Content:` }}</div>
                <ul class="detail-content">
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `Cloud computing and big data solutions` }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `Enterprise IT Outsourcing Services` }}
                    </div>
                  </li>
                  <li class="detail-row-item">
                    <div class="icon-wrapper">
                      <div class="dot-icon"></div>
                    </div>
                    <div class="text-wrapper">
                      {{ `Technical consulting and training` }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="list-item-wrapper bg-four rtl-row image-h"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-item section-four" id="our-advantages">
      <div class="inner-container">
        <div class="title-text-container">
          {{ `Our Advantages` }}
        </div>
        <div class="summary-text-container">
          {{
            `As an industry innovator, we keep up with global technological trends and are committed to taking the lead in the following technologies:`
          }}
        </div>
        <div class="content-container">
          <div class="content-list-wrapper">
            <div class="list-item-wrapper">
              <div class="item-up-bg bg-one"></div>
              <div class="item-down-container">
                <div class="down-title">{{ `AI and Machine Learning` }}</div>
                <div class="down-summary">
                  {{
                    `Provide customers with intelligent solutions and improve automation level`
                  }}
                </div>
              </div>
            </div>
            <div class="list-item-wrapper">
              <div class="item-up-bg bg-two"></div>
              <div class="item-down-container">
                <div class="down-title">{{ `Big Data and Data Analysis` }}</div>
                <div class="down-summary">
                  {{
                    `Help enterprises optimize operational efficiency through data-driven decision making`
                  }}
                </div>
              </div>
            </div>
            <div class="list-item-wrapper">
              <div class="item-up-bg bg-three"></div>
              <div class="item-down-container">
                <div class="down-title">{{ `Cloud Computing` }}</div>
                <div class="down-summary">
                  {{
                    `Provide enterprises with highly scalable, flexible and secure information technology architecture`
                  }}
                </div>
              </div>
            </div>
            <div class="list-item-wrapper">
              <div class="item-up-bg bg-four"></div>
              <div class="item-down-container">
                <div class="down-title">{{ `Internet of Things (IoT)` }}</div>
                <div class="down-summary">
                  {{
                    `Promote the digital transformation of enterprises through the connection and management of smart devices`
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-item section-five">
      <div class="inner-container">
        <div class="title-text-container">
          {{ `Customer Testimonials` }}
        </div>
        <div class="summary-text-container">
          {{
            `We are always committed to providing customers with high-quality and innovative services, listening and understanding customers' needs, and ensuring the successful implementation of each project.`
          }}
        </div>
        <div class="content-container">
          <div class="content-row-item">
            <div class="item-up-wrapper">
              <div class="up-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="57"
                  height="50"
                  viewBox="0 0 57 50"
                  fill="none"
                >
                  <path
                    d="M23.0507 49.9999H0V36.7796C0 22.373 4.74573 10.1132 14.2372 0.000244141H24.4066C17.3445 8.24877 13.2485 17.2318 12.1186 26.9492H23.0507V49.9999ZM54.9996 49.9999H31.7794V36.7796C31.7794 22.5424 36.4969 10.2827 45.9319 0.000244141H56.3555C49.2934 8.47476 45.1409 17.4577 43.898 26.9492H54.9996V49.9999Z"
                    fill="#398CFE"
                  />
                </svg>
              </div>
              <div class="up-right icon-one"></div>
            </div>
            <div class="item-down-wrapper">
              {{
                `The ERP system tailored for us by BITECH solved many pain points in our management, improved business efficiency, and greatly promoted the development of the company.`
              }}
            </div>
          </div>
          <div class="content-row-item">
            <div class="item-up-wrapper">
              <div class="up-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="57"
                  height="50"
                  viewBox="0 0 57 50"
                  fill="none"
                >
                  <path
                    d="M23.0507 49.9999H0V36.7796C0 22.373 4.74573 10.1132 14.2372 0.000244141H24.4066C17.3445 8.24877 13.2485 17.2318 12.1186 26.9492H23.0507V49.9999ZM54.9996 49.9999H31.7794V36.7796C31.7794 22.5424 36.4969 10.2827 45.9319 0.000244141H56.3555C49.2934 8.47476 45.1409 17.4577 43.898 26.9492H54.9996V49.9999Z"
                    fill="#398CFE"
                  />
                </svg>
              </div>
              <div class="up-right icon-two"></div>
            </div>
            <div class="item-down-wrapper">
              {{
                `Their development team is highly skilled and provides excellent service, making them an important partner in our digital transformation process.`
              }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="partners-show-container">
      <div class="swiper-outer-wrapper">
        <div class="swiper-inner-container">
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="114"
              height="35"
              viewBox="0 0 114 35"
              fill="none"
            >
              <g clip-path="url(#clip0_114_167)">
                <path
                  d="M77.8341 21.2528C77.8341 17.2903 74.6183 14.0765 70.6559 14.0783C66.6934 14.0783 63.4796 17.294 63.4814 21.2565C63.4814 25.219 66.6952 28.431 70.6577 28.431C74.6202 28.431 77.8341 25.2172 77.8341 21.2547V21.2528ZM112.302 21.2528C112.296 28.4588 106.448 34.2958 99.2421 34.2902C93.8828 34.2864 89.0694 31.0038 87.1095 26.016L92.077 21.0484V21.2547C92.0863 25.2116 95.302 28.4105 99.2589 28.3994C103.216 28.3882 106.415 25.1744 106.404 21.2175C106.394 17.2606 103.179 14.0616 99.2218 14.0727C97.3288 14.0783 95.5137 14.8307 94.1762 16.1701L79.9351 30.4187C74.8858 35.5478 66.6339 35.6128 61.503 30.5636C59.0081 28.1078 57.6074 24.7527 57.613 21.2528V2.94701C57.7133 1.32524 59.1084 0.09358 60.7283 0.192038C62.2107 0.283065 63.3904 1.46456 63.4832 2.94701V10.2682C69.5022 6.30573 77.5943 7.97395 81.5568 13.9929C82.4653 15.3732 83.1025 16.9132 83.4368 18.5313L90.028 11.942C95.1218 6.84632 103.383 6.84447 108.479 11.9383C110.948 14.4072 112.326 17.7622 112.302 21.2528ZM55.6586 21.2528C55.6717 28.4513 49.8477 34.2994 42.6473 34.3125C37.2674 34.3236 32.4336 31.0261 30.4793 26.016L35.4468 21.0484V21.2547C35.4561 25.2116 38.6718 28.4105 42.6288 28.3994C46.5857 28.39 49.7846 25.1744 49.7734 21.2175C49.7641 17.2606 46.5485 14.0616 42.5916 14.0727C40.6986 14.0783 38.8836 14.8307 37.5461 16.1701L23.2547 30.4744C18.1591 35.5682 9.89788 35.5664 4.80407 30.4707C-0.289756 25.375 -0.287891 17.1138 4.80778 12.02C9.90159 6.92807 18.1609 6.92807 23.2547 12.02C24.401 13.1718 24.3954 15.0369 23.2418 16.1812C22.0899 17.3275 20.2248 17.3219 19.0805 16.1683C16.2735 13.3706 11.7296 13.378 8.93188 16.185C6.13417 18.992 6.14161 23.5359 8.94859 26.3336C11.75 29.1257 16.281 29.1257 19.0805 26.3336L33.396 11.981C38.4805 6.88534 46.7342 6.87605 51.8318 11.9606C54.2989 14.422 55.6772 17.7696 55.6586 21.2565V21.2509V21.2528Z"
                  fill="#C7CED6"
                />
              </g>
              <defs>
                <clipPath id="clip0_114_167">
                  <rect
                    width="112.128"
                    height="34.1259"
                    fill="white"
                    transform="translate(0.984863 0.186523)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <g clip-path="url(#clip0_114_242)">
                <path
                  d="M31.1868 0.844785C31.8593 0.305713 32.5345 -0.236226 33.2585 -0.703613C33.7159 -0.227624 34.1331 0.28134 34.5776 0.767365C35.4335 1.64766 36.1518 2.66129 37.0865 3.46559C37.627 3.03978 38.0399 2.48781 38.5045 1.98458C39.2027 1.1817 39.9167 0.391735 40.6106 -0.415439C41.1095 -0.411138 41.4593 -0.0240388 41.8321 0.252666C42.2923 0.601055 42.7253 0.983854 43.1253 1.39963C42.0213 2.8319 40.7439 4.11936 39.5769 5.49858C38.7511 6.3416 38.0657 7.32368 37.1697 8.09502C37.1267 8.08928 37.0406 8.07925 36.9976 8.07494C36.3783 7.55738 35.8722 6.91795 35.3403 6.31436C33.8851 4.75162 32.5274 3.10143 31.0607 1.54873C30.8686 1.33941 30.9489 0.985288 31.1868 0.844785Z"
                  fill="#C7CED6"
                />
                <path
                  d="M47.5324 0.97824C47.6629 0.824834 47.8593 0.76892 48.0385 0.695801C48.0543 4.86931 48.0686 9.04568 48.0313 13.2192C46.989 13.1747 45.9195 13.3583 44.8987 13.0945C44.8872 11.7253 44.9102 10.3575 44.8886 8.98833C44.1331 9.1317 43.8263 9.89873 43.3159 10.379C42.4485 11.2392 41.7431 12.2543 40.8585 13.0916C39.5008 13.1518 38.1316 13.1418 36.7725 13.0859C37.9194 11.5977 39.2642 10.2715 40.4857 8.84353C42.8385 6.22416 45.1854 3.60192 47.5324 0.97824Z"
                  fill="#C7CED6"
                />
                <path
                  d="M26.1789 1.39803C26.3523 1.24319 26.5789 1.19444 26.7997 1.1543C28.5932 3.20592 30.4499 5.2002 32.2305 7.2633C32.7252 7.86832 33.3976 8.34145 33.7044 9.0841C29.7646 9.14145 25.8233 9.09414 21.8821 9.10991C21.1925 9.10561 20.5028 9.12568 19.8132 9.08554C20.186 8.37872 20.6964 7.75793 21.0706 7.05255C21.3 6.67118 21.5294 6.2038 22.0097 6.1063C22.9229 6.07906 23.8434 6.04609 24.7466 6.20236C25.4118 6.19949 26.1 6.30129 26.7437 6.09197C26.695 5.92422 26.7022 5.72637 26.5875 5.58444C25.8133 4.73855 24.9975 3.91991 24.3237 2.98944C24.8756 2.38585 25.5351 1.89695 26.1789 1.39803Z"
                  fill="#C7CED6"
                />
                <path
                  d="M51.3361 4.00469C51.3289 3.62046 51.3217 3.22476 51.4981 2.87207C52.0759 3.50577 52.6795 4.11366 53.2286 4.77316C53.6357 5.26348 54.1748 5.65345 54.473 6.22837C54.5763 7.81547 54.4286 9.41405 54.5447 11.0026C55.7705 10.9711 56.6867 9.837 57.934 9.92732C58.4946 10.7173 59.3032 11.3123 59.7476 12.184C57.5497 13.2205 55.3003 14.1453 53.0823 15.1374C52.5347 15.4026 51.9813 15.6879 51.3676 15.7539C51.3002 11.8384 51.3633 7.92157 51.3361 4.00469Z"
                  fill="#C7CED6"
                />
                <path
                  d="M19.8391 11.972C24.2019 11.9448 28.5646 11.9749 32.9259 11.9562C33.184 11.9304 33.4206 12.0322 33.6586 12.1154C33.2098 12.8236 32.5503 13.3555 32.0342 14.0107C30.503 15.7297 28.9618 17.4387 27.4449 19.1706C27.0922 19.5391 26.7539 20.2029 26.1417 19.9735C25.5524 19.5577 24.999 19.0918 24.4384 18.6387C24.2019 18.4953 24.1273 18.1355 24.3237 17.9305C25.1266 17.0688 25.875 16.1555 26.7281 15.3398C26.6707 15.2451 26.5574 15.0545 26.5001 14.9598C24.8943 14.9899 23.2872 14.967 21.6814 14.9727C21.2814 14.4867 20.9689 13.9405 20.6578 13.3971C20.3897 12.9182 19.9638 12.5225 19.8391 11.972Z"
                  fill="#C7CED6"
                />
                <path
                  d="M111.028 15.77C112.395 15.3887 113.821 15.5822 115.219 15.5349C116.638 15.4546 118.02 15.8948 119.344 16.3636C120.978 16.9729 122.516 17.8819 123.77 19.1034C124.076 19.4016 124.486 19.6697 124.518 20.1385C123.133 21.307 121.676 22.3909 120.29 23.5594C120.048 23.7443 119.686 24.0583 119.41 23.7529C118.243 22.6719 116.803 21.8604 115.226 21.5866C114.645 21.5565 114.063 21.5536 113.486 21.5937C111.877 21.9006 110.307 22.7464 109.412 24.1572C108.862 24.9414 108.667 25.8934 108.45 26.8096C108.347 27.5651 108.383 28.345 108.633 29.0705C108.994 30.6461 109.98 32.1329 111.454 32.8612C112.681 33.4691 114.111 33.8046 115.468 33.4877C116.509 33.2755 117.514 32.8684 118.397 32.2748C118.846 31.9795 119.189 31.5379 119.665 31.2856C119.95 31.2942 120.159 31.5135 120.375 31.6741C121.724 32.8139 123.123 33.8949 124.488 35.0175C124.465 35.1637 124.462 35.3114 124.478 35.4591C122.548 37.3387 120.165 38.8598 117.488 39.3674C117.008 39.4706 116.534 39.6254 116.04 39.6269C114.835 39.6498 113.629 39.6555 112.425 39.6139C109.845 39.2139 107.246 38.3595 105.25 36.6161C104.559 35.9709 103.875 35.3042 103.336 34.5243C102.182 32.887 101.563 30.9114 101.388 28.9286C101.374 28.0769 101.375 27.2253 101.385 26.3751C101.574 24.249 102.281 22.15 103.523 20.4023C104.007 19.707 104.644 19.1407 105.248 18.5514C106.849 17.0776 108.916 16.2073 111.028 15.77Z"
                  fill="#C7CED6"
                />
                <path
                  d="M75.7291 15.8241C76.8546 15.9245 77.97 15.5331 79.0883 15.7739C79.9471 15.86 80.8245 15.8571 81.6761 15.7023C82.1879 15.6005 82.6267 15.9947 82.8675 16.4076C85.1342 20.0191 87.4138 23.622 89.6833 27.2321C89.9987 27.7124 90.1923 28.3375 90.7744 28.5669C90.8088 24.379 90.7859 20.1883 90.7844 15.999C91.6203 15.3453 92.6454 16.0076 93.5687 15.751C94.231 15.6449 94.9063 15.718 95.5744 15.698C96.2597 15.7266 96.9895 15.5804 97.6275 15.9073C97.6963 17.5761 97.6346 19.2492 97.6562 20.9195C97.6633 27.0142 97.6404 33.1088 97.6676 39.2035C97.4067 39.2766 97.1429 39.3483 96.8719 39.3383C94.8088 39.3368 92.7457 39.3311 90.6812 39.3397C90.003 38.5798 89.5328 37.6709 88.9837 36.8207C87.2016 34.0178 85.4123 31.2221 83.6216 28.4249C83.2589 27.8443 82.9048 27.2565 82.489 26.7131C82.3686 28.7446 82.4661 30.7848 82.4345 32.8192C82.436 34.6859 82.4302 36.5526 82.4388 38.4193C82.4532 38.7347 82.3729 39.0415 82.2912 39.3426C80.175 39.3125 78.0574 39.3698 75.9427 39.3168C75.3248 39.1117 75.7076 38.4365 75.6961 37.9834C75.7119 31.149 75.6933 24.3131 75.7047 17.4786C75.7277 16.9209 75.504 16.3646 75.7291 15.8241Z"
                  fill="#C7CED6"
                />
                <path
                  d="M127.78 16.0175C128.498 15.4612 129.446 15.7451 130.268 15.8168C130.792 15.7494 131.324 15.6189 131.848 15.7752C132.799 15.8641 133.761 15.8111 134.716 15.8082C135.616 15.6433 136.534 15.6978 137.444 15.705C138.492 15.8784 139.56 15.8082 140.61 15.9946C142.062 16.2956 143.575 16.7845 144.621 17.8928C145.266 18.5294 145.717 19.3638 145.867 20.2584C145.979 20.8763 146.227 21.51 146.01 22.1351C145.846 23.2692 145.437 24.4032 144.617 25.2305C144.031 25.857 143.246 26.2326 142.511 26.6498C143.232 27.1703 144.138 27.3237 144.868 27.8298C146.066 28.5366 146.869 29.8054 147.153 31.1517C147.328 31.7897 147.258 32.4592 147.272 33.1115C147.295 33.9775 147.06 34.8334 146.71 35.6176C146.31 36.5453 145.563 37.2607 144.8 37.8972C143.285 38.9324 141.414 39.3998 139.589 39.3367C135.817 39.3338 132.045 39.3381 128.273 39.3352C127.97 39.3983 127.762 39.1001 127.785 38.8263C127.778 31.2234 127.791 23.6204 127.78 16.0175ZM134.882 21.2849C134.69 21.2993 134.377 21.3709 134.367 21.6046C134.324 22.5251 134.367 23.4484 134.349 24.3703C134.376 24.5738 134.29 24.8319 134.455 24.9925C135.434 25.0355 136.42 25.0398 137.4 24.9925C138.069 24.8247 138.822 24.5079 139.089 23.814C139.49 23.0957 139.033 22.181 138.37 21.8011C137.309 21.2018 136.059 21.2505 134.882 21.2849ZM134.35 29.8828C134.376 31.1531 134.31 32.4277 134.38 33.6965C134.433 33.7395 134.541 33.827 134.594 33.87C135.633 33.9302 136.679 33.8857 137.721 33.8929C138.532 33.794 139.413 33.5675 139.952 32.9008C140.398 32.528 140.22 31.8943 140.244 31.3868C140.279 30.9036 139.83 30.5983 139.519 30.3015C138.542 29.5875 137.285 29.7352 136.148 29.7323C135.547 29.7581 134.918 29.6334 134.35 29.8828Z"
                  fill="#C7CED6"
                />
                <path
                  d="M157.44 15.8339C158.328 15.8669 159.208 15.5457 160.091 15.7708C160.956 15.9572 161.81 15.6231 162.679 15.6948C163.086 15.6891 163.479 15.8138 163.883 15.8568C165.022 18.5737 166.024 21.3479 167.1 24.0892C168.659 28.1236 170.216 32.1566 171.77 36.191C172.123 37.0326 172.358 37.9272 172.844 38.7086C172.974 38.8921 172.961 39.123 173 39.3366C170.64 39.3308 168.282 39.3452 165.922 39.328C165.452 38.1179 165.092 36.8677 164.705 35.6276C162.173 35.576 159.639 35.6176 157.108 35.6061C156.751 35.5502 156.56 35.9172 156.471 36.2025C156.125 37.2477 155.824 38.3086 155.409 39.3308C153.057 39.3308 150.706 39.3552 148.356 39.3194C149.945 35.1258 151.598 30.9566 153.188 26.763C154.629 23.1286 155.971 19.4554 157.44 15.8339ZM160.378 24.1293C160.24 24.8748 159.979 25.5859 159.746 26.3042C159.379 27.3365 159.082 28.3931 158.692 29.4182C158.593 29.6476 158.58 29.8985 158.55 30.1437C159.232 30.3716 159.955 30.2813 160.662 30.3C161.367 30.2684 162.095 30.3903 162.781 30.1595C162.761 30.006 162.739 29.8526 162.715 29.7007C162.143 27.8397 161.397 26.0261 160.953 24.1279C160.759 24.1365 160.567 24.1365 160.378 24.1293Z"
                  fill="#C7CED6"
                />
                <path
                  d="M54.3223 15.9604C59.1439 15.9704 63.9668 15.9245 68.787 16.0034C68.397 16.5941 67.769 16.9453 67.2271 17.374C64.3023 19.6579 61.3761 21.9403 58.4543 24.2271C57.0894 25.2594 55.7861 26.3705 54.394 27.3669C54.3008 27.0916 54.1976 26.8121 54.2191 26.5167C54.2248 25.6981 54.2205 24.8809 54.2205 24.0622C54.1747 23.6235 54.5747 23.3368 54.87 23.0844C55.7675 22.4063 56.6507 21.7109 57.5324 21.0127C58.3496 20.3575 59.2514 19.797 59.9381 18.9941C59.6872 18.9066 59.4292 18.8278 59.1611 18.8464C57.551 18.8536 55.9424 18.8478 54.3338 18.8493C54.1503 17.9059 54.1675 16.9066 54.3223 15.9604Z"
                  fill="#C7CED6"
                />
                <path
                  d="M33.6615 16.501C33.888 16.2687 34.1117 15.9175 34.483 15.9748C38.4071 15.9705 42.3311 15.9762 46.2552 15.9719C46.7756 15.9805 47.3175 15.9074 47.8093 16.1253C46.7785 17.0888 45.6028 17.8802 44.5247 18.7877C42.7311 20.1928 40.9361 21.5964 39.1612 23.0229C38.8874 23.2293 38.6178 23.5333 38.2393 23.4802C37.8293 23.5304 37.6601 23.1003 37.4121 22.8623C37.0321 22.3706 36.3726 22.0107 36.3683 21.3168C37.0823 20.6931 37.8637 20.1526 38.5949 19.549C38.7741 19.4057 38.8558 19.1863 38.9662 18.9942C36.5777 18.9784 34.1891 18.9913 31.802 18.9899C31.8221 18.8738 31.8608 18.6429 31.8809 18.5268C32.4357 17.8185 33.0981 17.2035 33.6615 16.501Z"
                  fill="#C7CED6"
                />
                <path
                  d="M49.0852 18.8708C49.7217 18.3905 50.2795 17.7668 51.0436 17.4844C51.0766 21.3898 51.0465 25.2981 51.058 29.2035C50.3153 29.2393 49.7519 28.6859 49.1139 28.3949C48.7053 28.1296 48.1289 28.002 47.9325 27.5103C47.8823 26.3102 47.9569 25.1088 47.8966 23.9088C46.7669 24.3733 46.0113 25.417 44.9604 26.0135C44.7482 26.0206 44.569 25.8887 44.3855 25.8056C43.6687 25.4156 42.9532 25.0242 42.2393 24.6299C42.2851 24.3045 42.4787 24.045 42.7425 23.86C44.8629 22.2041 46.9733 20.5367 49.0852 18.8708Z"
                  fill="#C7CED6"
                />
                <path
                  d="M38.3596 25.9492C38.9589 26.0983 39.4822 26.4352 40.0141 26.7349C41.6572 27.6209 43.2959 28.5155 44.9389 29.403C45.8522 29.9191 46.8314 30.332 47.6687 30.9743C46.8873 31.5622 45.9726 31.9263 45.1339 32.4181C43.4292 33.34 41.7217 34.259 40.017 35.1808C39.4593 35.4719 38.936 35.8747 38.2966 35.9593C37.7776 35.1536 37.2729 34.3392 36.8098 33.5005C38.2005 32.5443 39.7819 31.9034 41.1855 30.9686C40.4959 30.3722 39.6399 30.0439 38.86 29.5937C38.2363 29.2396 37.5955 28.9184 36.9604 28.5844C36.9948 28.3793 36.9847 28.1557 37.098 27.9722C37.4951 27.284 37.9396 26.6245 38.3596 25.9492Z"
                  fill="#C7CED6"
                />
                <path
                  d="M58.354 28.4835C58.7497 28.3115 58.6207 28.8505 58.6508 29.0785C58.6049 29.9903 58.7325 30.9237 58.5877 31.8226C57.2185 32.6929 55.7289 33.3638 54.3339 34.1954C54.3253 36.4176 54.3325 38.6399 54.3311 40.8621C54.3913 41.2578 53.9913 41.4628 53.7074 41.6334C52.8443 42.0836 52.0156 42.6083 51.1081 42.9668C51.0364 42.6499 50.926 42.3359 50.9404 42.0062C50.9447 38.8721 50.906 35.7366 50.949 32.604C51.4751 32.128 52.1533 31.867 52.7626 31.5172C54.6207 30.4964 56.4931 29.4986 58.354 28.4835Z"
                  fill="#C7CED6"
                />
                <path
                  d="M46.1402 35.149C46.7567 34.8278 47.3331 34.3719 48.0399 34.2744C48.0628 37.7425 48.0499 41.2107 48.0456 44.6788C47.0234 45.291 45.9381 45.7928 44.9345 46.4394C44.8513 44.1598 44.9115 41.8745 44.9029 39.592C44.6076 39.625 44.2807 39.5777 44.0226 39.7569C43.4104 40.101 42.8083 40.4651 42.1875 40.7978C41.9294 40.9612 41.6155 40.9254 41.3244 40.9512C40.8628 40.1841 40.3767 39.4329 39.9395 38.6515C40.3681 38.2558 40.8843 37.982 41.3975 37.7153C42.9861 36.8751 44.556 35.9992 46.1402 35.149Z"
                  fill="#C7CED6"
                />
              </g>
              <defs>
                <clipPath id="clip0_114_242">
                  <rect width="193.324" height="46" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <path
                d="M113.62 8H116.206C115.498 8.77806 114.324 8.78217 113.62 8Z"
                fill="#C7CED6"
              />
              <path
                d="M117.766 8H118.519C118.268 8.64632 118.017 8.64632 117.766 8Z"
                fill="#C7CED6"
              />
              <path
                d="M147.855 8H148.612C148.361 8.64632 148.11 8.64632 147.855 8Z"
                fill="#C7CED6"
              />
              <path
                d="M3.44079 12.9975C3.87304 10.1858 6.66828 7.99981 9.5088 8.32091C10.9208 8.39501 12.1106 9.2307 13.2015 10.054C9.6076 11.6143 6.58183 14.2819 4.66345 17.7029C3.84011 16.2908 3.0744 14.6771 3.44079 12.9975Z"
                fill="#C7CED6"
              />
              <path
                d="M30.6524 8.69911C32.8836 7.77697 35.72 8.48093 37.1485 10.4528C38.6552 12.4618 38.7046 15.5658 37.0168 17.4841C36.0741 16.245 35.3001 14.87 34.1763 13.7832C32.6325 12.2724 30.7141 11.2515 28.8657 10.1688C29.0016 9.33309 29.9896 9.0408 30.6524 8.69911Z"
                fill="#C7CED6"
              />
              <path
                d="M14.3215 10.7454C19.2945 9.20576 24.9179 9.23869 29.541 11.8363C34.0817 14.0429 37.5274 18.8882 37.1692 24.0547C37.0663 27.8626 33.4107 31.0572 29.6562 30.8966C25.9965 30.8637 22.7031 27.6856 22.5261 24.0341C22.2174 20.5884 24.6997 17.2004 28.0425 16.3812C29.3351 16.163 30.6525 16.2906 31.9534 16.3441C28.9111 13.0302 24.6833 10.7866 20.1878 10.342C18.22 10.1361 16.2687 10.5149 14.3215 10.7454Z"
                fill="#C7CED6"
              />
              <path
                d="M112.583 9.86061C113.958 9.29662 115.308 10.2146 116.482 10.8445C116.457 10.4946 116.412 9.79474 116.387 9.44482C117.325 9.81533 117.046 10.7539 117.008 11.532C115.086 11.5732 113.159 11.6102 111.241 11.4908C111.657 10.9268 112.019 10.2929 112.583 9.86061ZM113.048 10.9104C113.493 11.2109 113.937 11.2109 114.382 10.9104C114.436 10.3052 112.949 10.2887 113.048 10.9104Z"
                fill="#C7CED6"
              />
              <path
                d="M145.986 9.3457C146.579 9.83971 147.212 10.8689 146.29 11.4123C145.027 11.8198 143.672 11.5523 142.392 11.3876C142.626 10.8483 142.898 10.3214 143.207 9.81501C143.376 10.1567 143.713 10.8318 143.882 11.1694C144.537 11.1159 145.195 11.0624 145.854 11.0047C145.887 10.5889 145.953 9.76149 145.986 9.3457Z"
                fill="#C7CED6"
              />
              <path
                d="M149.728 11.0505C150.288 10.643 150.848 10.2313 151.412 9.82373C151.778 10.0748 152.149 10.326 152.519 10.573C153.655 9.55615 154.812 10.4083 155.866 11.0382C155.146 11.4169 154.363 11.4786 153.573 11.3551C152.684 11.5363 151.79 11.4498 150.942 11.1534C150.889 11.384 150.79 11.8491 150.741 12.0797C150.399 11.738 150.061 11.3963 149.728 11.0505Z"
                fill="#C7CED6"
              />
              <path
                d="M57.316 11.5112C57.7894 11.4453 58.2711 11.4288 58.7486 11.4618C59.5514 12.3922 59.0532 13.7959 58.1188 14.4258C56.3609 14.8292 54.8995 13.2978 53.1458 13.5201C53.3969 13.7754 53.9033 14.2941 54.1544 14.5534C54.1585 15.4385 54.1544 16.3277 54.1461 17.2169C55.2782 16.6282 56.4803 16.8711 57.633 17.2293C57.5712 16.5294 57.6947 15.7678 57.3242 15.1339L57.0896 15.422L57.0443 15.7061C57.3036 16.1877 56.715 16.5912 56.2745 16.3936C56.1551 15.8584 56.1469 15.315 56.2457 14.7675C57.1472 14.7263 58.0488 14.714 58.9503 14.7263C58.9544 15.8872 58.9544 17.0523 58.9503 18.2173C56.9002 17.9867 54.8419 17.9415 52.7917 18.2379C52.7835 17.1099 52.7835 15.9778 52.7917 14.8498C52.6024 14.7057 52.2236 14.4176 52.0302 14.2735C52.0137 14.0471 51.9766 13.586 51.9561 13.3596C52.7753 12.952 53.5698 12.4745 54.4467 12.2069C55.8546 12.4827 57.0813 13.5284 58.5963 13.2237C57.8512 12.8409 57.4271 12.2687 57.316 11.5112Z"
                fill="#C7CED6"
              />
              <path
                d="M157.084 11.6715C157.545 11.6263 158.011 11.6016 158.476 11.6016C159.118 11.9885 159.225 12.7831 159.509 13.417C160.094 13.4458 160.687 13.4747 161.279 13.4994C161.798 12.9765 162.152 11.9885 163.062 12.1861C163.535 12.0956 164.017 12.5978 164.025 13.0506C164.124 14.792 164.132 16.5416 164.116 18.2871C163.07 18.2171 162.037 18.0236 161.098 17.5502C160.053 18.1224 158.846 18.4065 157.722 17.8754C157.418 17.7972 156.813 17.649 156.508 17.5749C156.648 16.8998 156.471 15.9364 157.344 15.7224C158.023 15.5906 158.719 15.6071 159.41 15.5824C159.447 16.1752 159.484 16.7721 159.521 17.3649C160.044 16.9615 160.571 16.5581 161.098 16.1587C161.654 16.5828 162.21 17.0109 162.774 17.4349C162.654 16.5787 162.922 15.5906 162.416 14.8249C161.123 14.5244 159.777 14.5903 158.476 14.3515C157.817 13.5693 157.529 12.569 157.084 11.6715ZM162.535 12.9889C162.313 13.6599 162.535 13.8946 163.202 13.6846C163.42 13.0136 163.198 12.7831 162.535 12.9889ZM157.653 16.3275C157.084 17.5749 159.032 16.3234 157.653 16.3275V16.3275Z"
                fill="#C7CED6"
              />
              <path
                d="M47.6792 13.7099C48.659 12.8742 49.717 11.8245 51.0961 12.5078C51.0137 12.759 50.8491 13.2653 50.7667 13.5164C50.3262 13.4794 49.4494 13.4012 49.0089 13.3641C49.4782 13.5946 49.9516 13.8252 50.4209 14.0557C50.4003 14.6856 50.3839 15.3154 50.3674 15.9453C50.7997 15.9453 51.236 15.9494 51.6724 15.9576C51.6806 16.571 51.6806 17.1844 51.6806 17.7978C50.7914 18.0818 49.8611 18.1765 48.9348 18.1889C48.9183 16.9909 48.9142 15.793 48.9224 14.5909C48.5025 14.3027 48.0909 14.0104 47.6792 13.7099Z"
                fill="#C7CED6"
              />
              <path
                d="M71.066 14.3071C70.5761 13.315 71.3748 12.0511 72.5068 12.2158C73.4537 12.0964 73.6513 13.138 73.96 13.7802C73.3755 13.5661 72.8033 13.3314 72.231 13.0886C72.1899 13.2985 72.1075 13.7184 72.0664 13.9284C73.252 14.1507 74.487 14.1465 75.512 13.422C75.1292 13.3026 74.3676 13.0639 73.9889 12.9486C74.1412 12.6522 74.4417 12.0635 74.594 11.7671C75.0674 11.9729 75.5326 12.2035 75.9855 12.4587C76.0019 13.1956 76.1995 14.2165 75.4215 14.6488C74.1206 15.4886 72.2022 15.4063 71.066 14.3071Z"
                fill="#C7CED6"
              />
              <path
                d="M78.7969 12.1782C79.6079 12.3182 80.2871 12.8287 81.0034 13.195C81.0075 14.8582 81.0034 16.5255 81.0075 18.1886C80.53 18.1845 80.0525 18.1845 79.5749 18.1845C79.6079 16.5749 79.6614 14.9405 79.2085 13.3762C78.2576 14.216 77.3519 15.1093 76.4915 16.0438C76.4956 16.8095 76.4874 17.5752 76.4751 18.3409C75.4953 18.1968 74.5361 17.954 73.6222 17.5711C72.6671 18.0116 71.6379 18.2174 70.5923 18.2833C70.5758 17.3488 70.5676 16.4102 70.5635 15.4757C70.9175 15.4716 71.6215 15.4633 71.9755 15.4633C71.9796 16.1056 71.9837 16.7519 71.9879 17.3982C72.5313 16.9824 73.0788 16.5666 73.6304 16.1508C74.1491 16.5707 74.6678 16.9865 75.1865 17.4064C75.1948 16.8424 75.1989 16.2743 75.2071 15.7104C76.3845 14.5165 77.4795 13.2239 78.7969 12.1782Z"
                fill="#C7CED6"
              />
              <path
                d="M95.0786 13.7549C96.0707 12.8904 97.1452 11.8283 98.5572 12.5035C98.442 12.7711 98.2197 13.3062 98.1085 13.5738C97.598 13.475 97.0958 13.3721 96.5894 13.2609C97.1822 13.7097 97.6968 14.2407 98.0344 14.9076C99.3559 14.9241 100.681 14.92 102.003 14.8665C101.492 14.4877 100.974 14.1213 100.459 13.7467C101.151 13.191 101.842 12.6434 102.538 12.0918C103.102 12.38 103.666 12.6723 104.226 12.9728C103.493 13.2568 102.727 13.4462 101.941 13.3886C102.336 13.7549 103.246 13.8496 103.127 14.5536C103.036 15.6816 103.547 17.1924 102.559 18.0116C100.574 18.2545 98.4378 18.448 96.5071 17.8305C96.3342 16.7519 96.3877 15.6569 96.3754 14.57C95.939 14.2983 95.5067 14.0266 95.0786 13.7549ZM97.7668 16.0109C97.7751 16.2497 97.7998 16.7272 97.8121 16.966C99.0101 17.1389 100.224 17.1842 101.435 17.2089C101.542 16.8137 101.649 16.4226 101.76 16.0274C100.426 16.015 99.0965 16.015 97.7668 16.0109Z"
                fill="#C7CED6"
              />
              <path
                d="M104.279 13.7713C104.95 13.2156 105.63 12.6639 106.305 12.1123C106.877 12.4005 107.441 12.6928 108.009 12.9892C107.26 13.195 106.502 13.3802 105.741 13.5202C107.087 13.6478 106.832 14.9158 106.869 15.8956C107.276 15.9285 107.684 15.9532 108.095 16.0191C108.26 16.5419 108.408 17.0688 108.532 17.604C107.61 18.0156 106.618 18.2009 105.613 18.238C105.564 17.0235 105.56 15.8091 105.555 14.5906C105.127 14.3189 104.703 14.0472 104.279 13.7713Z"
                fill="#C7CED6"
              />
              <path
                d="M110.504 12.2729C111.023 12.3059 111.525 12.4417 112.035 12.5364C111.933 12.7957 111.723 13.3103 111.62 13.5656C111.229 13.4791 110.451 13.3021 110.059 13.2156C110.656 13.6973 111.175 14.2572 111.574 14.92C112.884 14.9241 114.189 14.9076 115.498 14.8664C115.102 14.5906 114.32 14.0308 113.929 13.7549C114.608 13.2033 115.296 12.6558 115.992 12.1206C116.342 12.2729 117.041 12.5776 117.391 12.7299C117.28 12.9234 117.054 13.3144 116.938 13.512C116.523 13.475 115.695 13.405 115.279 13.368C115.609 13.5162 116.267 13.8167 116.597 13.9649C116.593 15.134 116.753 16.332 116.469 17.4764C116.309 18.1804 115.469 18.0816 114.921 18.168C113.373 18.1845 111.78 18.3533 110.265 17.9746C109.438 17.0812 109.957 15.6733 109.808 14.5618C109.388 14.2942 108.973 14.0266 108.557 13.7591C109.187 13.2403 109.796 12.6846 110.504 12.2729ZM111.192 16.0232C111.29 16.3979 111.393 16.7684 111.496 17.143C112.657 17.1677 113.818 17.1718 114.979 17.1636C115.061 16.7848 115.148 16.4061 115.23 16.0274C113.884 16.015 112.538 16.0109 111.192 16.0232Z"
                fill="#C7CED6"
              />
              <path
                d="M118.647 13.7463C119.326 13.1947 120.009 12.6471 120.689 12.0996C121.269 12.3837 121.841 12.6759 122.418 12.9765C121.66 13.1905 120.894 13.3593 120.125 13.5034C120.565 13.7504 121.006 14.0056 121.446 14.2609C122.208 14.3926 122.99 14.4708 123.718 14.7549C123.982 15.5618 124.101 16.4057 124.266 17.2414C124.916 17.1508 125.563 17.0561 126.213 16.9573C126.213 16.1669 126.213 15.3765 126.209 14.5902C125.797 14.2897 125.398 13.9892 124.995 13.6845C125.991 12.82 127.098 11.8444 128.49 12.5154C128.374 12.7706 128.144 13.277 128.029 13.5281C127.588 13.4787 126.711 13.3799 126.271 13.3264C126.617 13.4869 127.304 13.7998 127.65 13.9604C127.65 15.1295 127.745 16.311 127.551 17.4719C127.428 18.0153 126.855 18.0812 126.398 18.147C125.126 18.3117 123.821 18.1923 122.607 17.7642C122.582 17.0026 122.553 16.2369 122.512 15.4753C121.631 15.5124 120.754 15.5782 119.882 15.6688C120.631 15.8664 122.027 15.8787 121.677 17.052C121.512 18.4764 119.791 18.11 118.787 18.1759C118.77 17.089 118.778 16.0022 118.807 14.9113C119.124 14.796 119.445 14.6767 119.762 14.5573C119.487 14.3556 118.927 13.948 118.647 13.7463ZM120.244 16.805C119.598 18.0112 121.623 16.8832 120.244 16.805V16.805Z"
                fill="#C7CED6"
              />
              <path
                d="M137.695 12.2808C138.242 12.2972 138.778 12.4372 139.321 12.5236C139.181 12.7789 138.897 13.2852 138.757 13.5405C138.329 13.4828 137.473 13.3717 137.044 13.3141C137.51 13.5487 137.979 13.7792 138.448 14.0098C138.44 15.253 138.436 16.4921 138.44 17.7354C136.896 18.2788 135.101 18.4805 133.57 17.8095C133.245 16.4098 133.364 14.9607 133.282 13.5405C132.549 13.5322 131.816 13.5364 131.083 13.5446C131.055 14.3473 131.03 15.1542 131.009 15.957C131.359 15.9405 132.063 15.9035 132.417 15.887C132.31 16.5992 132.668 17.5954 131.857 17.9865C131.145 18.2459 130.371 18.1265 129.63 18.1471C129.647 16.5457 129.507 14.936 129.704 13.3388C129.955 12.2725 131.281 12.326 132.15 12.2972C132.989 12.3343 134.105 12.2396 134.628 13.0465C134.937 14.3762 134.751 15.7676 134.834 17.122C135.529 17.1261 136.229 17.1508 136.925 17.0602C137.032 16.2287 137.032 15.3889 137.061 14.5532C136.76 14.3515 136.159 13.9521 135.859 13.7504C136.456 13.2441 137.028 12.6965 137.695 12.2808Z"
                fill="#C7CED6"
              />
              <path
                d="M144.582 12.1079C145.183 12.3714 145.788 12.6431 146.385 12.923C145.615 13.2894 144.784 13.4623 143.944 13.5446C144.405 13.6846 144.87 13.8328 145.331 13.981C145.191 15.0061 145.026 16.0311 144.924 17.0644C145.191 17.1838 145.73 17.4308 146.002 17.5543C145.755 17.3485 145.261 16.9327 145.014 16.7268C145.45 16.3358 145.899 15.957 146.356 15.5783C146.578 16.3563 147.077 17.3567 146.22 17.9454C145.475 18.2459 144.652 18.143 143.87 18.1924C143.824 16.9944 143.82 15.7924 143.82 14.5944C143.458 14.3145 143.1 14.0345 142.742 13.7505C143.351 13.1988 143.964 12.6513 144.582 12.1079Z"
                fill="#C7CED6"
              />
              <path
                d="M146.982 13.7464C147.645 13.1948 148.324 12.6514 149.008 12.1162C149.469 12.322 149.934 12.5279 150.399 12.7296C150.263 12.9313 149.991 13.3306 149.86 13.5282C149.366 13.4583 148.876 13.3842 148.39 13.3101C150.136 13.8288 149.448 15.7801 149.687 17.1345C150.93 17.1674 152.173 17.1756 153.421 17.1633C153.482 16.2988 153.528 15.4343 153.573 14.5698C153.256 14.3639 152.626 13.9523 152.313 13.7464C153.256 12.919 154.269 11.8569 155.631 12.4744C155.553 12.7296 155.393 13.2483 155.314 13.5035C154.894 13.4747 154.051 13.413 153.631 13.38C154.092 13.59 154.557 13.7999 155.022 14.0099C155.014 15.249 155.014 16.4923 155.002 17.7314C152.799 18.3859 150.313 18.4847 148.131 17.7067C148.143 16.6569 148.151 15.6113 148.168 14.5656C147.871 14.3598 147.279 13.9523 146.982 13.7464Z"
                fill="#C7CED6"
              />
              <path
                d="M168.953 13.771C169.603 13.2193 170.258 12.6759 170.912 12.1284C171.439 12.3301 171.966 12.5319 172.497 12.7377C172.283 13.0012 172.069 13.2646 171.859 13.5281C171.452 13.4746 170.633 13.3634 170.221 13.3099C170.665 13.524 171.106 13.738 171.555 13.9521C171.571 14.195 171.612 14.6849 171.633 14.9278C172.991 14.9154 174.354 14.9154 175.713 14.8784C175.264 14.4626 174.827 14.055 174.383 13.6434C175.021 13.1411 175.655 12.6348 176.293 12.1325C176.808 12.3343 177.326 12.536 177.845 12.7418C177.684 12.9353 177.367 13.3223 177.207 13.5157C176.803 13.4705 175.997 13.3799 175.593 13.3346C175.927 13.4828 176.585 13.7792 176.915 13.9274C176.919 15.2571 177.087 16.6239 176.709 17.9206C175.079 18.3323 173.378 18.2129 171.715 18.1635C171.159 18.0647 170.246 18.1882 170.229 17.3896C170.097 16.4468 170.151 15.4918 170.138 14.5408C169.739 14.2856 169.348 14.0262 168.953 13.771ZM171.254 16.1175C171.695 16.6321 172.11 17.336 172.913 17.1837C174.021 17.2414 175.358 17.3854 175.844 16.101C174.317 15.9775 172.781 15.9734 171.254 16.1175Z"
                fill="#C7CED6"
              />
              <path
                d="M180.871 14.1708C181.48 13.2116 182.447 12.3594 183.608 12.2153C184.263 12.3676 184.876 12.6641 185.51 12.9069C186.663 11.8778 188.021 12.4418 189.265 12.9646C188.989 13.3762 188.713 13.7879 188.441 14.1996C187.82 13.9485 187.202 13.6891 186.585 13.4339C185.21 14.5495 183.666 13.3474 182.147 13.6356C184.049 14.8336 186.329 14.2325 188.417 14.7142C188.478 15.8668 188.499 17.0236 188.511 18.1804C188.025 18.1804 187.544 18.1845 187.062 18.1887C187.058 17.3324 187.05 16.4761 187.042 15.6198C185.749 15.4963 184.444 15.4922 183.155 15.6363C183.143 16.4885 183.139 17.3365 183.135 18.1845C182.649 18.1804 182.167 18.1763 181.686 18.1722C181.706 16.9248 181.735 15.6816 181.883 14.4425C181.628 14.3725 181.122 14.2407 180.871 14.1708Z"
                fill="#C7CED6"
              />
              <path
                d="M61.4905 12.7255C63.3183 12.1739 65.2737 12.1863 67.1509 12.4127C68.5053 12.6391 68.1019 14.1334 68.2172 15.1173C67.4473 15.1544 66.6816 15.1873 65.9118 15.2203C65.7471 14.6933 65.5907 14.1623 65.4713 13.623C66.2535 13.6559 66.6816 14.1252 66.7557 15.0227C66.6775 14.5081 66.5993 13.9894 66.5252 13.4707C65.1749 13.5159 63.6435 13.1619 62.5114 14.1087C64.3022 14.8786 66.3317 15.1544 67.9784 16.2371C68.069 16.5994 68.2583 17.3198 68.3489 17.6779C66.694 18.4436 64.8168 18.2501 63.1248 17.7191C63.0301 17.9043 62.8408 18.2748 62.7461 18.4601C62.3262 18.4477 61.9063 18.4313 61.4905 18.4189C61.4946 17.5256 61.5028 16.6364 61.5152 15.7431C61.7539 15.7554 62.2274 15.7719 62.4661 15.7842C63.7258 16.8958 65.4055 17.3651 67.0645 17.1057C65.496 15.7966 63.2936 15.8089 61.6469 14.6522C61.5111 14.0223 61.5234 13.3719 61.4905 12.7255Z"
                fill="#C7CED6"
              />
              <path
                d="M87.0428 15.2529C87.1458 14.2361 86.6147 12.5894 88.0679 12.3877C89.9245 12.2107 91.8553 12.1489 93.646 12.7582C93.6707 13.4498 93.6954 14.1373 93.7201 14.8248C91.9211 15.8951 89.7393 15.9774 87.9444 17.0478C89.2123 17.1672 90.4885 17.1877 91.7606 17.1548C92.2258 16.525 92.8515 16.0845 93.6213 15.8992C93.6419 16.3726 93.6872 17.3277 93.7078 17.8053C91.5177 18.4186 89.1918 18.2704 86.9688 17.9246C87.0346 17.3359 87.0593 16.7349 87.2075 16.1545C88.6813 15.1911 90.4968 15.0429 92.1599 14.5942C92.1599 14.3184 92.164 13.7709 92.1681 13.4951C90.8796 13.4457 89.5952 13.5157 88.3108 13.4374C88.3602 13.9438 88.459 14.446 88.5043 14.9565C88.5413 14.6848 88.6154 14.1455 88.6525 13.8738C89.307 13.211 90.0398 14.693 89.2494 14.87C88.6072 15.2941 87.7633 15.1212 87.0428 15.2529Z"
                fill="#C7CED6"
              />
              <path
                d="M4.55647 22.379C4.89816 18.9169 8.03508 15.924 11.5384 15.887C14.3954 15.7429 17.3512 17.4266 18.4256 20.1313C20.1464 23.5934 18.545 28.3194 14.9758 29.8879C13.2592 30.8141 11.2585 30.5424 9.39771 30.423C12.5881 33.737 16.7872 36.2935 21.4473 36.701C23.5592 36.9645 25.6587 36.5322 27.7294 36.1453C25.4117 36.7998 23.0487 37.4791 20.6198 37.4914C16.8366 37.2568 13.0163 36.1658 9.96581 33.8481C6.4131 31.2546 4.07893 26.8251 4.55647 22.379Z"
                fill="#C7CED6"
              />
              <path
                d="M178.421 18.1392C178.994 18.1392 179.57 18.1392 180.15 18.1392C180.167 18.6002 180.183 19.0572 180.2 19.5183C179.586 19.5183 178.973 19.5183 178.364 19.5183C178.38 19.0572 178.401 18.5961 178.421 18.1392Z"
                fill="#C7CED6"
              />
              <path
                d="M56.8717 27.0068C58.1273 27.1303 59.6216 26.7269 60.6796 27.5873C61.0337 28.1883 61.0748 28.9046 61.2519 29.5674C60.3915 30.2714 59.3705 30.6872 58.2837 30.8889C58.2878 31.4158 58.2961 31.9428 58.3002 32.4697C57.942 32.4738 57.2216 32.4779 56.8635 32.4779C56.8635 30.6542 56.8593 28.8305 56.8717 27.0068ZM58.2837 28.0731C58.2919 28.6247 58.2961 29.1722 58.3043 29.7239C58.7242 29.6868 59.1441 29.6498 59.564 29.6127C59.5887 29.1681 59.6134 28.7276 59.6422 28.283C59.1894 28.213 58.7365 28.143 58.2837 28.0731Z"
                fill="#C7CED6"
              />
              <path
                d="M63.1785 26.916C63.6519 26.9819 64.1212 27.0683 64.5905 27.1671C65.4632 28.8344 65.9078 30.691 66.4924 32.4736C66.1301 32.4694 65.4056 32.4694 65.0433 32.4694C64.9692 32.1895 64.8252 31.6255 64.7511 31.3456C64.1706 31.3415 63.5943 31.3373 63.0138 31.3332C62.9232 31.6214 62.7421 32.1977 62.6474 32.4859C62.2893 32.4818 61.5688 32.4653 61.2107 32.4571C61.8694 30.6087 62.5075 28.7562 63.1785 26.916ZM63.3431 30.1476C63.6642 30.5881 63.9977 30.5963 64.3476 30.1723C64.9692 28.9208 62.8162 28.8756 63.3431 30.1476Z"
                fill="#C7CED6"
              />
              <path
                d="M79.7974 27.0564C80.3161 27.0481 80.8348 27.0358 81.3535 27.0234C81.9834 28.8307 82.6297 30.6338 83.2472 32.4451C82.8932 32.4575 82.1851 32.4863 81.8352 32.4986C81.6664 32.0952 81.5058 31.6918 81.3412 31.2925C80.9954 31.3172 80.3038 31.3666 79.958 31.3913C79.501 32.0705 78.8506 32.5275 78.0437 32.7045C78.5254 30.7902 79.1429 28.9171 79.7974 27.0564ZM80.5549 28.7977C80.3614 29.3123 80.1762 29.8269 79.9868 30.3415C80.2791 30.3415 80.8595 30.3415 81.1518 30.3374C80.9501 29.8228 80.7525 29.3082 80.5549 28.7977Z"
                fill="#C7CED6"
              />
              <path
                d="M87.0798 27.2C88.3478 26.6361 90.332 26.809 90.5379 28.505C89.4881 28.4351 88.0184 27.41 87.3474 28.6944C86.6352 29.8059 87.3145 31.0533 88.3231 31.6749C89.0558 31.4197 89.7927 31.1727 90.5337 30.938C90.2415 33.3957 86.4911 33.0211 85.8201 31.008C85.2397 29.6659 85.709 27.8587 87.0798 27.2Z"
                fill="#C7CED6"
              />
              <path
                d="M92.3492 27.0845C94.354 26.2447 96.5688 28.1466 96.0748 30.2544C95.9142 32.5803 92.6579 33.4983 91.2377 31.6993C90.4473 30.242 90.4596 27.6691 92.3492 27.0845ZM92.3574 31.1724C92.695 31.3041 93.3783 31.5635 93.72 31.6952C94.9303 30.7237 95.058 27.8708 93.0614 27.9943C91.7975 28.5624 92.1145 30.0938 92.3574 31.1724Z"
                fill="#C7CED6"
              />
              <path
                d="M96.927 27.0312C97.4251 27.0312 97.9232 27.0312 98.4255 27.0312C98.8207 27.8999 99.22 28.7726 99.6234 29.6412C100.056 28.7767 100.48 27.904 100.904 27.0312C101.418 27.0312 101.929 27.0312 102.447 27.0312C102.447 28.8508 102.447 30.6663 102.447 32.4859C101.995 32.4818 101.542 32.4818 101.093 32.4776C101.097 31.3414 101.105 30.2093 101.142 29.0731C100.677 29.987 100.253 30.9997 99.2529 31.4444C98.8948 30.6457 98.5407 29.8471 98.1826 29.0484C98.1414 30.1929 98.1291 31.3414 98.1167 32.4859C97.7215 32.4859 97.3222 32.4859 96.927 32.4818C96.927 30.6663 96.927 28.8467 96.927 27.0312Z"
                fill="#C7CED6"
              />
              <path
                d="M103.534 27.0276C104.094 27.0358 104.654 27.044 105.218 27.0523C105.613 27.9538 106.009 28.8513 106.4 29.7528C106.799 28.8636 107.198 27.9744 107.593 27.077C108.166 27.0564 108.738 27.0399 109.31 27.0234C109.314 28.8389 109.31 30.6544 109.31 32.4739C108.94 32.4739 108.203 32.4739 107.832 32.4739C107.816 31.3624 107.799 30.2509 107.762 29.1394C107.408 29.9257 107.05 30.712 106.68 31.4901C105.832 30.9055 105.284 30.0369 104.819 29.1435C104.86 30.255 104.876 31.3707 104.885 32.4822C104.432 32.4822 103.983 32.4781 103.534 32.4781C103.534 30.6585 103.534 28.843 103.534 27.0276Z"
                fill="#C7CED6"
              />
              <path
                d="M110.253 27.077C111.5 27.0399 112.748 27.0317 113.999 27.0234C114.003 27.2951 114.011 27.8344 114.016 28.102C113.242 28.1061 112.459 28.1102 111.686 28.1144C111.71 28.3614 111.764 28.8554 111.793 29.1024C112.513 29.1065 113.233 29.1106 113.958 29.1188C113.954 29.3411 113.946 29.7899 113.941 30.0163C113.205 30.0163 112.468 30.0163 111.735 30.0204C111.731 30.358 111.723 31.0372 111.718 31.3748C112.459 31.3748 113.209 31.3789 113.954 31.3789C113.974 31.6506 114.016 32.1981 114.032 32.4739C112.776 32.4781 111.521 32.4781 110.265 32.4616C110.265 30.6667 110.269 28.8718 110.253 27.077Z"
                fill="#C7CED6"
              />
              <path
                d="M115.069 27.0068C116.42 27.155 118.12 26.6487 119.157 27.7808C119.659 28.7317 118.935 29.5921 118.416 30.3331C118.988 31.0412 119.557 31.7493 120.129 32.4573C119.717 32.4656 118.89 32.4903 118.478 32.4985C117.811 31.6958 117.136 30.8971 116.436 30.1232C116.378 30.9136 116.337 31.6999 116.296 32.4903C115.884 32.4862 115.477 32.482 115.065 32.482C115.065 30.6542 115.061 28.8305 115.069 27.0068ZM116.333 29.5839C116.774 29.5715 117.21 29.5592 117.655 29.5468C117.683 29.271 117.749 28.7153 117.782 28.4394C117.321 28.2336 116.852 28.0442 116.374 27.8796C116.358 28.4477 116.346 29.0158 116.333 29.5839Z"
                fill="#C7CED6"
              />
              <path
                d="M121.211 27.4715C122.183 26.5988 123.562 27.0475 124.707 27.2328C124.719 27.5868 124.748 28.3031 124.76 28.6612C123.863 28.2702 122.623 27.5127 121.874 28.5748C121.109 29.7604 121.751 31.2342 123.019 31.7241C123.603 31.4153 124.188 31.0983 124.772 30.7813C124.744 31.1642 124.69 31.9299 124.661 32.3128C123.62 32.5021 122.434 32.9344 121.471 32.2634C119.812 31.2795 119.721 28.6489 121.211 27.4715Z"
                fill="#C7CED6"
              />
              <path
                d="M125.324 27.0312C126.308 27.0312 127.292 27.0312 128.276 27.0312C128.251 27.1959 128.198 27.5211 128.173 27.6858C127.107 28.9414 127.202 31.115 128.42 32.2512C127.255 32.527 125.958 32.7164 124.921 31.9466C125.238 31.786 125.872 31.4608 126.185 31.3003C126.028 30.09 126.592 28.5133 125.374 27.7023C125.361 27.5335 125.337 27.2 125.324 27.0312Z"
                fill="#C7CED6"
              />
              <path
                d="M130.441 27.0602C130.956 27.0479 131.466 27.0355 131.985 27.019C132.627 28.8222 133.282 30.617 133.862 32.4407C133.525 32.4572 132.841 32.4901 132.504 32.5066C132.318 32.1073 132.129 31.708 131.948 31.3128C131.549 31.321 130.758 31.3375 130.359 31.3457C130.281 31.6256 130.12 32.1814 130.038 32.4613C129.663 32.4654 128.91 32.4737 128.531 32.4778C129.181 30.6747 129.832 28.8757 130.441 27.0602ZM131.557 30.2218C131.75 29.7978 131.66 29.4561 131.289 29.1885C130.346 29.0774 130.795 30.9917 131.557 30.2218Z"
                fill="#C7CED6"
              />
              <path
                d="M134.352 27.0273C134.813 27.0273 135.278 27.0273 135.748 27.0273C135.748 28.4723 135.748 29.9173 135.743 31.3622C136.517 31.3705 137.295 31.3787 138.073 31.3869C138.082 31.6586 138.102 32.1979 138.111 32.4655C136.863 32.4737 135.612 32.4779 134.364 32.4861C134.344 30.6665 134.348 28.8469 134.352 27.0273Z"
                fill="#C7CED6"
              />
              <path
                d="M140.93 27.0024C142.215 27.0848 143.623 26.8378 144.8 27.4964C144.862 28.1551 144.8 28.8138 144.755 29.4725C145.158 30.1723 145.636 31.1932 144.956 31.8848C143.828 32.7988 142.256 32.3871 140.926 32.5024C140.918 30.6704 140.918 28.8385 140.93 27.0024ZM142.079 29.2502C142.643 29.1884 143.207 29.1225 143.771 29.0484C143.483 28.6615 143.199 28.2745 142.877 27.9122C142.392 28.2045 142.297 28.7767 142.079 29.2502ZM142.318 30.0282C142.305 30.5058 142.293 30.9792 142.285 31.4567C142.824 31.3908 143.359 31.325 143.903 31.255C143.861 30.8804 143.824 30.5058 143.787 30.1311C143.297 30.0941 142.803 30.0611 142.318 30.0282Z"
                fill="#C7CED6"
              />
              <path
                d="M147.315 27.0603C147.834 27.0479 148.357 27.0356 148.88 27.0273C149.506 28.8346 150.148 30.6377 150.773 32.449C150.415 32.4614 149.699 32.4861 149.345 32.4984C149.234 32.2061 149.016 31.6216 148.904 31.3252C148.341 31.3334 147.777 31.3375 147.213 31.3416C147.163 31.6092 147.068 32.1485 147.023 32.4202C146.657 32.4408 145.924 32.4861 145.558 32.5067C146.122 30.683 146.677 28.8593 147.315 27.0603ZM148.135 28.7111C147.916 29.2504 147.715 29.7938 147.501 30.3372C147.785 30.3372 148.353 30.3454 148.637 30.3495C148.468 29.802 148.299 29.2545 148.135 28.7111Z"
                fill="#C7CED6"
              />
              <path
                d="M151.333 26.9077C151.872 26.99 152.416 27.0477 152.947 27.1835C153.643 28.0604 154.104 29.0937 154.655 30.0611C154.676 29.0566 154.692 28.0563 154.705 27.0518C155.034 27.0477 155.689 27.0353 156.018 27.0271C156.018 28.8426 156.018 30.658 156.022 32.4735C155.685 32.4776 155.009 32.4817 154.668 32.4817C153.976 31.2755 153.284 30.0652 152.543 28.8837C152.511 30.0858 152.506 31.2838 152.502 32.4817C152.206 32.4817 151.613 32.4817 151.317 32.4817C151.308 30.6251 151.3 28.7643 151.333 26.9077Z"
                fill="#C7CED6"
              />
              <path
                d="M160.242 27.052C160.793 27.0479 161.345 27.0437 161.901 27.0396C161.242 27.8753 160.579 28.711 159.912 29.5385C160.612 30.5471 161.316 31.5515 161.987 32.5766C161.436 32.5313 160.888 32.4654 160.345 32.3708C159.616 31.7532 159.11 30.9134 158.492 30.193C158.467 30.9546 158.447 31.7162 158.43 32.4819C158.105 32.4819 157.459 32.486 157.134 32.486C157.125 30.6623 157.125 28.8427 157.134 27.019C157.463 27.0232 158.122 27.0355 158.451 27.0396C158.447 27.8177 158.443 28.5999 158.439 29.3779C159.044 28.604 159.645 27.83 160.242 27.052Z"
                fill="#C7CED6"
              />
              <path
                d="M175.14 27.1303C176.087 26.7557 177.104 27.085 178.067 27.2085C178.08 27.6613 178.088 28.1101 178.104 28.5629C177.289 28.39 176.301 27.5996 175.577 28.3488C174.753 29.0857 175.206 30.3001 175.227 31.247C176.219 31.5146 177.223 31.424 178.141 30.9259C178.109 31.2799 178.051 31.988 178.022 32.342C177.03 32.4985 175.943 32.8772 175.005 32.342C172.967 31.4322 173.07 27.9413 175.14 27.1303Z"
                fill="#C7CED6"
              />
              <path
                d="M66.9617 27.056C67.5421 27.0478 68.1267 27.0396 68.7113 27.0313C69.2917 28.044 69.8804 29.0526 70.4979 30.0447C70.5103 29.032 70.5185 28.0235 70.5267 27.0107C70.852 27.0272 71.5024 27.056 71.8276 27.0725C71.8194 28.8674 71.8235 30.6623 71.8235 32.4613C71.4736 32.4654 70.7737 32.4777 70.4238 32.486C69.7363 31.2468 69.0324 30.02 68.2708 28.8262C68.2708 30.0489 68.2708 31.2674 68.2708 32.4901C67.9456 32.4818 67.2992 32.4654 66.974 32.4571C66.9617 30.6581 66.9658 28.8591 66.9617 27.056Z"
                fill="#C7CED6"
              />
              <path
                d="M72.7534 27.0273C74.0049 27.085 75.3511 26.8709 76.5202 27.4267C78.2739 28.3118 78.2039 31.2799 76.4461 32.1279C75.2893 32.6384 73.9802 32.4367 72.7534 32.4943C72.7534 30.6706 72.7534 28.8469 72.7534 27.0273ZM74.2601 27.933C74.2354 29.1598 74.2437 30.3866 74.2766 31.6092C75.2111 31.3828 76.4543 31.0576 76.3679 29.8267C76.5408 28.5382 75.2481 28.1389 74.2601 27.933Z"
                fill="#C7CED6"
              />
              <path
                d="M164.585 27.0107C165.709 27.056 166.874 26.9202 167.961 27.2742C169.142 27.7764 169.171 29.7195 168.026 30.2876C167.38 30.5923 166.668 30.6993 165.985 30.8804C165.997 31.4115 166.009 31.9384 166.026 32.4695C165.663 32.4736 164.943 32.4736 164.585 32.4777C164.581 30.654 164.581 28.8344 164.585 27.0107ZM166.063 27.8711C166.038 28.501 166.017 29.1308 166.009 29.7607C166.503 29.6742 167.001 29.5878 167.504 29.5013C167.446 28.6286 166.878 28.1099 166.063 27.8711Z"
                fill="#C7CED6"
              />
              <path
                d="M169.55 27.0234C169.908 27.0317 170.62 27.044 170.978 27.0523C170.974 28.4972 170.974 29.9422 170.974 31.3871C171.711 31.383 172.448 31.3789 173.189 31.3748C173.205 31.6465 173.242 32.1981 173.263 32.4739C172.024 32.4781 170.789 32.4781 169.55 32.4781C169.55 30.6585 169.554 28.843 169.55 27.0234Z"
                fill="#C7CED6"
              />
              <path
                d="M178.899 30.9832C179.352 30.975 179.808 30.9667 180.265 30.9585C180.303 31.4649 180.335 31.9671 180.372 32.4734C179.862 32.4734 179.347 32.4734 178.841 32.4734C178.858 31.9753 178.874 31.4813 178.899 30.9832Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="229"
              height="30"
              viewBox="0 0 229 30"
              fill="none"
            >
              <path
                d="M118.553 0.178714C120.22 -0.347142 122.127 1.02202 121.996 2.80072C121.943 4.95019 118.994 6.04795 117.481 4.56246C115.904 3.3387 116.658 0.595522 118.553 0.178714Z"
                fill="#C7CED6"
              />
              <path
                d="M182.111 0.295355C183.655 -0.409825 185.659 0.610384 185.889 2.31154C186.221 4.17506 184.016 5.73808 182.332 5.05229C181.466 4.73969 180.708 4.0127 180.575 3.0773C180.337 1.93835 181.054 0.731549 182.111 0.295355Z"
                fill="#C7CED6"
              />
              <path
                d="M0.72961 0.86461C6.54553 0.862187 12.3614 0.862187 18.1774 0.86461C18.1798 8.13451 18.1798 15.4044 18.1774 22.6743C15.2694 22.6791 12.359 22.6767 9.45106 22.6743C9.45833 18.3148 9.45106 13.9528 9.45349 9.59091C6.54553 9.58122 3.63757 9.58849 0.732033 9.58849C0.727186 6.68053 0.72961 3.77257 0.72961 0.86461Z"
                fill="#C7CED6"
              />
              <path
                d="M24.3203 0.999957C26.7751 0.268121 29.5522 2.15587 29.7557 4.70761C30.1701 7.19391 27.9528 9.68506 25.4423 9.58328C23.1207 9.6681 21.0028 7.53317 21.0973 5.21407C21.0585 3.28997 22.4689 1.48462 24.3203 0.999957Z"
                fill="#C7CED6"
              />
              <path
                d="M34.1636 0.866543C37.5611 0.85685 40.9561 0.864119 44.3512 0.861695C41.4359 8.13159 38.5352 15.4063 35.6224 22.6762C32.2298 22.6762 28.8372 22.6762 25.4446 22.6762C28.355 15.4063 31.2653 8.13886 34.1636 0.866543Z"
                fill="#C7CED6"
              />
              <path
                d="M53.073 0.864126C58.4042 0.85928 63.7379 0.861703 69.0692 0.864126C69.0692 2.31811 69.0667 3.77209 69.0692 5.22606C67.1305 5.22849 65.1919 5.22607 63.2508 5.22849C63.2532 11.0444 63.2508 16.8579 63.2532 22.6738C61.7993 22.6762 60.3453 22.6762 58.8889 22.6762C58.8864 16.8603 58.8913 11.0444 58.8889 5.22849C56.9502 5.22607 55.0116 5.23091 53.073 5.22606C53.0681 3.77209 53.073 2.31811 53.073 0.864126Z"
                fill="#C7CED6"
              />
              <path
                d="M109.778 0.86713C111.232 0.85986 112.686 0.859859 114.14 0.864706C114.145 8.1346 114.142 15.4045 114.142 22.6744C112.686 22.6792 111.23 22.6768 109.776 22.6744C109.773 22.071 109.773 21.4676 109.78 20.8642C107.825 23.4765 103.773 23.6001 101.258 21.7463C97.4458 19.0419 96.6631 13.1387 99.4305 9.42622C100.611 7.80261 102.431 6.58127 104.456 6.3874C106.441 6.07722 108.564 6.8551 109.785 8.47386C109.771 5.93667 109.778 3.4019 109.778 0.86713ZM105.043 10.461C102.241 11.2364 101.454 14.9465 102.872 17.2341C103.921 19.0491 106.533 19.5702 108.28 18.4942C111.033 16.8657 110.876 12.0918 107.982 10.6936C107.081 10.2429 106.005 10.238 105.043 10.461Z"
                fill="#C7CED6"
              />
              <path
                d="M157.035 0.866667C158.729 0.859397 160.422 0.856973 162.119 0.869089C164.307 5.95317 166.478 11.0421 168.662 16.1262C170.61 11.0421 172.537 5.94832 174.482 0.864243C176.179 0.86182 177.873 0.854551 179.569 0.866667C176.654 8.13414 173.755 15.4089 170.843 22.6764C169.386 22.6739 167.93 22.6836 166.476 22.6715C163.338 15.3992 160.18 8.13414 157.035 0.866667Z"
                fill="#C7CED6"
              />
              <path
                d="M190.038 9.14509C193.008 5.4011 199.433 5.26297 202.578 8.84703C204.187 10.7469 204.526 13.3689 204.301 15.768C200.303 15.7728 196.304 15.7607 192.308 15.7752C192.507 16.9602 193.258 18.1113 194.433 18.5136C196.571 19.4708 199.365 18.8843 200.741 16.9263C201.798 17.7551 202.896 18.5305 203.967 19.3448C201.141 23.3747 194.87 24.1453 191.038 21.1453C187.483 18.3367 187.086 12.5256 190.038 9.14509ZM192.463 12.6104C194.967 12.6225 197.47 12.6225 199.971 12.6104C199.738 11.9658 199.498 11.2849 198.963 10.8269C196.968 9.08936 193.122 9.87451 192.463 12.6104Z"
                fill="#C7CED6"
              />
              <path
                d="M80.7419 9.15216C82.2443 7.18929 84.7645 6.05276 87.2339 6.37021C88.7072 6.46957 89.9431 7.39042 90.8664 8.4809C90.8737 7.88235 90.8737 7.28137 90.8712 6.68282C92.3277 6.67313 93.7841 6.67797 95.238 6.68282C95.2429 12.0141 95.2356 17.3453 95.2429 22.6742C93.7865 22.6742 92.3301 22.679 90.8761 22.6742C90.8737 22.0708 90.8688 21.4649 90.8712 20.8591C88.9278 23.4763 84.8809 23.595 82.3655 21.7533C78.4446 18.9883 77.7297 12.838 80.7419 9.15216ZM86.1482 10.4583C83.5335 11.1708 82.6587 14.481 83.7201 16.7565C84.5489 18.8139 87.3405 19.599 89.2283 18.5836C92.0732 17.0691 92.0393 12.2782 89.2137 10.7612C88.2904 10.2475 87.1587 10.2281 86.1482 10.4583Z"
                fill="#C7CED6"
              />
              <path
                d="M128.672 8.10306C129.758 6.60546 131.789 6.23954 133.521 6.42614C135.072 6.47945 136.577 7.25248 137.515 8.49079C138.576 9.81876 138.843 11.5781 138.86 13.2308C138.86 16.3786 138.857 19.5265 138.86 22.6744C137.406 22.6768 135.949 22.6792 134.495 22.6744C134.491 19.8415 134.498 17.0087 134.493 14.1758C134.474 13.0005 134.352 11.7114 133.536 10.7953C132.753 9.85753 131.287 9.91326 130.308 10.4852C129.113 11.1685 128.716 12.6346 128.692 13.9238C128.663 16.8415 128.689 19.7591 128.68 22.6768C127.223 22.6768 125.769 22.6768 124.315 22.6744C124.318 17.3431 124.318 12.0118 124.315 6.68058C125.769 6.68058 127.226 6.67816 128.68 6.68058C128.68 7.15555 128.677 7.62809 128.672 8.10306Z"
                fill="#C7CED6"
              />
              <path
                d="M146.236 6.83783C147.923 6.25381 149.896 6.03329 151.541 6.86691C152.3 7.22556 152.828 7.89439 153.395 8.49052C153.397 7.88954 153.397 7.28614 153.392 6.68516C154.849 6.67547 156.305 6.67789 157.762 6.68031C157.754 11.6093 157.769 16.5359 157.754 21.4624C157.747 23.6386 157.175 25.9601 155.566 27.5183C153.923 29.1491 151.505 29.6241 149.273 29.692C146.651 29.595 143.879 28.9383 141.913 27.099C142.701 26.0013 143.513 24.9253 144.303 23.8276C145.485 24.8672 146.939 25.6838 148.541 25.7759C149.905 25.9552 151.466 25.6547 152.37 24.5328C153.373 23.4035 153.434 21.7969 153.4 20.3647C152.794 21.1038 152.113 21.8065 151.226 22.1991C149.605 22.8122 147.756 22.8486 146.169 22.1022C141.841 20.2993 140.127 14.4349 142.357 10.4195C143.169 8.81282 144.581 7.53574 146.236 6.83783ZM148.655 10.4946C146.983 10.9962 145.74 12.6756 145.81 14.4349C145.73 16.6498 147.77 18.7338 150.014 18.506C152.433 18.5981 154.401 16.0318 153.87 13.7006C153.598 11.3209 150.889 9.7361 148.655 10.4946Z"
                fill="#C7CED6"
              />
              <path
                d="M69.0642 6.68304C70.5182 6.68062 71.9721 6.67577 73.4261 6.68304C73.4285 7.57724 73.4382 8.46901 73.4334 9.36321C74.141 7.35187 76.5158 6.46252 78.486 6.98595C78.5538 8.3745 78.5054 9.76548 78.5199 11.154C77.5118 10.8511 76.4019 10.7299 75.3866 11.0668C74.0804 11.6072 73.4237 13.0612 73.4407 14.4134C73.414 17.1662 73.4334 19.9215 73.431 22.6744C71.9746 22.6792 70.5182 22.6768 69.0618 22.6744C69.069 17.3431 69.0642 12.0119 69.0642 6.68304Z"
                fill="#C7CED6"
              />
              <path
                d="M117.048 6.68088C118.502 6.6833 119.958 6.67845 121.412 6.68088C121.41 12.0121 121.41 17.3434 121.412 22.6746C119.958 22.6771 118.502 22.6771 117.048 22.6771C117.045 17.3434 117.045 12.0121 117.048 6.68088Z"
                fill="#C7CED6"
              />
              <path
                d="M181.023 6.68004C182.477 6.67762 183.933 6.67762 185.387 6.68004C185.385 12.0113 185.385 17.3426 185.387 22.6738C183.931 22.6762 182.477 22.6787 181.023 22.6738C181.021 17.3426 181.021 12.0113 181.023 6.68004Z"
                fill="#C7CED6"
              />
              <path
                d="M204.289 6.68048C205.808 6.67321 207.325 6.68048 208.845 6.68048C209.851 10.0828 210.827 13.4948 211.83 16.8995C212.887 13.4948 213.941 10.0901 214.981 6.68048C216.078 6.67805 217.176 6.67805 218.276 6.6829C219.34 10.0901 220.389 13.4996 221.473 16.902C222.459 13.4948 223.433 10.0876 224.412 6.67805C225.941 6.67805 227.473 6.67805 229.004 6.68048C227.281 12.0117 225.561 17.343 223.838 22.6767C222.418 22.6767 220.998 22.6767 219.578 22.6743C218.611 19.6476 217.627 16.6257 216.648 13.6038C215.662 16.6233 214.712 19.6548 213.735 22.6767C212.313 22.6743 210.89 22.6839 209.47 22.667C207.747 17.3381 206.01 12.0117 204.289 6.68048Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="238"
              height="30"
              viewBox="0 0 238 30"
              fill="none"
            >
              <path
                d="M220.29 24.7867H237.562V28.3622H220.29V24.7867ZM221.532 10.1515H236.834V13.6361H221.532V10.1515ZM227.32 1.06128H231.047V26.5139H227.32V1.06128ZM209.412 6.3639H219.351V9.66668H209.412V6.3639ZM213.745 16.7267L217.351 12.424V29.2409H213.745V16.7267ZM213.745 0.788574H217.351V7.90924H213.745V0.788574ZM216.987 13.4543C218.048 14.1815 221.472 17.2116 222.29 17.9691L220.078 20.9082C218.896 19.3326 216.351 16.5449 214.987 15.2117L216.987 13.4543ZM218.018 6.3639H218.745L219.381 6.2427L221.411 7.57593C219.108 13.4846 214.533 18.7872 210.018 21.6355C209.685 20.6658 208.836 18.8781 208.291 18.1812C212.412 15.9389 216.412 11.4847 218.018 7.03052V6.3639Z"
                fill="#C7CED6"
              />
              <path
                d="M180.384 24.7564C185.808 24.6049 194.232 24.3019 201.867 23.9686L201.746 27.3017C194.322 27.7259 186.323 28.0895 180.869 28.3016L180.384 24.7564ZM180.233 15.7268H205.443V19.1205H180.233V15.7268ZM185.626 9.90906H199.837V13.2421H185.626V9.90906ZM187.656 17.9387L191.808 19.0902C190.383 21.9384 188.747 25.0897 187.384 27.1199L184.202 26.0593C185.444 23.8474 186.899 20.484 187.656 17.9387ZM195.171 21.0597L198.383 19.3932C201.11 21.7263 203.928 24.9079 205.322 27.2108L201.958 29.2409C200.686 26.938 197.777 23.4838 195.171 21.0597ZM192.717 4.30343C190.262 7.75771 185.535 11.6362 180.505 14.03C180.081 13.1512 179.112 11.7877 178.354 10.9999C183.535 8.72733 188.384 4.39433 190.626 0.818848H194.474C197.686 5.27305 202.504 8.72733 207.321 10.4545C206.443 11.3635 205.625 12.6664 204.958 13.7572C200.413 11.6362 195.292 7.81831 192.717 4.30343Z"
                fill="#C7CED6"
              />
              <path
                d="M168.9 2.75863L171.324 0.758789C172.688 1.69811 174.475 3.12224 175.324 4.12217L172.779 6.30382C171.991 5.27359 170.294 3.75856 168.9 2.75863ZM148.841 5.90991H176.112V9.45509H148.841V5.90991ZM149.962 12.9397H163.082V16.4545H149.962V12.9397ZM154.568 14.9395H158.204V25.2115H154.568V14.9395ZM148.811 24.6963C152.477 24.1509 158.416 23.1207 163.87 22.0905L164.143 25.3327C159.143 26.4235 153.78 27.5446 149.841 28.3627L148.811 24.6963ZM163.719 0.879992H167.628C167.355 13.3336 169.658 25.4236 172.536 25.4236C173.203 25.4236 173.536 23.757 173.718 19.9391C174.536 20.7875 175.869 21.6663 176.9 22.0299C176.354 27.8173 175.112 29.2414 172.233 29.2414C165.658 29.2414 163.749 14.9092 163.719 0.879992Z"
                fill="#C7CED6"
              />
              <path
                d="M129.418 13.4548H146.114V16.7576H129.418V13.4548ZM132.539 6.00081H145.357V9.30359H132.539V6.00081ZM136.085 0.758789H139.63V29.2111H136.085V0.758789ZM135.115 15.1516L137.933 16C136.206 20.8179 133.327 25.3024 129.843 27.8476C129.297 26.9689 128.206 25.6963 127.419 25.0599C130.691 23.0298 133.63 19.0907 135.115 15.1516ZM140.781 15.3031C142.023 18.8786 144.448 22.6662 146.962 24.7872C146.144 25.4236 144.932 26.6962 144.326 27.6355C141.811 24.9993 139.569 20.4239 138.236 16.0909L140.781 15.3031ZM131.509 2.24352L134.812 2.81924C134.236 6.72803 133.054 10.5762 131.479 13.0003C130.843 12.4852 129.418 11.5761 128.6 11.1822C130.115 9.03088 131.024 5.6372 131.509 2.24352ZM118.237 6.42502H129.176V9.7581H118.237V6.42502ZM122.177 0.758789H125.722V29.2111H122.177V0.758789ZM122.146 8.90968L124.085 9.6672C123.237 14.6971 121.419 20.6057 119.328 23.757C118.965 22.6965 118.177 21.03 117.571 20.0603C119.601 17.5151 121.358 12.7276 122.146 8.90968ZM125.57 10.455C126.267 11.3337 128.782 15.2728 129.297 16.1818L127.206 18.6362C126.54 16.8182 124.813 13.273 123.964 11.7276L125.57 10.455Z"
                fill="#C7CED6"
              />
              <path
                d="M91.3001 4.72766C93.6333 4.78826 98.2996 4.75796 101.633 4.27315C104.693 3.81864 107.753 3.15202 109.268 2.4248L111.783 5.75788C110.753 6.03059 109.935 6.24269 109.45 6.3639C107.572 6.84871 105.148 7.24261 102.814 7.57592C99.3601 8.06073 94.9059 8.18194 92.2698 8.24254L91.3001 4.72766ZM92.6031 25.6351C97.9663 23.1808 100.36 19.1811 100.36 13.5149C100.36 13.5149 100.36 11.5453 100.36 7.24262L104.329 6.7881C104.329 9.39397 104.329 13.424 104.329 13.424C104.329 19.5447 102.33 24.7261 96.4816 28.241L92.6031 25.6351ZM89.0276 11.9998C89.876 12.0604 90.8759 12.1513 91.8455 12.1513C93.7545 12.1513 109.965 12.1513 111.996 12.1513C112.632 12.1513 114.056 12.0604 114.723 11.9998V15.9086C113.904 15.848 112.905 15.7874 112.056 15.7874C110.147 15.7874 93.6636 15.7874 91.8759 15.7874C90.9365 15.7874 89.8154 15.848 89.0276 15.9086V11.9998Z"
                fill="#C7CED6"
              />
              <path
                d="M62.4842 3.27344C63.4538 3.39464 64.6961 3.48554 65.7263 3.48554C67.6353 3.48554 76.1195 3.48554 77.9072 3.48554C78.9071 3.48554 80.1192 3.39464 81.1494 3.27344V7.18223C80.1192 7.12163 78.9374 7.06102 77.9072 7.06102C76.1195 7.06102 67.6353 7.06102 65.7263 7.06102C64.6961 7.06102 63.3932 7.12163 62.4842 7.18223V3.27344ZM58.939 11.0001C59.848 11.1213 60.9085 11.1819 61.8478 11.1819C63.7568 11.1819 79.907 11.1819 81.6948 11.1819C82.3311 11.1819 83.6643 11.1516 84.4825 11.0001V14.9392C83.6946 14.9089 82.5432 14.8786 81.6948 14.8786C79.907 14.8786 63.7568 14.8786 61.8478 14.8786C60.9691 14.8786 59.7874 14.9089 58.939 14.9695V11.0001ZM74.4529 13.1212C74.4529 17.0906 73.756 20.1206 72.4531 22.6356C71.4229 24.7566 69.0594 27.0898 66.3929 28.4836L62.8175 25.9081C64.9688 25.0596 67.1202 23.3325 68.3019 21.5448C69.8169 19.2419 70.2411 16.3936 70.2411 13.1515L74.4529 13.1212Z"
                fill="#C7CED6"
              />
              <path
                d="M41.3646 8.51514C41.9403 9.78777 43.425 13.7572 43.8492 15.151L40.2434 16.3933C39.8495 14.9389 38.5163 11.2725 37.7891 9.69687L41.3646 8.51514ZM52.5455 10.6968C52.2122 11.6967 52.0304 12.333 51.8486 12.939C51.0608 15.9994 49.7579 19.1507 47.6974 21.7868C44.8795 25.4532 41.1222 27.9682 37.577 29.3317L34.3954 26.0895C37.6982 25.1805 41.9403 22.8777 44.4249 19.7264C46.4854 17.1811 47.9701 13.1511 48.3034 9.33326L52.5455 10.6968ZM34.3348 10.0908C35.0923 11.6361 36.5771 15.3328 37.1528 17.0296L33.4561 18.3932C32.9713 16.7872 31.4259 12.7269 30.6987 11.424L34.3348 10.0908Z"
                fill="#C7CED6"
              />
              <path
                d="M18.1542 1.9397C18.9117 3.06083 19.9723 4.96977 20.548 6.0909L18.1239 7.15142C17.4876 5.90909 16.5786 4.12135 15.7605 2.93963L18.1542 1.9397ZM21.7297 0.576172C22.5175 1.7276 23.6386 3.63654 24.1841 4.69707L21.76 5.75759C21.154 4.48496 20.1541 2.72752 19.3359 1.57609L21.7297 0.576172ZM5.06431 3.27293C4.94311 4.18195 4.85221 5.54549 4.85221 6.42421C4.85221 8.27255 4.85221 19.4232 4.85221 21.6958C4.85221 23.029 5.45822 23.3623 6.82175 23.6047C7.73077 23.7562 8.9731 23.7865 10.3063 23.7865C13.4879 23.7865 18.3663 23.4229 20.8813 22.7563V27.1802C18.0027 27.6044 13.4273 27.7862 10.0942 27.7862C8.12468 27.7862 6.27634 27.665 4.97341 27.4529C2.36755 26.9681 0.822212 25.5439 0.822212 22.9078C0.822212 19.4232 0.822212 8.30285 0.822212 6.42421C0.822212 5.72729 0.73131 4.18195 0.610107 3.27293H5.06431ZM3.06446 12.5752C6.97325 11.7268 12.1547 10.0906 15.3362 8.78766C16.3968 8.33315 17.427 7.84834 18.639 7.15142L20.245 10.9693C19.0935 11.4541 17.73 12.0298 16.7301 12.4237C13.1546 13.7873 7.21566 15.575 3.09476 16.5446L3.06446 12.5752Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="82"
              height="43"
              viewBox="0 0 82 43"
              fill="none"
            >
              <path
                d="M1.91151 4.63108C3.55705 2.22649 6.5711 1.23473 9.36353 1.1073C13.53 0.963246 17.6965 1.20703 21.8519 1.47298C21.8519 3.11852 21.8464 4.76406 21.8464 6.4096C17.9126 6.14365 13.9622 5.65608 10.0118 5.84446C7.51853 5.87771 6.66529 9.36825 8.55461 10.7977C12.372 13.9835 17.6522 15.0362 21.0485 18.8149C23.8243 21.7347 24.0072 26.6769 21.5915 29.8627C19.885 32.0069 17.0593 32.8546 14.4165 32.971C10.4606 33.0873 6.51015 32.7937 2.56529 32.5443C2.56529 30.9265 2.55975 29.3087 2.56529 27.6964C6.32732 27.9457 10.0949 28.3446 13.868 28.1839C15.785 28.1451 16.3446 25.868 16.0122 24.3277C15.5579 22.5935 13.8957 21.5962 12.4496 20.743C9.07542 18.8149 5.19705 17.5295 2.4988 14.6096C-0.0110618 11.9612 -0.0276834 7.60081 1.91151 4.63108Z"
                fill="#C7CED6"
              />
              <path
                d="M24.866 1.07984C26.8994 1.0743 28.9272 1.0743 30.9606 1.07984C30.955 3.93876 30.955 6.79214 30.955 9.65106C34.1409 8.93079 37.72 8.00552 40.7729 9.65106C42.629 10.6594 43.1996 12.9144 43.1442 14.8813C43.1498 20.9261 43.1498 26.9652 43.1387 33.0044C41.0942 33.0044 39.0498 33.0044 37.0109 32.9823C37.033 27.3309 37.0053 21.6796 37.0275 16.0282C36.9665 15.247 37.1161 14.3384 36.5621 13.6957C34.6894 13.2801 32.7613 13.8342 30.9218 14.2054C30.9994 20.4717 30.944 26.7381 30.9495 33.0044C28.9217 32.9989 26.8938 32.9989 24.866 33.0044C24.8604 22.3611 24.866 11.7177 24.866 1.07984Z"
                fill="#C7CED6"
              />
              <path
                d="M55.051 1.3128C57.0622 0.332123 59.6607 2.14942 59.3283 4.3878C59.1731 6.65388 56.209 7.88388 54.4637 6.48212C52.5965 5.25212 52.9622 2.09401 55.051 1.3128Z"
                fill="#C7CED6"
              />
              <path
                d="M47.1945 1.7059C48.9232 0.980085 51.0452 2.49819 50.7349 4.39306C50.5576 6.30454 48.0034 7.30738 46.574 6.03306C45.0337 4.94711 45.4714 2.36522 47.1945 1.7059Z"
                fill="#C7CED6"
              />
              <path
                d="M63.3394 1.7557C64.8797 1.02989 66.8466 2.14354 66.9685 3.85556C67.2067 5.87786 64.6359 7.40151 62.9849 6.18259C61.3061 5.22408 61.5332 2.44272 63.3394 1.7557Z"
                fill="#C7CED6"
              />
              <path
                d="M40.0859 2.23779C41.3436 1.61725 43.0003 2.39292 43.1665 3.82238C43.438 5.41806 41.4544 6.70346 40.0915 5.8613C38.6565 5.20752 38.6842 2.91927 40.0859 2.23779Z"
                fill="#C7CED6"
              />
              <path
                d="M70.5143 2.21549C72.8967 0.92454 74.8858 4.82508 72.4092 5.89995C70.06 7.19089 68.1374 3.37346 70.5143 2.21549Z"
                fill="#C7CED6"
              />
              <path
                d="M34.6672 3.7783C35.4263 2.81979 37.2879 2.76438 37.1439 4.35452C36.5012 5.68979 34.9609 4.95844 34.6672 3.7783Z"
                fill="#C7CED6"
              />
              <path
                d="M75.9109 3.27407C76.4262 2.7588 77.1132 3.27961 77.695 3.3461C77.5509 4.04975 77.562 5.38502 76.4428 5.04705C75.5064 5.2188 75.0854 3.66745 75.9109 3.27407Z"
                fill="#C7CED6"
              />
              <path
                d="M45.1223 9.04141C47.1668 9.03033 49.2112 9.03587 51.2557 9.05249C51.2446 17.0309 51.2446 25.0092 51.2557 32.9876C49.2112 32.9987 47.1668 33.0098 45.1223 32.9987C45.1223 25.0148 45.1223 17.0309 45.1223 9.04141Z"
                fill="#C7CED6"
              />
              <path
                d="M53.2337 9.03564C55.2726 9.03564 57.3115 9.04119 59.356 9.04119C59.3393 17.4683 59.3671 25.8955 59.3449 34.3227C59.3172 36.3505 58.9792 38.7274 57.1841 39.974C55.1175 41.3204 52.5134 40.9436 50.1919 40.8439C50.1864 39.7856 50.1864 38.7274 50.1753 37.6692C51.3443 37.309 53.2281 37.0819 53.1783 35.4585C53.3002 26.6546 53.1783 17.8396 53.2337 9.03564Z"
                fill="#C7CED6"
              />
              <path
                d="M61.3281 9.04119C63.367 9.04119 65.406 9.03564 67.4504 9.03564C67.4615 17.0251 67.456 25.0146 67.4504 33.004C65.4115 33.004 63.367 32.9985 61.3281 32.9985C61.3281 25.009 61.3281 17.0251 61.3281 9.04119Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M45.805 38.4798H42.8967L38.7161 31.5727L34.5355 38.4798H31.6272L37.262 29.2097L34.5355 24.8473L26.1743 38.4798L8.90649 9.03369H27.8102L34.5355 20.6667L37.262 17.0314L32.5361 9.03369H35.2626L38.7161 14.8502L42.3514 9.03369H45.0779L40.352 17.0314L42.8967 20.6667L49.6221 9.03369H68.344L51.985 38.4798L42.8967 24.8473L40.352 29.2097L45.805 38.4798ZM26.5379 11.0329H13.269L25.9926 34.299L33.6268 22.8477L26.5379 11.0329ZM43.8057 22.8477L51.4399 11.0329H64.7088L51.4399 34.299L43.8057 22.8477ZM38.7162 19.3942L36.1715 22.8477L38.7162 26.6648L41.0792 22.8477L38.7162 19.3942Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M114.331 8.85205H111.059L111.968 12.3056H115.785L114.331 8.85205ZM72.1611 9.57911V31.5728H75.4329V12.3056H91.9736V28.6645H90.5195V31.5728H95.4272V9.57911H72.1611ZM79.0682 14.305H76.16L78.1594 20.8486L76.16 27.2104H79.0682L79.7953 25.211L80.8859 27.2104H83.4306L81.4312 20.8486L83.4306 14.305H80.8859L79.7953 16.1227L79.0682 14.305ZM87.0659 14.305H84.5212L86.7024 21.2121L84.5212 27.2104H87.0659L87.793 25.211L89.0654 27.2104H91.4283L89.0654 20.8486L91.4283 14.305H89.0654L87.793 16.668L87.0659 14.305ZM98.5172 9.57911V13.0327H99.426V27.7557L98.3354 28.1192L98.5172 31.5728L105.424 29.9369V31.5728H109.423V29.3916H110.332V25.7563H109.423V13.0327H110.332V9.57911H98.5172ZM118.875 8.85205H122.874L121.238 13.9415H122.874V17.5768H118.875V21.2121H122.51V25.211H118.875L123.237 31.391L118.875 31.5728L116.694 27.7557L114.331 31.5728L109.787 31.7546L113.786 25.211H110.332V21.2121H114.876V17.2133H111.059V13.9415H116.694L118.875 8.85205ZM129.963 9.57911H125.782L129.963 16.3045H134.143L129.963 9.57911ZM135.234 8.85205H139.233V17.5768H149.23V21.2121H143.413V28.6645H145.595L146.685 24.4839H150.139L148.321 31.7546H139.778V21.2121H135.234L129.963 31.5728L125.237 31.7546L131.053 21.2121H125.782V17.5768H135.234V8.85205ZM149.23 8.85205H145.049L140.323 16.668H144.868L149.23 8.85205ZM154.501 8.85205L151.048 19.5762H153.774V31.7546H157.228V17.2133H155.592L158.5 8.85205H154.501ZM167.952 8.85205H163.953L166.679 14.4868H170.86L167.952 8.85205ZM159.227 10.6697H162.862V14.4868L166.861 19.9398H167.952L172.132 14.4868V10.6697H175.95V15.3956L169.951 23.0298L176.495 31.5728L172.132 31.7546L167.407 26.1198L162.862 31.7546H157.955L164.68 23.0298L159.227 15.7592V10.6697ZM102.88 15.3954V13.2142H105.606V15.3954H102.88ZM102.88 21.3937V19.0308H105.606V21.3937H102.88ZM102.88 24.302V27.0285H105.606V24.302H102.88Z"
                fill="#C7CED6"
              />
              <path
                d="M84.9059 36.4797C84.9041 35.8454 85.4681 35.3319 86.0862 35.3066C86.7943 35.2508 87.5421 35.1985 88.2268 35.4292C88.8088 35.8634 88.9962 36.7193 88.7242 37.3842C88.4232 38.1356 88.4395 38.9429 88.5205 39.7339C87.5205 39.8204 87.097 38.7915 86.4177 38.2636C86.3042 38.7483 86.2123 39.2366 86.1294 39.7267C85.7294 39.651 85.0969 39.7555 84.9474 39.2654C84.814 38.3464 84.8789 37.4059 84.9059 36.4797ZM85.879 35.7391C85.369 36.0184 85.2267 36.6419 85.342 37.177C85.5114 37.9789 85.0213 38.9104 85.7186 39.5483C85.9835 39.0204 86.0375 38.2636 86.6952 38.0672C87.1205 38.6384 87.5962 39.1771 88.1764 39.597C88.1512 38.8708 88.0809 38.1338 88.2701 37.4239C88.3746 36.8851 88.5223 36.1716 88.0178 35.786C87.3745 35.422 86.5439 35.4418 85.879 35.7391Z"
                fill="#C7CED6"
              />
              <path
                d="M168.552 35.2799C169.577 35.1159 170.622 35.1159 171.658 35.1591C171.988 35.1339 172.186 35.4258 172.408 35.6204C172.302 35.6114 172.091 35.5952 171.986 35.5862C170.972 35.4745 169.95 35.5321 168.939 35.6529C169.339 36.0727 169.909 36.4349 170.002 37.0511C170.125 37.8368 170.082 38.635 170.092 39.426C170.406 39.4819 170.716 39.5486 171.031 39.6044C170.667 39.817 170.242 39.8152 169.835 39.7954C169.754 38.853 169.777 37.9052 169.772 36.9592C169.336 36.7682 168.826 36.6655 168.501 36.2943C168.487 35.9556 168.523 35.6168 168.552 35.2799Z"
                fill="#C7CED6"
              />
              <path
                d="M72.4442 35.9787C72.4262 35.4238 73.1307 35.2526 73.5452 35.4616C73.9686 36.0742 74.2749 36.777 74.8534 37.2743C75.1975 36.7824 75.5633 36.3049 75.9922 35.8814C75.7002 36.4941 75.3633 37.0833 75.0065 37.6581C74.2984 37.2473 73.8371 36.5644 73.5055 35.8328C73.2551 35.8166 73.0064 35.8022 72.7559 35.7859C72.6532 37.0112 72.5794 38.2419 72.6064 39.4708C72.8587 39.4384 73.1127 39.4059 73.365 39.3735C73.3362 38.851 73.3127 38.3302 73.2911 37.8077C73.965 37.7194 74.4822 38.1356 74.9038 38.6059C75.2083 38.3194 75.3993 37.759 75.8192 37.6869C76.2642 38.1266 76.1201 38.8816 76.2552 39.4618C76.4408 39.46 76.6246 39.46 76.8102 39.4582C76.7868 38.2437 76.7814 37.0274 76.6967 35.8166C76.4624 35.8382 76.2282 35.8598 75.9922 35.8814C76.021 35.7679 76.0768 35.5373 76.1057 35.422C76.4156 35.476 76.9093 35.4057 76.9616 35.8346C77.1346 36.9211 77.2174 38.0365 77.1688 39.1357C77.1616 39.7069 76.4931 39.6708 76.0877 39.7123C75.994 39.1555 75.9075 38.5969 75.7993 38.0437C75.4912 38.3681 75.1921 38.6996 74.8912 39.0312C74.6263 38.7483 74.365 38.4654 74.102 38.1843C74.0263 38.1915 73.8749 38.2023 73.7992 38.2095C73.7344 38.6816 73.6965 39.1627 73.5308 39.6132C73.1704 39.678 72.8046 39.6744 72.4406 39.6834C72.2622 38.4545 72.3685 37.2094 72.4442 35.9787Z"
                fill="#C7CED6"
              />
              <path
                d="M89.9856 35.5105C91.0433 35.4385 92.1262 35.3754 93.1479 35.716C93.4686 36.9286 93.4614 38.2242 93.184 39.4459C92.1677 39.7054 91.0992 39.6783 90.0649 39.5846C89.791 39.3558 89.7621 38.9774 89.7567 38.6476C89.7351 37.5971 89.8198 36.5484 89.9856 35.5105ZM90.8217 36.17C90.4631 36.9503 90.3658 37.8404 90.5514 38.6801C90.8145 39.217 91.6433 39.2224 92.0542 38.8639C92.51 38.2152 92.6704 37.2223 92.0794 36.5989C91.8271 36.1304 91.2703 36.2079 90.8217 36.17Z"
                fill="#C7CED6"
              />
              <path
                d="M78.4301 35.8115C78.8463 35.8313 79.3851 35.7683 79.4464 36.3161C79.4518 37.3449 79.3058 38.372 79.3725 39.4045C79.0607 39.4243 78.7472 39.4387 78.4355 39.4514C78.3832 38.2387 78.3688 37.0224 78.4301 35.8115Z"
                fill="#C7CED6"
              />
              <path
                d="M118.427 35.4849C119.03 35.4597 119.634 35.4705 120.238 35.5066C120.247 35.575 120.267 35.712 120.276 35.7805C120.29 35.894 120.319 36.1228 120.335 36.2381C120.122 36.148 119.912 36.0561 119.701 35.966C119.704 36.2345 119.71 36.5048 119.706 36.7751C119.485 36.6706 119.25 36.5931 119.029 36.4922C118.998 36.8129 118.976 37.1355 118.96 37.458C118.692 37.4328 118.425 37.3968 118.158 37.3607C118.034 37.7391 117.899 38.1157 117.834 38.5103C117.767 38.8563 118.003 39.168 118.054 39.4924C117.946 39.5374 117.728 39.6257 117.62 39.6707C117.465 38.8419 117.677 38.0112 117.713 37.1823C117.742 36.5481 118.128 36.0201 118.427 35.4849Z"
                fill="#C7CED6"
              />
              <path
                d="M137.552 35.5498C138.372 35.3588 139.413 35.1372 140.109 35.748C139.669 36.3769 138.775 36.1264 138.119 36.2219C138.112 36.5733 138.11 36.9228 138.11 37.2742C138.734 37.3445 139.375 37.3607 139.977 37.5607C140.484 38.1553 140.123 39.0058 140.055 39.6978C139.094 39.6293 138.132 39.6617 137.172 39.6365C137.238 39.4563 137.307 39.2743 137.375 39.0941C138.011 39.0797 138.647 39.0671 139.285 39.0527C139.255 39.1446 139.192 39.3302 139.161 39.4221C139.413 39.3986 139.666 39.3716 139.918 39.3464C139.925 38.831 139.93 38.3175 139.93 37.8022C139.604 37.7679 139.28 37.7319 138.956 37.6941C139.127 37.9211 139.3 38.1481 139.46 38.3842C138.716 38.1625 137.91 38.1157 137.211 37.7625C136.386 37.2454 136.613 35.8057 137.552 35.5498ZM137.109 36.6742C137.022 37.094 137.401 37.3499 137.651 37.6148C137.736 36.9607 137.835 36.3102 137.983 35.6687C137.656 35.9588 137.119 36.1696 137.109 36.6742Z"
                fill="#C7CED6"
              />
              <path
                d="M173.77 35.4815C174.669 35.1932 175.632 35.3211 176.558 35.3626C176.448 35.6346 176.345 35.9103 176.241 36.1824C175.655 36.2455 175.071 36.3176 174.489 36.4022C174.576 36.6041 174.653 36.8131 174.763 37.0041C175.226 37.2816 175.792 37.287 176.306 37.4221C176.385 37.4672 176.545 37.5573 176.624 37.6023C176.659 38.2762 176.641 38.9501 176.677 39.6222C175.477 39.7123 174.224 39.9232 173.064 39.4961C173.161 39.3159 173.235 39.0745 173.48 39.0582C174.167 38.8907 174.95 39.0078 175.523 38.5051C174.787 38.2618 173.979 38.2474 173.278 37.9104C172.559 37.2545 172.765 35.7554 173.77 35.4815ZM173.433 36.0743C173.024 36.3788 172.976 37.0005 173.237 37.4167C173.565 37.5771 173.922 37.6726 174.269 37.7861C174.23 37.1951 174.21 36.6041 174.219 36.0148C174.857 35.932 175.496 35.8581 176.129 35.7373C175.241 35.4617 174.19 35.4815 173.433 36.0743ZM174.738 37.7068C175.086 37.914 175.46 38.0906 175.763 38.3663C175.999 38.642 175.778 39.1519 175.414 39.1664C174.758 39.3105 174.078 39.2547 173.415 39.3375C174.359 39.5357 175.329 39.4493 176.287 39.4601C176.385 38.8438 176.399 38.215 176.298 37.5987C175.778 37.6239 175.257 37.66 174.738 37.7068Z"
                fill="#C7CED6"
              />
              <path
                d="M81.122 35.676C82.0103 35.4364 82.9347 35.4328 83.8464 35.5085C83.8032 35.7986 83.7563 36.0905 83.7059 36.3788C83.1491 36.368 82.5941 36.3644 82.0373 36.3626C81.6878 37.2094 81.7778 38.1356 81.895 39.0185C82.5455 39.0672 83.1977 39.0762 83.85 39.0816C83.8464 39.1969 83.8374 39.4258 83.8338 39.5411C82.8986 39.6438 81.9472 39.66 81.0174 39.4961C80.8787 38.2311 80.7832 36.9175 81.122 35.676Z"
                fill="#C7CED6"
              />
              <path
                d="M141.46 35.4991C142.444 35.4396 143.433 35.4757 144.421 35.4811C144.513 36.2127 143.956 36.6325 143.415 36.9857C143.433 37.8055 143.448 38.6254 143.444 39.4453C143.089 39.5155 142.734 39.5894 142.377 39.6345C142.332 38.8182 142.402 37.9983 142.323 37.1857C142.271 36.4992 141.424 36.1928 141.46 35.4991Z"
                fill="#C7CED6"
              />
              <path
                d="M85.879 35.7388C86.5439 35.4415 87.3745 35.4217 88.0178 35.7857C88.5223 36.1713 88.3746 36.8848 88.2701 37.4236C88.0809 38.1335 88.1512 38.8705 88.1764 39.5967C87.5962 39.1768 87.1205 38.6381 86.6952 38.0669C86.0375 38.2633 85.9835 39.0201 85.7186 39.548C85.0213 38.9102 85.5114 37.9786 85.342 37.1767C85.2267 36.6416 85.369 36.0181 85.879 35.7388ZM86.3979 36.2596C86.333 36.5983 86.3186 36.9407 86.2916 37.2831C86.6394 37.384 86.9907 37.4993 87.3601 37.4921C87.8593 37.1894 87.5133 36.5785 87.4286 36.1443C87.0844 36.1731 86.7349 36.1803 86.3979 36.2596Z"
                fill="#C7CED6"
              />
              <path
                d="M97.339 35.638C98.3895 35.3803 99.5067 35.4001 100.55 35.683C100.827 36.9353 100.824 38.2471 100.579 39.5048C99.6022 39.6868 98.5985 39.6418 97.6111 39.6003C97.3084 39.6111 97.157 39.2616 97.1732 39.0021C97.1516 37.8813 97.0579 36.7371 97.339 35.638ZM97.9426 36.7767C97.7805 37.393 97.8201 38.0561 97.939 38.6759C98.3697 39.3246 99.2184 39.0507 99.831 38.8904C99.9445 38.1318 100.31 37.1876 99.6851 36.5569C99.2562 35.9785 98.1823 36.056 97.9426 36.7767Z"
                fill="#C7CED6"
              />
              <path
                d="M101.844 35.6854C102.837 35.3683 103.896 35.4098 104.914 35.5647C104.979 35.8044 105.042 36.044 105.105 36.2837C104.862 36.1611 104.619 36.0368 104.376 35.9125C104.43 36.3954 104.482 36.8783 104.511 37.3648C104.426 37.3774 104.258 37.4027 104.174 37.4153C104.114 37.0639 104.051 36.7143 103.992 36.363C103.649 36.3557 103.307 36.3467 102.965 36.3377C102.95 36.7269 102.943 37.1162 102.948 37.5054C102.716 37.5144 102.484 37.536 102.251 37.5504C102.127 38.0748 101.995 38.5973 101.849 39.1163C102.188 38.7775 102.543 38.4459 102.967 38.2135C102.824 38.6406 102.678 39.0676 102.538 39.4965C102.246 39.464 101.925 39.5289 101.664 39.3739C101.711 38.1468 101.498 36.8801 101.844 35.6854Z"
                fill="#C7CED6"
              />
              <path
                d="M106.392 35.6994C107.307 35.6219 108.226 35.593 109.145 35.6183C109.16 35.6904 109.187 35.8363 109.201 35.9084C108.814 36.2706 108.147 36.4381 108.037 37.0201C107.943 37.8238 108.023 38.6346 107.99 39.4419C107.862 39.4545 107.605 39.4815 107.477 39.496C107.39 38.6365 107.489 37.7679 107.399 36.9102C107.226 36.4039 106.731 36.094 106.392 35.6994Z"
                fill="#C7CED6"
              />
              <path
                d="M129.97 35.6906C130.141 35.6726 130.314 35.6528 130.485 35.6348C130.68 36.9682 130.781 38.3592 130.438 39.6782C130.271 39.6061 130.105 39.5341 129.939 39.462C129.919 38.2061 129.89 36.9465 129.97 35.6906Z"
                fill="#C7CED6"
              />
              <path
                d="M113.369 35.6526C114.331 35.4417 115.372 35.3102 116.313 35.6796C116.255 35.8147 116.14 36.0886 116.082 36.2238C115.511 36.3319 114.918 36.3337 114.361 36.4959C113.666 37.1193 114.051 38.0815 114.142 38.8654C114.848 38.9609 115.601 38.9717 116.225 39.3591C115.313 39.7879 114.28 39.633 113.314 39.5393C113.143 38.2527 113.114 36.9283 113.369 35.6526Z"
                fill="#C7CED6"
              />
              <path
                d="M122.128 39.4819C121.989 38.1791 121.807 36.7844 122.25 35.5249C122.495 35.7411 122.928 35.8961 122.91 36.2889C122.935 37.1106 122.885 37.9322 122.922 38.7539C123.663 39.0296 124.452 39.1035 125.238 39.1071C125.254 39.2026 125.288 39.3954 125.305 39.4909C124.258 39.6837 123.173 39.6927 122.128 39.4819Z"
                fill="#C7CED6"
              />
              <path
                d="M145.336 37.0073C145.114 36.2937 145.702 35.5315 146.424 35.4792C147.142 35.4234 147.87 35.4162 148.585 35.5135C149.091 36.2144 148.852 37.137 148.715 37.919C148.579 38.5064 148.826 39.1173 148.643 39.6957C147.565 39.8038 147.652 38.0145 146.54 38.3371C146.524 38.7317 146.515 39.1245 146.509 39.5191C146.109 39.5605 145.569 39.7894 145.314 39.3425C145.152 38.5641 145.572 37.7857 145.336 37.0073ZM146.706 37.2001C147.077 37.6253 148.183 37.6001 147.99 36.8379C148.093 36.1928 147.338 36.173 146.895 36.1315C146.801 36.4811 146.677 36.8325 146.706 37.2001Z"
                fill="#C7CED6"
              />
              <path
                d="M154.857 35.4849C155.257 35.7335 155.656 35.9912 155.996 36.3209C155.811 37.3823 155.171 38.3391 155.299 39.4526C155.174 39.4779 154.926 39.5283 154.802 39.5535C154.783 38.1985 154.764 36.8381 154.857 35.4849Z"
                fill="#C7CED6"
              />
              <path
                d="M157.285 37.2867C157.58 36.5767 157.804 35.6271 158.697 35.4722C159.024 35.8488 158.968 36.3659 158.973 36.8272C158.957 37.7552 159.006 38.685 158.944 39.6111C158.71 39.5877 158.478 39.5643 158.245 39.539C158.232 38.9895 158.213 38.4399 158.178 37.8903C157.555 38.2056 156.939 38.5372 156.285 38.7895C156.121 38.4615 155.87 38.193 155.582 37.9696C155.522 38.4507 155.499 38.9354 155.447 39.4165L155.299 39.4526C155.171 38.339 155.811 37.3822 155.996 36.3209C156.027 36.3803 156.088 36.4992 156.119 36.5587C156.187 36.811 156.247 37.065 156.31 37.3191C156.634 37.3101 156.959 37.2993 157.285 37.2867ZM157.946 36.4308C157.861 36.7191 157.834 37.0182 157.793 37.3137C158.025 37.3173 158.261 37.3227 158.497 37.3263C158.627 36.8596 158.701 36.3785 158.714 35.892C158.451 36.0578 158.135 36.1749 157.946 36.4308Z"
                fill="#C7CED6"
              />
              <path
                d="M159.995 35.6508L160.144 35.631C161.128 35.4562 162.132 35.4292 163.126 35.5391C163.126 35.658 163.128 35.8941 163.128 36.013C162.519 36.0671 161.912 36.1391 161.303 36.2004C161.305 36.5248 161.297 36.8491 161.335 37.1716C161.67 37.341 162.034 37.4473 162.393 37.5644C162.449 37.6545 162.559 37.8365 162.615 37.9284C162.18 38.0203 161.744 38.105 161.317 38.2275L161.296 38.3717C161.272 38.5465 161.252 38.7231 161.238 38.8979C161.863 39.0276 162.499 39.0871 163.139 39.0798C163.139 39.2114 163.137 39.4781 163.137 39.6114C162.143 39.6456 161.137 39.7249 160.15 39.5826C159.778 39.3303 159.874 38.8474 159.856 38.4654C159.912 37.5266 159.757 36.5734 159.995 35.6508Z"
                fill="#C7CED6"
              />
              <path
                d="M164.171 36.6525C164.152 36.2453 164.197 35.8363 164.344 35.4525C164.692 35.4651 165.042 35.4759 165.393 35.4849C165.447 35.6093 165.555 35.8597 165.609 35.9858C165.227 36.0237 164.845 36.0597 164.465 36.0976C164.528 36.4363 164.579 36.7823 164.71 37.103C165.042 37.2886 165.427 37.3409 165.795 37.4328C165.757 37.1589 165.719 36.885 165.683 36.6111C166.036 36.7553 166.309 37.0201 166.564 37.2958C166.727 37.2994 166.889 37.303 167.053 37.3084C167.146 36.7084 166.795 35.8759 167.309 35.4507C167.406 35.4597 167.597 35.4795 167.694 35.4885C168.098 36.0381 167.943 36.7462 167.968 37.3787C167.923 38.1553 168.083 38.9662 167.772 39.7032C167.48 39.6617 167.19 39.6221 166.903 39.5662C167.049 39.4761 167.345 39.2941 167.491 39.2022C167.469 38.7013 167.438 38.1986 167.402 37.6976C166.993 37.8472 166.593 38.0418 166.316 38.386C165.963 38.177 165.613 37.9607 165.267 37.7373C165.26 38.2905 165.29 38.8437 165.276 39.3968C165.103 39.8329 164.552 39.6041 164.206 39.6167C164.096 38.6328 164.204 37.6418 164.171 36.6525Z"
                fill="#C7CED6"
              />
              <path
                d="M168.939 35.6529C169.95 35.5321 170.972 35.4745 171.986 35.5862C171.975 35.8925 171.968 36.197 171.923 36.4998C171.637 36.6349 171.338 36.7394 171.037 36.8295C171.017 37.7539 171.055 38.6801 171.031 39.6044C170.716 39.5486 170.406 39.4819 170.092 39.426C170.082 38.635 170.125 37.8368 170.002 37.0511C169.909 36.4349 169.339 36.0727 168.939 35.6529Z"
                fill="#C7CED6"
              />
              <path
                d="M135.166 35.7803C135.267 35.8001 135.469 35.8415 135.57 35.8632C135.637 37.0975 135.615 38.3372 135.577 39.5715C134.539 39.53 134.453 38.3282 133.781 37.7768C133.537 37.566 133.06 37.2578 132.881 37.6975C132.746 38.2633 132.831 38.8525 132.844 39.4273C132.718 39.4543 132.463 39.5084 132.337 39.5336C132.136 38.6273 132.262 37.6957 132.413 36.7929C132.503 36.9966 132.593 37.2002 132.687 37.402C132.986 37.184 133.283 36.9641 133.581 36.7425C133.966 37.0957 134.31 37.647 134.892 37.6362C135.192 37.065 135.132 36.4019 135.166 35.7803ZM134.586 38.2903C134.541 38.8345 135.028 39.1552 135.422 39.4183C135.429 38.8399 135.363 38.2633 135.224 37.7029C134.997 37.8777 134.559 37.9281 134.586 38.2903Z"
                fill="#C7CED6"
              />
              <path
                d="M173.433 36.0741C174.19 35.4813 175.241 35.4615 176.129 35.7372C175.496 35.8579 174.857 35.9318 174.219 36.0147C174.21 36.6039 174.23 37.1949 174.269 37.7859C173.922 37.6724 173.565 37.5769 173.237 37.4166C172.976 37.0003 173.024 36.3787 173.433 36.0741Z"
                fill="#C7CED6"
              />
              <path
                d="M72.7558 35.7861C73.0062 35.8023 73.2549 35.8168 73.5053 35.833C73.8369 36.5646 74.2982 37.2475 75.0063 37.6583C75.3631 37.0835 75.7001 36.4943 75.992 35.8816C76.228 35.86 76.4623 35.8384 76.6965 35.8168C76.7812 37.0276 76.7866 38.2439 76.81 39.4584C76.6244 39.4602 76.4406 39.4602 76.255 39.462C76.1199 38.8818 76.2641 38.1268 75.819 37.6871C75.3991 37.7592 75.2081 38.3196 74.9036 38.6061C74.482 38.1358 73.9648 37.7196 73.2909 37.8079C73.3125 38.3304 73.336 38.8512 73.3648 39.3737C73.1125 39.4061 72.8585 39.4386 72.6062 39.471C72.5792 38.2421 72.6531 37.0114 72.7558 35.7861ZM72.954 36.0654C73.0008 36.4871 73.0585 36.9069 73.1233 37.325C73.4117 37.3232 73.7 37.3195 73.9901 37.3159C73.7882 36.7934 73.5612 36.215 72.954 36.0654ZM75.8388 37.5286C76.0694 37.561 76.2659 37.4168 76.4442 37.2925C76.5001 36.8889 76.4713 36.4781 76.4605 36.0726C76.0785 36.4492 75.41 36.9339 75.8388 37.5286Z"
                fill="#C7CED6"
              />
              <path
                d="M111.039 35.8363C111.214 35.8345 111.39 35.8309 111.565 35.8291C111.619 37.0382 111.612 38.2509 111.578 39.4617C111.392 39.4617 111.205 39.4635 111.021 39.4635C110.979 38.2545 110.97 37.0436 111.039 35.8363Z"
                fill="#C7CED6"
              />
              <path
                d="M120.275 35.7803C120.468 36.6398 120.504 37.5335 120.802 38.3696C120.928 38.7228 120.973 39.0976 121.009 39.4706C120.71 39.4597 120.412 39.4453 120.113 39.4273C120.032 39.121 119.942 38.8183 119.854 38.5138C119.457 38.4921 119.059 38.4669 118.664 38.521C118.409 38.8579 118.268 39.2651 118.182 39.6742C117.994 39.721 117.807 39.6363 117.619 39.6706C117.727 39.6255 117.946 39.5372 118.054 39.4922C118.003 39.1678 117.767 38.8561 117.834 38.5101C117.899 38.1155 118.034 37.7389 118.158 37.3605C118.425 37.3966 118.692 37.4326 118.96 37.4578C119.203 37.4777 119.447 37.4939 119.692 37.5083C119.699 37.2632 119.702 37.02 119.706 36.7749C119.71 36.5046 119.704 36.2344 119.701 35.9659C119.911 36.056 120.122 36.1479 120.335 36.238C120.319 36.1226 120.29 35.8938 120.275 35.7803Z"
                fill="#C7CED6"
              />
              <path
                d="M132.535 35.6597C132.973 35.9245 133.339 36.2885 133.581 36.7426C133.283 36.9642 132.986 37.1841 132.687 37.4021C132.593 37.2003 132.503 36.9967 132.413 36.7931C132.179 36.4273 132.453 36.0291 132.535 35.6597Z"
                fill="#C7CED6"
              />
              <path
                d="M166.316 38.3861C166.593 38.0419 166.993 37.8473 167.402 37.6978C167.438 38.1987 167.469 38.7014 167.491 39.2023C167.345 39.2942 167.049 39.4762 166.903 39.5663C166.687 39.1861 166.334 38.8492 166.316 38.3861Z"
                fill="#C7CED6"
              />
              <path
                d="M137.109 36.6744C137.119 36.1699 137.656 35.9591 137.982 35.6689C137.835 36.3104 137.736 36.9609 137.651 37.615C137.4 37.3501 137.022 37.0942 137.109 36.6744Z"
                fill="#C7CED6"
              />
              <path
                d="M150.248 35.7931C150.473 35.7859 150.699 35.7787 150.926 35.7715C150.969 36.667 150.915 37.5626 150.942 38.4581C150.809 37.6382 150.814 36.7968 150.563 35.9985C150.401 36.9067 150.263 37.8365 150.36 38.7572C150.374 39.1284 150.855 39.4672 151.16 39.4113C151.138 39.1627 150.982 38.8762 150.967 38.5969C151.03 38.687 151.16 38.8653 151.223 38.9554C151.6 38.9807 151.978 39.0077 152.356 39.0311C152.295 39.1482 152.232 39.2654 152.167 39.3825C152.223 39.4149 152.337 39.4834 152.392 39.5158C151.681 39.5699 150.969 39.5374 150.259 39.4852C150.045 38.2635 150.124 37.0202 150.248 35.7931Z"
                fill="#C7CED6"
              />
              <path
                d="M104.376 35.9116C104.619 36.036 104.862 36.1603 105.105 36.2828C105.111 36.9099 105.096 37.5351 105.06 38.1604C104.363 38.1946 103.664 38.1766 102.967 38.2126C102.543 38.4451 102.188 38.7766 101.849 39.1154C101.995 38.5964 102.127 38.0739 102.251 37.5495C102.484 37.5351 102.716 37.5135 102.949 37.5045C103.358 37.4757 103.767 37.4576 104.174 37.4144C104.259 37.4018 104.426 37.3766 104.511 37.3639C104.482 36.8774 104.43 36.3945 104.376 35.9116Z"
                fill="#C7CED6"
              />
              <path
                d="M152.356 39.0312C152.911 38.0077 152.524 36.786 152.911 35.7085C153.67 36.8185 153.288 38.2095 153.19 39.4492C152.924 39.4727 152.657 39.4961 152.392 39.5159C152.336 39.4835 152.223 39.415 152.167 39.3826C152.232 39.2654 152.295 39.1483 152.356 39.0312Z"
                fill="#C7CED6"
              />
              <path
                d="M157.946 36.4309C158.135 36.175 158.45 36.0579 158.713 35.8921C158.701 36.3786 158.627 36.8597 158.497 37.3264C158.261 37.3228 158.025 37.3174 157.793 37.3138C157.834 37.0183 157.861 36.7192 157.946 36.4309Z"
                fill="#C7CED6"
              />
              <path
                d="M164.465 36.098C164.845 36.0602 165.227 36.0242 165.609 35.9863C165.629 36.1953 165.654 36.4044 165.683 36.6116C165.719 36.8855 165.757 37.1594 165.795 37.4333C165.427 37.3414 165.042 37.2891 164.71 37.1035C164.579 36.7828 164.528 36.4368 164.465 36.098Z"
                fill="#C7CED6"
              />
              <path
                d="M72.9541 36.0649C73.5613 36.2145 73.7884 36.7929 73.9902 37.3155C73.7001 37.3191 73.4118 37.3227 73.1235 37.3245C73.0586 36.9064 73.001 36.4866 72.9541 36.0649Z"
                fill="#C7CED6"
              />
              <path
                d="M75.8388 37.5287C75.4099 36.9341 76.0784 36.4494 76.4605 36.0728C76.4713 36.4782 76.5001 36.889 76.4442 37.2926C76.2658 37.417 76.0694 37.5611 75.8388 37.5287Z"
                fill="#C7CED6"
              />
              <path
                d="M150.564 35.9985C150.814 36.7968 150.809 37.6383 150.942 38.4581L150.967 38.5969C150.982 38.8762 151.139 39.1627 151.16 39.4113C150.856 39.4672 150.375 39.1284 150.36 38.7572C150.263 37.8365 150.402 36.9067 150.564 35.9985Z"
                fill="#C7CED6"
              />
              <path
                d="M174.738 37.7067C175.257 37.6599 175.777 37.6239 176.298 37.5986C176.399 38.2149 176.385 38.8437 176.287 39.46C175.329 39.4492 174.359 39.5357 173.415 39.3375C174.078 39.2546 174.757 39.3104 175.413 39.1663C175.777 39.1519 175.999 38.6419 175.763 38.3662C175.46 38.0906 175.085 37.914 174.738 37.7067Z"
                fill="#C7CED6"
              />
              <path
                d="M134.586 38.2905C134.559 37.9284 134.997 37.8779 135.224 37.7031C135.363 38.2635 135.429 38.8401 135.422 39.4185C135.028 39.1555 134.541 38.8347 134.586 38.2905Z"
                fill="#C7CED6"
              />
              <path
                d="M138.956 37.6943C139.28 37.7322 139.604 37.7682 139.93 37.8024C139.93 38.3178 139.925 38.8313 139.918 39.3467C139.666 39.3719 139.413 39.3989 139.161 39.4224C139.192 39.3305 139.255 39.1449 139.285 39.053C139.345 38.8313 139.408 38.6097 139.46 38.3845C139.3 38.1484 139.127 37.9214 138.956 37.6943Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <g opacity="0.95">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M74.7493 18.0988H70.9004L70.1247 22.522H74.0024C74.6727 22.522 75.2521 22.3119 75.74 21.89C76.229 21.4691 76.5301 20.9424 76.6449 20.3104C76.7602 19.6977 76.6494 19.1755 76.3144 18.7452C75.9795 18.3144 75.4578 18.0988 74.7493 18.0988ZM75.9841 28.4105C76.0994 27.7208 75.9747 27.1569 75.611 26.7161C75.2472 26.2754 74.7012 26.0553 73.9737 26.0553H69.5216L68.6893 30.7946H73.0833C73.8301 30.7946 74.4666 30.57 74.9933 30.1193C75.52 29.6696 75.8505 29.1002 75.9841 28.4105ZM80.6091 20.1957C80.3596 21.8613 79.5075 23.1924 78.0521 24.1882C79.5269 25.2222 80.1301 26.7636 79.8618 28.8127C79.6128 30.4977 78.8422 31.8671 77.5502 32.9195C76.2573 33.9729 74.5292 34.4996 72.3652 34.4996H64.0642L67.5971 14.3936H75.2089C76.9709 14.3936 78.3781 14.9391 79.4315 16.0308C80.4844 17.122 80.8769 18.5108 80.6091 20.1957Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M92.5862 20.1382H96.694L88.9381 34.4997C87.7704 36.6831 86.4914 38.2095 85.1036 39.081C83.7148 39.9526 82.1208 40.3402 80.321 40.2448L80.9243 36.7973C81.9389 36.8162 82.7434 36.6299 83.3377 36.2374C83.9304 35.8448 84.486 35.1412 85.0032 34.1261L85.0892 33.983L81.7575 20.1382H85.5488L87.6745 29.8176L92.5862 20.1382Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M111.928 14.3936L111.268 18.1848H105.839L102.967 34.4996H99.0025L101.876 18.1848H96.4749L97.1357 14.3936H111.928Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M115.117 22.4361C116.15 20.6747 117.759 19.8131 119.942 19.8513L119.224 23.9874C118.553 23.9109 117.898 23.9541 117.256 24.1166C116.615 24.2791 115.997 24.6478 115.403 25.2222C114.81 25.7971 114.417 26.5533 114.226 27.4915L112.991 34.4996H109.286L111.813 20.138H115.519L115.117 22.4361Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M127.18 31.369C128.29 31.369 129.277 31.0202 130.138 30.3205C131 29.6219 131.555 28.6977 131.804 27.5489L131.862 27.0893C132.014 25.9216 131.77 24.9924 131.129 24.3027C130.487 23.614 129.621 23.2692 128.53 23.2692C127.399 23.2692 126.404 23.6285 125.542 24.346C124.681 25.0645 124.145 26.0458 123.934 27.29C123.723 28.497 123.924 29.4778 124.537 30.2346C125.15 30.9908 126.03 31.369 127.18 31.369ZM133.097 20.1378H136.802L134.274 34.4999H130.569L130.799 33.1786C129.554 34.3274 127.965 34.9018 126.031 34.9018C123.867 34.9018 122.259 34.1078 121.206 32.5183C120.152 30.9282 119.827 29.0813 120.229 26.974C120.592 24.9253 121.517 23.2071 123.001 21.8183C124.484 20.4305 126.261 19.7363 128.329 19.7363C130.358 19.7363 131.833 20.5115 132.752 22.0628L133.097 20.1378Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M144.643 31.369C145.811 31.369 146.83 30.9958 147.702 30.2485C148.573 29.5022 149.105 28.5352 149.296 27.3477C149.526 26.103 149.325 25.1122 148.693 24.3748C148.061 23.6379 147.171 23.2692 146.022 23.2692C144.892 23.2692 143.887 23.6285 143.006 24.346C142.125 25.0645 141.579 26.0458 141.368 27.29C141.158 28.497 141.363 29.4778 141.986 30.2346C142.608 30.9908 143.494 31.369 144.643 31.369ZM151.594 14.3936H155.299L151.766 34.4996H148.061L148.291 33.1208C147.046 34.3083 145.438 34.9016 143.465 34.9016C141.301 34.9016 139.693 34.1076 138.64 32.518C137.587 30.928 137.262 29.0811 137.663 26.9738C138.027 24.9251 138.951 23.2069 140.435 21.8181C141.919 20.4302 143.695 19.7361 145.763 19.7361C147.832 19.7361 149.325 20.5306 150.244 22.1202L151.594 14.3936Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M159.779 25.9399H167.076C167.056 24.9635 166.764 24.241 166.199 23.7715C165.634 23.3024 164.911 23.0679 164.031 23.0679C163.073 23.0679 162.226 23.3173 161.488 23.8147C160.751 24.3131 160.182 25.0207 159.779 25.9399ZM158.631 21.8183C160.258 20.4305 162.135 19.7363 164.261 19.7363C166.386 19.7363 168.062 20.4682 169.287 21.9336C170.513 23.3984 170.924 25.1936 170.522 27.3188C170.483 27.7402 170.35 28.248 170.12 28.8413H159.263C159.339 29.76 159.694 30.4403 160.326 30.8805C160.958 31.3213 161.752 31.5409 162.709 31.5409C164.146 31.5409 165.323 31.0529 166.242 30.0761L168.857 32.1729C167.248 33.9925 165.103 34.9018 162.422 34.9018C160.048 34.9018 158.243 34.1456 157.008 32.6331C155.773 31.12 155.337 29.2532 155.701 27.0316C156.026 24.9447 157.003 23.2071 158.631 21.8183Z"
                  fill="#C7CED6"
                />
              </g>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M48.3157 18.2861L45.7651 24.5394C46.3625 25.3218 46.8343 26.2032 47.1427 27.1559L48.3439 30.8673C48.3487 30.8821 48.3509 30.8968 48.3552 30.9116L53.5054 18.2861H48.3157Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.0678 5.91855C56.7307 5.68221 56.2107 5.69221 56.4187 5.88857C57.2668 6.68966 57.7959 7.82484 57.7959 9.08382C57.7959 11.5058 55.8375 13.4703 53.4177 13.4807H40.8569C42.3027 14.4134 43.4318 15.8236 43.9983 17.5444L44.2399 18.2782C44.2407 18.2808 44.2416 18.2838 44.2425 18.286H48.3157H53.3981V18.3012C57.0495 18.1765 59.9724 15.1785 59.9724 11.4967C59.9724 9.18852 58.8242 7.14973 57.0678 5.91855Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.0757 22.3525L34.7124 33.7372C35.4222 35.4802 37.0966 36.6062 38.9785 36.6062H43.2737C44.5801 36.6062 45.8187 35.9754 46.5872 34.9185C47.3561 33.8623 47.5742 32.4891 47.1723 31.2466L45.9711 27.5348V27.5344C44.9672 24.4347 42.1043 22.3521 38.846 22.3521L30.0757 22.3525ZM37.2214 27.1573H38.8461C40.0135 27.1573 41.0396 27.9037 41.3993 29.0145L42.117 31.2323C42.2078 31.5134 41.9988 31.801 41.7038 31.801H39.1129L37.2214 27.1573Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36.6816 18.2861C37.4031 18.2861 38.0387 18.7466 38.2646 19.4313L38.5057 20.1655C38.6747 20.679 38.59 21.2251 38.2729 21.663C37.9557 22.1009 37.464 22.352 36.9235 22.352H35.2618L33.9933 19.2475H28.8022L32.034 27.1572H36.9231H36.924C39.0236 27.1572 40.9346 26.1811 42.1658 24.4798C43.3974 22.7782 43.7267 20.6586 43.0703 18.6637L42.8288 17.9295C42.5607 17.1153 41.8813 16.833 41.0676 16.8325C39.2208 16.8325 36.6816 18.2861 36.6816 18.2861Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.3682 5.74121C25.2222 5.74469 25.0106 5.80116 24.8434 5.91846C23.087 7.14964 21.9392 9.18843 21.9392 11.4966C21.9392 15.1784 24.8616 18.176 28.5135 18.3007V18.2859H33.6003H36.6817C37.4033 18.2859 38.0389 18.7464 38.2648 19.4311L38.5059 20.1652C38.6492 20.6005 38.5997 21.0558 38.3907 21.4525L43.0483 22.7462C43.4945 21.4412 43.5188 20.0254 43.0704 18.6634L42.8289 17.9292C41.9531 15.2683 39.4833 13.4806 36.6817 13.4806H36.3359H28.5135H28.4961C26.075 13.4715 24.1153 11.5066 24.1153 9.08374C24.1153 7.82475 24.6449 6.68958 25.4929 5.88805C25.5906 5.79552 25.5272 5.74469 25.3969 5.74121H25.3682Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <path
                d="M20.2443 7.73458C24.5533 7.40916 28.9657 8.97972 32.0875 11.9678C34.855 14.5408 36.6282 18.17 36.9398 21.9385C37.5011 26.7867 35.5044 31.8196 31.8669 35.0545C29.4883 37.2511 26.39 38.6355 23.1827 39.0368C20.8965 39.2546 18.5358 39.125 16.3641 38.3294C13.6394 37.4676 11.2167 35.7702 9.3524 33.6191C7.01519 30.8723 5.65422 27.3093 5.61423 23.6994C5.39913 18.5782 8.04935 13.5039 12.2343 10.5889C14.5743 8.9287 17.3789 7.91521 20.2443 7.73458ZM19.8968 8.91905C16.6288 9.21137 13.496 10.6826 11.1463 12.9661C9.44617 14.647 8.11002 16.7208 7.39438 19.0098C6.43054 21.9895 6.53671 25.2782 7.55571 28.2304C8.69467 31.508 11.0498 34.332 14.0503 36.068C17.6602 38.1556 22.1857 38.6134 26.1114 37.1794C28.559 36.3534 30.7431 34.8187 32.4171 32.8593C34.5571 30.3635 35.8133 27.1245 35.9084 23.8373C35.9112 21.5745 35.4865 19.2924 34.513 17.2406C33.2031 14.4649 31.0272 12.0891 28.3232 10.6247C25.7722 9.2293 22.7897 8.6088 19.8968 8.91905Z"
                fill="#C7CED6"
              />
              <path
                d="M95.0987 12.7829C96.2584 12.5637 97.447 12.6133 98.608 12.7995C98.608 14.0115 98.6053 15.2222 98.6094 16.4342C97.8924 16.3515 97.1574 16.3598 96.45 16.5087C95.9495 16.6038 95.5096 16.9982 95.3759 17.4918C95.249 17.9165 95.289 18.3661 95.2821 18.8032C96.3894 18.8004 97.498 18.8032 98.6066 18.8018C98.608 20.0304 98.6053 21.2576 98.6066 22.4862C97.498 22.4931 96.3894 22.4876 95.2821 22.4903C95.2835 26.2933 95.2821 30.0963 95.2821 33.8993C93.775 33.8993 92.2679 33.902 90.7607 33.8979C90.7621 30.0935 90.7635 26.2905 90.7607 22.4876C90.0037 22.4889 89.2467 22.4862 88.4897 22.4917C88.4856 21.2617 88.4842 20.0318 88.4883 18.8032C89.2453 18.8018 90.0024 18.8004 90.7607 18.8032C90.7111 17.4822 90.8683 16.0964 91.6019 14.963C92.3603 13.7578 93.7047 12.987 95.0987 12.7829Z"
                fill="#C7CED6"
              />
              <path
                d="M58.0079 12.9426C59.6239 12.9426 61.24 12.9385 62.856 12.9454C62.856 18.5161 62.856 24.0882 62.856 29.6603C65.8606 29.6575 68.8666 29.6562 71.8712 29.6617C71.8698 31.0737 71.8671 32.4856 71.8726 33.8976C67.2519 33.9018 62.6299 33.899 58.0079 33.899C58.0065 26.9135 58.0065 19.9281 58.0079 12.9426Z"
                fill="#C7CED6"
              />
              <path
                d="M111.343 12.9743C112.86 12.9757 114.378 12.9716 115.895 12.9771C115.892 15.397 115.898 17.8184 115.891 20.2397C116.685 19.1297 118.005 18.4678 119.356 18.3851C120.829 18.2886 122.401 18.454 123.674 19.2607C124.738 19.9212 125.296 21.1332 125.575 22.3122C125.878 23.6704 125.867 25.0713 125.862 26.4544C125.862 28.935 125.865 31.417 125.86 33.8976C124.355 33.9004 122.848 33.9004 121.342 33.8976C121.34 31.3246 121.343 28.7516 121.34 26.1786C121.336 25.2382 121.401 24.2247 120.91 23.3822C120.469 22.61 119.511 22.3522 118.678 22.3563C117.797 22.3218 116.791 22.5962 116.313 23.3987C115.789 24.2288 115.865 25.2437 115.862 26.1827C115.863 28.753 115.859 31.3246 115.865 33.8962C114.358 33.9045 112.851 33.8976 111.343 33.899C111.342 26.9246 111.339 19.9488 111.343 12.9743Z"
                fill="#C7CED6"
              />
              <path
                d="M102.051 18.8027C102.046 17.3314 102.055 15.8615 102.047 14.3916C103.554 14.3861 105.061 14.3889 106.57 14.3889C106.571 15.8601 106.568 17.3314 106.57 18.8013C107.717 18.8013 108.866 18.8013 110.014 18.8013C110.014 20.0299 110.014 21.2585 110.013 22.4885C108.864 22.4885 107.717 22.4899 106.57 22.4885C106.571 24.452 106.563 26.4155 106.575 28.3805C106.56 29.0561 106.778 29.8352 107.433 30.1537C108.253 30.4805 109.158 30.3785 110.013 30.2764C110.016 31.494 110.013 32.7102 110.014 33.9277C108.885 34.1925 107.712 34.1939 106.563 34.0767C105.074 33.9429 103.525 33.2231 102.761 31.8842C102.21 30.9631 102.054 29.8655 102.051 28.8065C102.048 26.701 102.05 24.5954 102.051 22.4885C101.293 22.4885 100.534 22.4885 99.7774 22.4871C99.7787 21.2585 99.7746 20.0299 99.7788 18.8013C100.536 18.8027 101.293 18.7986 102.051 18.8027Z"
                fill="#C7CED6"
              />
              <path
                d="M132.868 18.4885C134.637 18.2582 136.46 18.3092 138.192 18.7629C139.329 19.0856 140.428 19.7736 140.992 20.845C141.81 22.2942 141.697 24.0165 141.704 25.6215C141.705 28.3806 141.701 31.1398 141.704 33.899C140.326 33.9003 138.949 33.899 137.571 33.9003C137.573 33.2661 137.57 32.6331 137.571 31.9989C136.618 33.255 135.162 34.1072 133.592 34.2795C132.028 34.4436 130.316 34.3306 129.003 33.3736C128.02 32.731 127.471 31.5976 127.282 30.4683C127.094 29.1528 127.22 27.6884 128.079 26.6157C128.952 25.5277 130.329 25.0382 131.648 24.7431C133.157 24.4811 134.689 24.3832 136.209 24.1943C136.559 24.1185 136.969 24.0137 137.159 23.6773C137.421 23.2691 137.29 22.6996 136.967 22.3673C136.412 21.8861 135.639 21.8392 134.942 21.8075C134.261 21.8158 133.517 21.8406 132.949 22.2611C132.632 22.4914 132.533 22.8858 132.46 23.2484C130.997 23.2498 129.534 23.2553 128.071 23.2457C128.131 22.0433 128.54 20.8105 129.447 19.9805C130.343 19.0538 131.641 18.716 132.868 18.4885ZM134.824 27.5147C133.923 27.6802 132.929 27.6664 132.147 28.2055C131.288 28.7723 131.427 30.2876 132.363 30.7013C133.144 31.0019 134.012 30.9026 134.812 30.7275C135.765 30.5165 136.716 29.9746 137.145 29.0604C137.453 28.4606 137.386 27.7712 137.392 27.1217C136.549 27.3272 135.677 27.3616 134.824 27.5147Z"
                fill="#C7CED6"
              />
              <path
                d="M147.812 20.5182C148.525 19.1876 150.014 18.4774 151.476 18.3795C153.02 18.2913 154.671 18.4554 155.989 19.3351C156.881 19.9239 157.389 20.9263 157.696 21.9219C158.067 23.2608 158.084 24.6659 158.085 26.0461C158.086 28.6633 158.084 31.2804 158.086 33.8976C156.579 33.9017 155.072 33.8989 153.565 33.8989C153.565 31.0474 153.566 28.1958 153.564 25.3457C153.551 24.5335 153.441 23.6414 152.86 23.0222C152.288 22.4597 151.43 22.3383 150.662 22.3604C149.896 22.3728 149.063 22.6334 148.608 23.2884C148.185 23.8854 148.08 24.6369 148.085 25.3512C148.085 28.2 148.081 31.0474 148.087 33.8962C146.578 33.9017 145.07 33.9003 143.562 33.8962C143.565 28.866 143.561 23.8344 143.564 18.8028C144.981 18.8001 146.398 18.8001 147.815 18.8028C147.814 19.3737 147.816 19.9459 147.812 20.5182Z"
                fill="#C7CED6"
              />
              <path
                d="M161.894 19.4826C163.333 18.5395 165.115 18.3326 166.798 18.3644C168.436 18.4485 170.247 18.6346 171.499 19.815C172.478 20.6768 172.916 21.9798 173.013 23.2498C171.55 23.2553 170.087 23.2442 168.625 23.2539C168.49 22.7285 168.188 22.1894 167.63 22.0363C166.767 21.8019 165.801 21.8033 164.971 22.1535C164.352 22.3976 164.18 23.3091 164.683 23.7503C165.338 24.2274 166.187 24.2108 166.955 24.3322C168.191 24.4673 169.415 24.7128 170.59 25.1195C171.724 25.5304 172.759 26.3881 173.109 27.5753C173.478 28.8412 173.48 30.26 172.907 31.4666C172.479 32.4539 171.576 33.1447 170.63 33.6011C169.294 34.1678 167.824 34.3112 166.387 34.3416C164.545 34.3305 162.583 34.1292 161.037 33.033C159.853 32.1629 159.279 30.6847 159.265 29.2466C160.748 29.2438 162.231 29.2493 163.715 29.2438C163.792 29.9512 164.296 30.5703 165 30.7289C166.066 30.9784 167.251 30.9812 168.271 30.5496C169.079 30.2214 169.006 28.9349 168.199 28.6523C167.057 28.2041 165.797 28.2248 164.604 27.9931C163.245 27.7849 161.834 27.3506 160.869 26.3178C160.079 25.4698 159.838 24.2646 159.869 23.1395C159.872 21.682 160.648 20.2479 161.894 19.4826Z"
                fill="#C7CED6"
              />
              <path
                d="M180.16 18.4898C181.769 18.2775 183.422 18.3023 185.012 18.6484C186.264 18.8911 187.501 19.553 188.193 20.6561C188.843 21.6792 188.996 22.9243 189 24.1129C189 27.3754 189.001 30.6365 188.998 33.8989C187.621 33.9003 186.245 33.8989 184.867 33.9003C184.864 33.2715 184.869 32.6441 184.866 32.0167C183.899 33.2577 182.456 34.1127 180.885 34.2809C179.321 34.4408 177.607 34.3305 176.297 33.3694C174.673 32.2649 174.258 30.0435 174.63 28.2234C174.863 27.0155 175.728 25.991 176.83 25.467C178.504 24.5928 180.43 24.5542 182.265 24.3322C182.933 24.2343 183.666 24.2922 184.259 23.913C184.756 23.5076 184.673 22.672 184.181 22.3011C183.63 21.8778 182.904 21.8364 182.239 21.8088C181.558 21.8198 180.811 21.8364 180.244 22.2639C179.931 22.4955 179.838 22.8926 179.756 23.2498C178.297 23.2553 176.839 23.2401 175.383 23.2567C175.436 22.0225 175.881 20.7636 176.812 19.9184C177.701 19.0331 178.964 18.7146 180.16 18.4898ZM182.078 27.5216C181.188 27.6801 180.212 27.6719 179.445 28.2082C178.781 28.6357 178.728 29.6257 179.132 30.2476C179.494 30.824 180.247 30.8929 180.864 30.8902C182.134 30.8474 183.552 30.4641 184.291 29.3431C184.738 28.6881 184.703 27.8732 184.689 27.1175C183.833 27.3326 182.945 27.3644 182.078 27.5216Z"
                fill="#C7CED6"
              />
              <path
                d="M82.7687 18.8014C84.2745 18.8014 85.7816 18.8 87.2874 18.8027C87.2929 23.8343 87.2887 28.8659 87.2901 33.8988C85.8712 33.8988 84.4524 33.903 83.0335 33.8961C83.039 33.31 83.0307 32.7254 83.0418 32.1407C82.504 33.0977 81.5815 33.7982 80.5239 34.0808C78.9961 34.4904 77.3207 34.4145 75.8467 33.8354C74.7767 33.4396 73.9438 32.5434 73.515 31.4982C73.0296 30.2944 72.8918 28.9817 72.8904 27.6924C72.8835 24.7292 72.8931 21.766 72.8862 18.8014C74.3934 18.8 75.9005 18.8014 77.4076 18.8027C77.4145 21.6722 77.4035 24.5417 77.4131 27.4111C77.4173 28.1695 77.5234 28.99 78.0433 29.5829C78.5452 30.1482 79.345 30.3096 80.0703 30.3123C80.859 30.3247 81.7346 30.0876 82.213 29.4133C82.675 28.8107 82.7563 28.0247 82.766 27.2898C82.7701 24.4603 82.7646 21.6308 82.7687 18.8014Z"
                fill="#C7CED6"
              />
              <path
                d="M8.37476 19.4883C9.10695 19.793 9.84327 20.0853 10.5755 20.3887C10.8885 20.1474 11.2042 19.9075 11.5159 19.6634C13.5718 20.6052 15.625 21.5497 17.6823 22.4874C17.4616 22.6487 17.241 22.8114 17.0204 22.9741C16.0055 22.5356 14.9865 22.1027 13.973 21.66C13.8365 21.7855 13.6986 21.9124 13.5621 22.0392C14.9907 22.8748 16.4675 23.7628 18.1442 23.9614C19.5879 23.9807 21.0109 23.3519 22.0092 22.3136C22.7731 21.5732 23.3536 20.6631 23.8087 19.7061C27.3538 19.3931 30.9334 19.5958 34.4371 20.2136C34.1834 20.4563 33.9311 20.6976 33.6788 20.9403C31.3788 20.5169 29.0388 20.3873 26.7043 20.3859C26.5885 20.6038 26.4727 20.823 26.3582 21.0423C28.5989 21.012 30.8396 21.1829 33.0541 21.5208C32.8128 21.751 32.5729 21.9841 32.333 22.2143C30.2233 21.8462 28.0736 21.7883 25.9377 21.809C25.8081 22.0461 25.6757 22.2805 25.5433 22.5163C27.5965 22.4281 29.6579 22.5384 31.6918 22.8335C31.4587 23.0665 31.2216 23.294 30.9803 23.5174C28.9892 23.1851 26.9594 23.2306 24.9504 23.3133C24.7573 23.5436 24.5671 23.7752 24.3768 24.0069C26.3569 23.8938 28.3383 23.9628 30.3129 24.1379C30.0688 24.3861 29.8179 24.6288 29.5683 24.8701C28.57 24.757 27.5689 24.6426 26.5637 24.7157C24.7684 24.7419 22.8931 25.0176 21.357 26.0049C22.6904 27.4307 24.5478 28.2291 26.4217 28.662C27.5068 28.873 28.6155 28.9475 29.72 28.975C29.2787 29.386 28.8389 29.7982 28.399 30.2119C26.8436 29.8948 25.3103 29.4756 23.7976 28.9957C21.4066 28.1863 19.1494 27.0198 17.0342 25.6492C15.3919 24.5336 13.8021 23.3409 12.1143 22.2943C10.905 21.6076 9.67781 20.9513 8.40509 20.3915C8.39544 20.0895 8.38579 19.7889 8.37476 19.4883Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
        </div>
        <div class="swiper-inner-container">
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="114"
              height="35"
              viewBox="0 0 114 35"
              fill="none"
            >
              <g clip-path="url(#clip0_114_167)">
                <path
                  d="M77.8341 21.2528C77.8341 17.2903 74.6183 14.0765 70.6559 14.0783C66.6934 14.0783 63.4796 17.294 63.4814 21.2565C63.4814 25.219 66.6952 28.431 70.6577 28.431C74.6202 28.431 77.8341 25.2172 77.8341 21.2547V21.2528ZM112.302 21.2528C112.296 28.4588 106.448 34.2958 99.2421 34.2902C93.8828 34.2864 89.0694 31.0038 87.1095 26.016L92.077 21.0484V21.2547C92.0863 25.2116 95.302 28.4105 99.2589 28.3994C103.216 28.3882 106.415 25.1744 106.404 21.2175C106.394 17.2606 103.179 14.0616 99.2218 14.0727C97.3288 14.0783 95.5137 14.8307 94.1762 16.1701L79.9351 30.4187C74.8858 35.5478 66.6339 35.6128 61.503 30.5636C59.0081 28.1078 57.6074 24.7527 57.613 21.2528V2.94701C57.7133 1.32524 59.1084 0.09358 60.7283 0.192038C62.2107 0.283065 63.3904 1.46456 63.4832 2.94701V10.2682C69.5022 6.30573 77.5943 7.97395 81.5568 13.9929C82.4653 15.3732 83.1025 16.9132 83.4368 18.5313L90.028 11.942C95.1218 6.84632 103.383 6.84447 108.479 11.9383C110.948 14.4072 112.326 17.7622 112.302 21.2528ZM55.6586 21.2528C55.6717 28.4513 49.8477 34.2994 42.6473 34.3125C37.2674 34.3236 32.4336 31.0261 30.4793 26.016L35.4468 21.0484V21.2547C35.4561 25.2116 38.6718 28.4105 42.6288 28.3994C46.5857 28.39 49.7846 25.1744 49.7734 21.2175C49.7641 17.2606 46.5485 14.0616 42.5916 14.0727C40.6986 14.0783 38.8836 14.8307 37.5461 16.1701L23.2547 30.4744C18.1591 35.5682 9.89788 35.5664 4.80407 30.4707C-0.289756 25.375 -0.287891 17.1138 4.80778 12.02C9.90159 6.92807 18.1609 6.92807 23.2547 12.02C24.401 13.1718 24.3954 15.0369 23.2418 16.1812C22.0899 17.3275 20.2248 17.3219 19.0805 16.1683C16.2735 13.3706 11.7296 13.378 8.93188 16.185C6.13417 18.992 6.14161 23.5359 8.94859 26.3336C11.75 29.1257 16.281 29.1257 19.0805 26.3336L33.396 11.981C38.4805 6.88534 46.7342 6.87605 51.8318 11.9606C54.2989 14.422 55.6772 17.7696 55.6586 21.2565V21.2509V21.2528Z"
                  fill="#C7CED6"
                />
              </g>
              <defs>
                <clipPath id="clip0_114_167">
                  <rect
                    width="112.128"
                    height="34.1259"
                    fill="white"
                    transform="translate(0.984863 0.186523)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <g clip-path="url(#clip0_114_242)">
                <path
                  d="M31.1868 0.844785C31.8593 0.305713 32.5345 -0.236226 33.2585 -0.703613C33.7159 -0.227624 34.1331 0.28134 34.5776 0.767365C35.4335 1.64766 36.1518 2.66129 37.0865 3.46559C37.627 3.03978 38.0399 2.48781 38.5045 1.98458C39.2027 1.1817 39.9167 0.391735 40.6106 -0.415439C41.1095 -0.411138 41.4593 -0.0240388 41.8321 0.252666C42.2923 0.601055 42.7253 0.983854 43.1253 1.39963C42.0213 2.8319 40.7439 4.11936 39.5769 5.49858C38.7511 6.3416 38.0657 7.32368 37.1697 8.09502C37.1267 8.08928 37.0406 8.07925 36.9976 8.07494C36.3783 7.55738 35.8722 6.91795 35.3403 6.31436C33.8851 4.75162 32.5274 3.10143 31.0607 1.54873C30.8686 1.33941 30.9489 0.985288 31.1868 0.844785Z"
                  fill="#C7CED6"
                />
                <path
                  d="M47.5324 0.97824C47.6629 0.824834 47.8593 0.76892 48.0385 0.695801C48.0543 4.86931 48.0686 9.04568 48.0313 13.2192C46.989 13.1747 45.9195 13.3583 44.8987 13.0945C44.8872 11.7253 44.9102 10.3575 44.8886 8.98833C44.1331 9.1317 43.8263 9.89873 43.3159 10.379C42.4485 11.2392 41.7431 12.2543 40.8585 13.0916C39.5008 13.1518 38.1316 13.1418 36.7725 13.0859C37.9194 11.5977 39.2642 10.2715 40.4857 8.84353C42.8385 6.22416 45.1854 3.60192 47.5324 0.97824Z"
                  fill="#C7CED6"
                />
                <path
                  d="M26.1789 1.39803C26.3523 1.24319 26.5789 1.19444 26.7997 1.1543C28.5932 3.20592 30.4499 5.2002 32.2305 7.2633C32.7252 7.86832 33.3976 8.34145 33.7044 9.0841C29.7646 9.14145 25.8233 9.09414 21.8821 9.10991C21.1925 9.10561 20.5028 9.12568 19.8132 9.08554C20.186 8.37872 20.6964 7.75793 21.0706 7.05255C21.3 6.67118 21.5294 6.2038 22.0097 6.1063C22.9229 6.07906 23.8434 6.04609 24.7466 6.20236C25.4118 6.19949 26.1 6.30129 26.7437 6.09197C26.695 5.92422 26.7022 5.72637 26.5875 5.58444C25.8133 4.73855 24.9975 3.91991 24.3237 2.98944C24.8756 2.38585 25.5351 1.89695 26.1789 1.39803Z"
                  fill="#C7CED6"
                />
                <path
                  d="M51.3361 4.00469C51.3289 3.62046 51.3217 3.22476 51.4981 2.87207C52.0759 3.50577 52.6795 4.11366 53.2286 4.77316C53.6357 5.26348 54.1748 5.65345 54.473 6.22837C54.5763 7.81547 54.4286 9.41405 54.5447 11.0026C55.7705 10.9711 56.6867 9.837 57.934 9.92732C58.4946 10.7173 59.3032 11.3123 59.7476 12.184C57.5497 13.2205 55.3003 14.1453 53.0823 15.1374C52.5347 15.4026 51.9813 15.6879 51.3676 15.7539C51.3002 11.8384 51.3633 7.92157 51.3361 4.00469Z"
                  fill="#C7CED6"
                />
                <path
                  d="M19.8391 11.972C24.2019 11.9448 28.5646 11.9749 32.9259 11.9562C33.184 11.9304 33.4206 12.0322 33.6586 12.1154C33.2098 12.8236 32.5503 13.3555 32.0342 14.0107C30.503 15.7297 28.9618 17.4387 27.4449 19.1706C27.0922 19.5391 26.7539 20.2029 26.1417 19.9735C25.5524 19.5577 24.999 19.0918 24.4384 18.6387C24.2019 18.4953 24.1273 18.1355 24.3237 17.9305C25.1266 17.0688 25.875 16.1555 26.7281 15.3398C26.6707 15.2451 26.5574 15.0545 26.5001 14.9598C24.8943 14.9899 23.2872 14.967 21.6814 14.9727C21.2814 14.4867 20.9689 13.9405 20.6578 13.3971C20.3897 12.9182 19.9638 12.5225 19.8391 11.972Z"
                  fill="#C7CED6"
                />
                <path
                  d="M111.028 15.77C112.395 15.3887 113.821 15.5822 115.219 15.5349C116.638 15.4546 118.02 15.8948 119.344 16.3636C120.978 16.9729 122.516 17.8819 123.77 19.1034C124.076 19.4016 124.486 19.6697 124.518 20.1385C123.133 21.307 121.676 22.3909 120.29 23.5594C120.048 23.7443 119.686 24.0583 119.41 23.7529C118.243 22.6719 116.803 21.8604 115.226 21.5866C114.645 21.5565 114.063 21.5536 113.486 21.5937C111.877 21.9006 110.307 22.7464 109.412 24.1572C108.862 24.9414 108.667 25.8934 108.45 26.8096C108.347 27.5651 108.383 28.345 108.633 29.0705C108.994 30.6461 109.98 32.1329 111.454 32.8612C112.681 33.4691 114.111 33.8046 115.468 33.4877C116.509 33.2755 117.514 32.8684 118.397 32.2748C118.846 31.9795 119.189 31.5379 119.665 31.2856C119.95 31.2942 120.159 31.5135 120.375 31.6741C121.724 32.8139 123.123 33.8949 124.488 35.0175C124.465 35.1637 124.462 35.3114 124.478 35.4591C122.548 37.3387 120.165 38.8598 117.488 39.3674C117.008 39.4706 116.534 39.6254 116.04 39.6269C114.835 39.6498 113.629 39.6555 112.425 39.6139C109.845 39.2139 107.246 38.3595 105.25 36.6161C104.559 35.9709 103.875 35.3042 103.336 34.5243C102.182 32.887 101.563 30.9114 101.388 28.9286C101.374 28.0769 101.375 27.2253 101.385 26.3751C101.574 24.249 102.281 22.15 103.523 20.4023C104.007 19.707 104.644 19.1407 105.248 18.5514C106.849 17.0776 108.916 16.2073 111.028 15.77Z"
                  fill="#C7CED6"
                />
                <path
                  d="M75.7291 15.8241C76.8546 15.9245 77.97 15.5331 79.0883 15.7739C79.9471 15.86 80.8245 15.8571 81.6761 15.7023C82.1879 15.6005 82.6267 15.9947 82.8675 16.4076C85.1342 20.0191 87.4138 23.622 89.6833 27.2321C89.9987 27.7124 90.1923 28.3375 90.7744 28.5669C90.8088 24.379 90.7859 20.1883 90.7844 15.999C91.6203 15.3453 92.6454 16.0076 93.5687 15.751C94.231 15.6449 94.9063 15.718 95.5744 15.698C96.2597 15.7266 96.9895 15.5804 97.6275 15.9073C97.6963 17.5761 97.6346 19.2492 97.6562 20.9195C97.6633 27.0142 97.6404 33.1088 97.6676 39.2035C97.4067 39.2766 97.1429 39.3483 96.8719 39.3383C94.8088 39.3368 92.7457 39.3311 90.6812 39.3397C90.003 38.5798 89.5328 37.6709 88.9837 36.8207C87.2016 34.0178 85.4123 31.2221 83.6216 28.4249C83.2589 27.8443 82.9048 27.2565 82.489 26.7131C82.3686 28.7446 82.4661 30.7848 82.4345 32.8192C82.436 34.6859 82.4302 36.5526 82.4388 38.4193C82.4532 38.7347 82.3729 39.0415 82.2912 39.3426C80.175 39.3125 78.0574 39.3698 75.9427 39.3168C75.3248 39.1117 75.7076 38.4365 75.6961 37.9834C75.7119 31.149 75.6933 24.3131 75.7047 17.4786C75.7277 16.9209 75.504 16.3646 75.7291 15.8241Z"
                  fill="#C7CED6"
                />
                <path
                  d="M127.78 16.0175C128.498 15.4612 129.446 15.7451 130.268 15.8168C130.792 15.7494 131.324 15.6189 131.848 15.7752C132.799 15.8641 133.761 15.8111 134.716 15.8082C135.616 15.6433 136.534 15.6978 137.444 15.705C138.492 15.8784 139.56 15.8082 140.61 15.9946C142.062 16.2956 143.575 16.7845 144.621 17.8928C145.266 18.5294 145.717 19.3638 145.867 20.2584C145.979 20.8763 146.227 21.51 146.01 22.1351C145.846 23.2692 145.437 24.4032 144.617 25.2305C144.031 25.857 143.246 26.2326 142.511 26.6498C143.232 27.1703 144.138 27.3237 144.868 27.8298C146.066 28.5366 146.869 29.8054 147.153 31.1517C147.328 31.7897 147.258 32.4592 147.272 33.1115C147.295 33.9775 147.06 34.8334 146.71 35.6176C146.31 36.5453 145.563 37.2607 144.8 37.8972C143.285 38.9324 141.414 39.3998 139.589 39.3367C135.817 39.3338 132.045 39.3381 128.273 39.3352C127.97 39.3983 127.762 39.1001 127.785 38.8263C127.778 31.2234 127.791 23.6204 127.78 16.0175ZM134.882 21.2849C134.69 21.2993 134.377 21.3709 134.367 21.6046C134.324 22.5251 134.367 23.4484 134.349 24.3703C134.376 24.5738 134.29 24.8319 134.455 24.9925C135.434 25.0355 136.42 25.0398 137.4 24.9925C138.069 24.8247 138.822 24.5079 139.089 23.814C139.49 23.0957 139.033 22.181 138.37 21.8011C137.309 21.2018 136.059 21.2505 134.882 21.2849ZM134.35 29.8828C134.376 31.1531 134.31 32.4277 134.38 33.6965C134.433 33.7395 134.541 33.827 134.594 33.87C135.633 33.9302 136.679 33.8857 137.721 33.8929C138.532 33.794 139.413 33.5675 139.952 32.9008C140.398 32.528 140.22 31.8943 140.244 31.3868C140.279 30.9036 139.83 30.5983 139.519 30.3015C138.542 29.5875 137.285 29.7352 136.148 29.7323C135.547 29.7581 134.918 29.6334 134.35 29.8828Z"
                  fill="#C7CED6"
                />
                <path
                  d="M157.44 15.8339C158.328 15.8669 159.208 15.5457 160.091 15.7708C160.956 15.9572 161.81 15.6231 162.679 15.6948C163.086 15.6891 163.479 15.8138 163.883 15.8568C165.022 18.5737 166.024 21.3479 167.1 24.0892C168.659 28.1236 170.216 32.1566 171.77 36.191C172.123 37.0326 172.358 37.9272 172.844 38.7086C172.974 38.8921 172.961 39.123 173 39.3366C170.64 39.3308 168.282 39.3452 165.922 39.328C165.452 38.1179 165.092 36.8677 164.705 35.6276C162.173 35.576 159.639 35.6176 157.108 35.6061C156.751 35.5502 156.56 35.9172 156.471 36.2025C156.125 37.2477 155.824 38.3086 155.409 39.3308C153.057 39.3308 150.706 39.3552 148.356 39.3194C149.945 35.1258 151.598 30.9566 153.188 26.763C154.629 23.1286 155.971 19.4554 157.44 15.8339ZM160.378 24.1293C160.24 24.8748 159.979 25.5859 159.746 26.3042C159.379 27.3365 159.082 28.3931 158.692 29.4182C158.593 29.6476 158.58 29.8985 158.55 30.1437C159.232 30.3716 159.955 30.2813 160.662 30.3C161.367 30.2684 162.095 30.3903 162.781 30.1595C162.761 30.006 162.739 29.8526 162.715 29.7007C162.143 27.8397 161.397 26.0261 160.953 24.1279C160.759 24.1365 160.567 24.1365 160.378 24.1293Z"
                  fill="#C7CED6"
                />
                <path
                  d="M54.3223 15.9604C59.1439 15.9704 63.9668 15.9245 68.787 16.0034C68.397 16.5941 67.769 16.9453 67.2271 17.374C64.3023 19.6579 61.3761 21.9403 58.4543 24.2271C57.0894 25.2594 55.7861 26.3705 54.394 27.3669C54.3008 27.0916 54.1976 26.8121 54.2191 26.5167C54.2248 25.6981 54.2205 24.8809 54.2205 24.0622C54.1747 23.6235 54.5747 23.3368 54.87 23.0844C55.7675 22.4063 56.6507 21.7109 57.5324 21.0127C58.3496 20.3575 59.2514 19.797 59.9381 18.9941C59.6872 18.9066 59.4292 18.8278 59.1611 18.8464C57.551 18.8536 55.9424 18.8478 54.3338 18.8493C54.1503 17.9059 54.1675 16.9066 54.3223 15.9604Z"
                  fill="#C7CED6"
                />
                <path
                  d="M33.6615 16.501C33.888 16.2687 34.1117 15.9175 34.483 15.9748C38.4071 15.9705 42.3311 15.9762 46.2552 15.9719C46.7756 15.9805 47.3175 15.9074 47.8093 16.1253C46.7785 17.0888 45.6028 17.8802 44.5247 18.7877C42.7311 20.1928 40.9361 21.5964 39.1612 23.0229C38.8874 23.2293 38.6178 23.5333 38.2393 23.4802C37.8293 23.5304 37.6601 23.1003 37.4121 22.8623C37.0321 22.3706 36.3726 22.0107 36.3683 21.3168C37.0823 20.6931 37.8637 20.1526 38.5949 19.549C38.7741 19.4057 38.8558 19.1863 38.9662 18.9942C36.5777 18.9784 34.1891 18.9913 31.802 18.9899C31.8221 18.8738 31.8608 18.6429 31.8809 18.5268C32.4357 17.8185 33.0981 17.2035 33.6615 16.501Z"
                  fill="#C7CED6"
                />
                <path
                  d="M49.0852 18.8708C49.7217 18.3905 50.2795 17.7668 51.0436 17.4844C51.0766 21.3898 51.0465 25.2981 51.058 29.2035C50.3153 29.2393 49.7519 28.6859 49.1139 28.3949C48.7053 28.1296 48.1289 28.002 47.9325 27.5103C47.8823 26.3102 47.9569 25.1088 47.8966 23.9088C46.7669 24.3733 46.0113 25.417 44.9604 26.0135C44.7482 26.0206 44.569 25.8887 44.3855 25.8056C43.6687 25.4156 42.9532 25.0242 42.2393 24.6299C42.2851 24.3045 42.4787 24.045 42.7425 23.86C44.8629 22.2041 46.9733 20.5367 49.0852 18.8708Z"
                  fill="#C7CED6"
                />
                <path
                  d="M38.3596 25.9492C38.9589 26.0983 39.4822 26.4352 40.0141 26.7349C41.6572 27.6209 43.2959 28.5155 44.9389 29.403C45.8522 29.9191 46.8314 30.332 47.6687 30.9743C46.8873 31.5622 45.9726 31.9263 45.1339 32.4181C43.4292 33.34 41.7217 34.259 40.017 35.1808C39.4593 35.4719 38.936 35.8747 38.2966 35.9593C37.7776 35.1536 37.2729 34.3392 36.8098 33.5005C38.2005 32.5443 39.7819 31.9034 41.1855 30.9686C40.4959 30.3722 39.6399 30.0439 38.86 29.5937C38.2363 29.2396 37.5955 28.9184 36.9604 28.5844C36.9948 28.3793 36.9847 28.1557 37.098 27.9722C37.4951 27.284 37.9396 26.6245 38.3596 25.9492Z"
                  fill="#C7CED6"
                />
                <path
                  d="M58.354 28.4835C58.7497 28.3115 58.6207 28.8505 58.6508 29.0785C58.6049 29.9903 58.7325 30.9237 58.5877 31.8226C57.2185 32.6929 55.7289 33.3638 54.3339 34.1954C54.3253 36.4176 54.3325 38.6399 54.3311 40.8621C54.3913 41.2578 53.9913 41.4628 53.7074 41.6334C52.8443 42.0836 52.0156 42.6083 51.1081 42.9668C51.0364 42.6499 50.926 42.3359 50.9404 42.0062C50.9447 38.8721 50.906 35.7366 50.949 32.604C51.4751 32.128 52.1533 31.867 52.7626 31.5172C54.6207 30.4964 56.4931 29.4986 58.354 28.4835Z"
                  fill="#C7CED6"
                />
                <path
                  d="M46.1402 35.149C46.7567 34.8278 47.3331 34.3719 48.0399 34.2744C48.0628 37.7425 48.0499 41.2107 48.0456 44.6788C47.0234 45.291 45.9381 45.7928 44.9345 46.4394C44.8513 44.1598 44.9115 41.8745 44.9029 39.592C44.6076 39.625 44.2807 39.5777 44.0226 39.7569C43.4104 40.101 42.8083 40.4651 42.1875 40.7978C41.9294 40.9612 41.6155 40.9254 41.3244 40.9512C40.8628 40.1841 40.3767 39.4329 39.9395 38.6515C40.3681 38.2558 40.8843 37.982 41.3975 37.7153C42.9861 36.8751 44.556 35.9992 46.1402 35.149Z"
                  fill="#C7CED6"
                />
              </g>
              <defs>
                <clipPath id="clip0_114_242">
                  <rect width="193.324" height="46" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <path
                d="M113.62 8H116.206C115.498 8.77806 114.324 8.78217 113.62 8Z"
                fill="#C7CED6"
              />
              <path
                d="M117.766 8H118.519C118.268 8.64632 118.017 8.64632 117.766 8Z"
                fill="#C7CED6"
              />
              <path
                d="M147.855 8H148.612C148.361 8.64632 148.11 8.64632 147.855 8Z"
                fill="#C7CED6"
              />
              <path
                d="M3.44079 12.9975C3.87304 10.1858 6.66828 7.99981 9.5088 8.32091C10.9208 8.39501 12.1106 9.2307 13.2015 10.054C9.6076 11.6143 6.58183 14.2819 4.66345 17.7029C3.84011 16.2908 3.0744 14.6771 3.44079 12.9975Z"
                fill="#C7CED6"
              />
              <path
                d="M30.6524 8.69911C32.8836 7.77697 35.72 8.48093 37.1485 10.4528C38.6552 12.4618 38.7046 15.5658 37.0168 17.4841C36.0741 16.245 35.3001 14.87 34.1763 13.7832C32.6325 12.2724 30.7141 11.2515 28.8657 10.1688C29.0016 9.33309 29.9896 9.0408 30.6524 8.69911Z"
                fill="#C7CED6"
              />
              <path
                d="M14.3215 10.7454C19.2945 9.20576 24.9179 9.23869 29.541 11.8363C34.0817 14.0429 37.5274 18.8882 37.1692 24.0547C37.0663 27.8626 33.4107 31.0572 29.6562 30.8966C25.9965 30.8637 22.7031 27.6856 22.5261 24.0341C22.2174 20.5884 24.6997 17.2004 28.0425 16.3812C29.3351 16.163 30.6525 16.2906 31.9534 16.3441C28.9111 13.0302 24.6833 10.7866 20.1878 10.342C18.22 10.1361 16.2687 10.5149 14.3215 10.7454Z"
                fill="#C7CED6"
              />
              <path
                d="M112.583 9.86061C113.958 9.29662 115.308 10.2146 116.482 10.8445C116.457 10.4946 116.412 9.79474 116.387 9.44482C117.325 9.81533 117.046 10.7539 117.008 11.532C115.086 11.5732 113.159 11.6102 111.241 11.4908C111.657 10.9268 112.019 10.2929 112.583 9.86061ZM113.048 10.9104C113.493 11.2109 113.937 11.2109 114.382 10.9104C114.436 10.3052 112.949 10.2887 113.048 10.9104Z"
                fill="#C7CED6"
              />
              <path
                d="M145.986 9.3457C146.579 9.83971 147.212 10.8689 146.29 11.4123C145.027 11.8198 143.672 11.5523 142.392 11.3876C142.626 10.8483 142.898 10.3214 143.207 9.81501C143.376 10.1567 143.713 10.8318 143.882 11.1694C144.537 11.1159 145.195 11.0624 145.854 11.0047C145.887 10.5889 145.953 9.76149 145.986 9.3457Z"
                fill="#C7CED6"
              />
              <path
                d="M149.728 11.0505C150.288 10.643 150.848 10.2313 151.412 9.82373C151.778 10.0748 152.149 10.326 152.519 10.573C153.655 9.55615 154.812 10.4083 155.866 11.0382C155.146 11.4169 154.363 11.4786 153.573 11.3551C152.684 11.5363 151.79 11.4498 150.942 11.1534C150.889 11.384 150.79 11.8491 150.741 12.0797C150.399 11.738 150.061 11.3963 149.728 11.0505Z"
                fill="#C7CED6"
              />
              <path
                d="M57.316 11.5112C57.7894 11.4453 58.2711 11.4288 58.7486 11.4618C59.5514 12.3922 59.0532 13.7959 58.1188 14.4258C56.3609 14.8292 54.8995 13.2978 53.1458 13.5201C53.3969 13.7754 53.9033 14.2941 54.1544 14.5534C54.1585 15.4385 54.1544 16.3277 54.1461 17.2169C55.2782 16.6282 56.4803 16.8711 57.633 17.2293C57.5712 16.5294 57.6947 15.7678 57.3242 15.1339L57.0896 15.422L57.0443 15.7061C57.3036 16.1877 56.715 16.5912 56.2745 16.3936C56.1551 15.8584 56.1469 15.315 56.2457 14.7675C57.1472 14.7263 58.0488 14.714 58.9503 14.7263C58.9544 15.8872 58.9544 17.0523 58.9503 18.2173C56.9002 17.9867 54.8419 17.9415 52.7917 18.2379C52.7835 17.1099 52.7835 15.9778 52.7917 14.8498C52.6024 14.7057 52.2236 14.4176 52.0302 14.2735C52.0137 14.0471 51.9766 13.586 51.9561 13.3596C52.7753 12.952 53.5698 12.4745 54.4467 12.2069C55.8546 12.4827 57.0813 13.5284 58.5963 13.2237C57.8512 12.8409 57.4271 12.2687 57.316 11.5112Z"
                fill="#C7CED6"
              />
              <path
                d="M157.084 11.6715C157.545 11.6263 158.011 11.6016 158.476 11.6016C159.118 11.9885 159.225 12.7831 159.509 13.417C160.094 13.4458 160.687 13.4747 161.279 13.4994C161.798 12.9765 162.152 11.9885 163.062 12.1861C163.535 12.0956 164.017 12.5978 164.025 13.0506C164.124 14.792 164.132 16.5416 164.116 18.2871C163.07 18.2171 162.037 18.0236 161.098 17.5502C160.053 18.1224 158.846 18.4065 157.722 17.8754C157.418 17.7972 156.813 17.649 156.508 17.5749C156.648 16.8998 156.471 15.9364 157.344 15.7224C158.023 15.5906 158.719 15.6071 159.41 15.5824C159.447 16.1752 159.484 16.7721 159.521 17.3649C160.044 16.9615 160.571 16.5581 161.098 16.1587C161.654 16.5828 162.21 17.0109 162.774 17.4349C162.654 16.5787 162.922 15.5906 162.416 14.8249C161.123 14.5244 159.777 14.5903 158.476 14.3515C157.817 13.5693 157.529 12.569 157.084 11.6715ZM162.535 12.9889C162.313 13.6599 162.535 13.8946 163.202 13.6846C163.42 13.0136 163.198 12.7831 162.535 12.9889ZM157.653 16.3275C157.084 17.5749 159.032 16.3234 157.653 16.3275V16.3275Z"
                fill="#C7CED6"
              />
              <path
                d="M47.6792 13.7099C48.659 12.8742 49.717 11.8245 51.0961 12.5078C51.0137 12.759 50.8491 13.2653 50.7667 13.5164C50.3262 13.4794 49.4494 13.4012 49.0089 13.3641C49.4782 13.5946 49.9516 13.8252 50.4209 14.0557C50.4003 14.6856 50.3839 15.3154 50.3674 15.9453C50.7997 15.9453 51.236 15.9494 51.6724 15.9576C51.6806 16.571 51.6806 17.1844 51.6806 17.7978C50.7914 18.0818 49.8611 18.1765 48.9348 18.1889C48.9183 16.9909 48.9142 15.793 48.9224 14.5909C48.5025 14.3027 48.0909 14.0104 47.6792 13.7099Z"
                fill="#C7CED6"
              />
              <path
                d="M71.066 14.3071C70.5761 13.315 71.3748 12.0511 72.5068 12.2158C73.4537 12.0964 73.6513 13.138 73.96 13.7802C73.3755 13.5661 72.8033 13.3314 72.231 13.0886C72.1899 13.2985 72.1075 13.7184 72.0664 13.9284C73.252 14.1507 74.487 14.1465 75.512 13.422C75.1292 13.3026 74.3676 13.0639 73.9889 12.9486C74.1412 12.6522 74.4417 12.0635 74.594 11.7671C75.0674 11.9729 75.5326 12.2035 75.9855 12.4587C76.0019 13.1956 76.1995 14.2165 75.4215 14.6488C74.1206 15.4886 72.2022 15.4063 71.066 14.3071Z"
                fill="#C7CED6"
              />
              <path
                d="M78.7969 12.1782C79.6079 12.3182 80.2871 12.8287 81.0034 13.195C81.0075 14.8582 81.0034 16.5255 81.0075 18.1886C80.53 18.1845 80.0525 18.1845 79.5749 18.1845C79.6079 16.5749 79.6614 14.9405 79.2085 13.3762C78.2576 14.216 77.3519 15.1093 76.4915 16.0438C76.4956 16.8095 76.4874 17.5752 76.4751 18.3409C75.4953 18.1968 74.5361 17.954 73.6222 17.5711C72.6671 18.0116 71.6379 18.2174 70.5923 18.2833C70.5758 17.3488 70.5676 16.4102 70.5635 15.4757C70.9175 15.4716 71.6215 15.4633 71.9755 15.4633C71.9796 16.1056 71.9837 16.7519 71.9879 17.3982C72.5313 16.9824 73.0788 16.5666 73.6304 16.1508C74.1491 16.5707 74.6678 16.9865 75.1865 17.4064C75.1948 16.8424 75.1989 16.2743 75.2071 15.7104C76.3845 14.5165 77.4795 13.2239 78.7969 12.1782Z"
                fill="#C7CED6"
              />
              <path
                d="M95.0786 13.7549C96.0707 12.8904 97.1452 11.8283 98.5572 12.5035C98.442 12.7711 98.2197 13.3062 98.1085 13.5738C97.598 13.475 97.0958 13.3721 96.5894 13.2609C97.1822 13.7097 97.6968 14.2407 98.0344 14.9076C99.3559 14.9241 100.681 14.92 102.003 14.8665C101.492 14.4877 100.974 14.1213 100.459 13.7467C101.151 13.191 101.842 12.6434 102.538 12.0918C103.102 12.38 103.666 12.6723 104.226 12.9728C103.493 13.2568 102.727 13.4462 101.941 13.3886C102.336 13.7549 103.246 13.8496 103.127 14.5536C103.036 15.6816 103.547 17.1924 102.559 18.0116C100.574 18.2545 98.4378 18.448 96.5071 17.8305C96.3342 16.7519 96.3877 15.6569 96.3754 14.57C95.939 14.2983 95.5067 14.0266 95.0786 13.7549ZM97.7668 16.0109C97.7751 16.2497 97.7998 16.7272 97.8121 16.966C99.0101 17.1389 100.224 17.1842 101.435 17.2089C101.542 16.8137 101.649 16.4226 101.76 16.0274C100.426 16.015 99.0965 16.015 97.7668 16.0109Z"
                fill="#C7CED6"
              />
              <path
                d="M104.279 13.7713C104.95 13.2156 105.63 12.6639 106.305 12.1123C106.877 12.4005 107.441 12.6928 108.009 12.9892C107.26 13.195 106.502 13.3802 105.741 13.5202C107.087 13.6478 106.832 14.9158 106.869 15.8956C107.276 15.9285 107.684 15.9532 108.095 16.0191C108.26 16.5419 108.408 17.0688 108.532 17.604C107.61 18.0156 106.618 18.2009 105.613 18.238C105.564 17.0235 105.56 15.8091 105.555 14.5906C105.127 14.3189 104.703 14.0472 104.279 13.7713Z"
                fill="#C7CED6"
              />
              <path
                d="M110.504 12.2729C111.023 12.3059 111.525 12.4417 112.035 12.5364C111.933 12.7957 111.723 13.3103 111.62 13.5656C111.229 13.4791 110.451 13.3021 110.059 13.2156C110.656 13.6973 111.175 14.2572 111.574 14.92C112.884 14.9241 114.189 14.9076 115.498 14.8664C115.102 14.5906 114.32 14.0308 113.929 13.7549C114.608 13.2033 115.296 12.6558 115.992 12.1206C116.342 12.2729 117.041 12.5776 117.391 12.7299C117.28 12.9234 117.054 13.3144 116.938 13.512C116.523 13.475 115.695 13.405 115.279 13.368C115.609 13.5162 116.267 13.8167 116.597 13.9649C116.593 15.134 116.753 16.332 116.469 17.4764C116.309 18.1804 115.469 18.0816 114.921 18.168C113.373 18.1845 111.78 18.3533 110.265 17.9746C109.438 17.0812 109.957 15.6733 109.808 14.5618C109.388 14.2942 108.973 14.0266 108.557 13.7591C109.187 13.2403 109.796 12.6846 110.504 12.2729ZM111.192 16.0232C111.29 16.3979 111.393 16.7684 111.496 17.143C112.657 17.1677 113.818 17.1718 114.979 17.1636C115.061 16.7848 115.148 16.4061 115.23 16.0274C113.884 16.015 112.538 16.0109 111.192 16.0232Z"
                fill="#C7CED6"
              />
              <path
                d="M118.647 13.7463C119.326 13.1947 120.009 12.6471 120.689 12.0996C121.269 12.3837 121.841 12.6759 122.418 12.9765C121.66 13.1905 120.894 13.3593 120.125 13.5034C120.565 13.7504 121.006 14.0056 121.446 14.2609C122.208 14.3926 122.99 14.4708 123.718 14.7549C123.982 15.5618 124.101 16.4057 124.266 17.2414C124.916 17.1508 125.563 17.0561 126.213 16.9573C126.213 16.1669 126.213 15.3765 126.209 14.5902C125.797 14.2897 125.398 13.9892 124.995 13.6845C125.991 12.82 127.098 11.8444 128.49 12.5154C128.374 12.7706 128.144 13.277 128.029 13.5281C127.588 13.4787 126.711 13.3799 126.271 13.3264C126.617 13.4869 127.304 13.7998 127.65 13.9604C127.65 15.1295 127.745 16.311 127.551 17.4719C127.428 18.0153 126.855 18.0812 126.398 18.147C125.126 18.3117 123.821 18.1923 122.607 17.7642C122.582 17.0026 122.553 16.2369 122.512 15.4753C121.631 15.5124 120.754 15.5782 119.882 15.6688C120.631 15.8664 122.027 15.8787 121.677 17.052C121.512 18.4764 119.791 18.11 118.787 18.1759C118.77 17.089 118.778 16.0022 118.807 14.9113C119.124 14.796 119.445 14.6767 119.762 14.5573C119.487 14.3556 118.927 13.948 118.647 13.7463ZM120.244 16.805C119.598 18.0112 121.623 16.8832 120.244 16.805V16.805Z"
                fill="#C7CED6"
              />
              <path
                d="M137.695 12.2808C138.242 12.2972 138.778 12.4372 139.321 12.5236C139.181 12.7789 138.897 13.2852 138.757 13.5405C138.329 13.4828 137.473 13.3717 137.044 13.3141C137.51 13.5487 137.979 13.7792 138.448 14.0098C138.44 15.253 138.436 16.4921 138.44 17.7354C136.896 18.2788 135.101 18.4805 133.57 17.8095C133.245 16.4098 133.364 14.9607 133.282 13.5405C132.549 13.5322 131.816 13.5364 131.083 13.5446C131.055 14.3473 131.03 15.1542 131.009 15.957C131.359 15.9405 132.063 15.9035 132.417 15.887C132.31 16.5992 132.668 17.5954 131.857 17.9865C131.145 18.2459 130.371 18.1265 129.63 18.1471C129.647 16.5457 129.507 14.936 129.704 13.3388C129.955 12.2725 131.281 12.326 132.15 12.2972C132.989 12.3343 134.105 12.2396 134.628 13.0465C134.937 14.3762 134.751 15.7676 134.834 17.122C135.529 17.1261 136.229 17.1508 136.925 17.0602C137.032 16.2287 137.032 15.3889 137.061 14.5532C136.76 14.3515 136.159 13.9521 135.859 13.7504C136.456 13.2441 137.028 12.6965 137.695 12.2808Z"
                fill="#C7CED6"
              />
              <path
                d="M144.582 12.1079C145.183 12.3714 145.788 12.6431 146.385 12.923C145.615 13.2894 144.784 13.4623 143.944 13.5446C144.405 13.6846 144.87 13.8328 145.331 13.981C145.191 15.0061 145.026 16.0311 144.924 17.0644C145.191 17.1838 145.73 17.4308 146.002 17.5543C145.755 17.3485 145.261 16.9327 145.014 16.7268C145.45 16.3358 145.899 15.957 146.356 15.5783C146.578 16.3563 147.077 17.3567 146.22 17.9454C145.475 18.2459 144.652 18.143 143.87 18.1924C143.824 16.9944 143.82 15.7924 143.82 14.5944C143.458 14.3145 143.1 14.0345 142.742 13.7505C143.351 13.1988 143.964 12.6513 144.582 12.1079Z"
                fill="#C7CED6"
              />
              <path
                d="M146.982 13.7464C147.645 13.1948 148.324 12.6514 149.008 12.1162C149.469 12.322 149.934 12.5279 150.399 12.7296C150.263 12.9313 149.991 13.3306 149.86 13.5282C149.366 13.4583 148.876 13.3842 148.39 13.3101C150.136 13.8288 149.448 15.7801 149.687 17.1345C150.93 17.1674 152.173 17.1756 153.421 17.1633C153.482 16.2988 153.528 15.4343 153.573 14.5698C153.256 14.3639 152.626 13.9523 152.313 13.7464C153.256 12.919 154.269 11.8569 155.631 12.4744C155.553 12.7296 155.393 13.2483 155.314 13.5035C154.894 13.4747 154.051 13.413 153.631 13.38C154.092 13.59 154.557 13.7999 155.022 14.0099C155.014 15.249 155.014 16.4923 155.002 17.7314C152.799 18.3859 150.313 18.4847 148.131 17.7067C148.143 16.6569 148.151 15.6113 148.168 14.5656C147.871 14.3598 147.279 13.9523 146.982 13.7464Z"
                fill="#C7CED6"
              />
              <path
                d="M168.953 13.771C169.603 13.2193 170.258 12.6759 170.912 12.1284C171.439 12.3301 171.966 12.5319 172.497 12.7377C172.283 13.0012 172.069 13.2646 171.859 13.5281C171.452 13.4746 170.633 13.3634 170.221 13.3099C170.665 13.524 171.106 13.738 171.555 13.9521C171.571 14.195 171.612 14.6849 171.633 14.9278C172.991 14.9154 174.354 14.9154 175.713 14.8784C175.264 14.4626 174.827 14.055 174.383 13.6434C175.021 13.1411 175.655 12.6348 176.293 12.1325C176.808 12.3343 177.326 12.536 177.845 12.7418C177.684 12.9353 177.367 13.3223 177.207 13.5157C176.803 13.4705 175.997 13.3799 175.593 13.3346C175.927 13.4828 176.585 13.7792 176.915 13.9274C176.919 15.2571 177.087 16.6239 176.709 17.9206C175.079 18.3323 173.378 18.2129 171.715 18.1635C171.159 18.0647 170.246 18.1882 170.229 17.3896C170.097 16.4468 170.151 15.4918 170.138 14.5408C169.739 14.2856 169.348 14.0262 168.953 13.771ZM171.254 16.1175C171.695 16.6321 172.11 17.336 172.913 17.1837C174.021 17.2414 175.358 17.3854 175.844 16.101C174.317 15.9775 172.781 15.9734 171.254 16.1175Z"
                fill="#C7CED6"
              />
              <path
                d="M180.871 14.1708C181.48 13.2116 182.447 12.3594 183.608 12.2153C184.263 12.3676 184.876 12.6641 185.51 12.9069C186.663 11.8778 188.021 12.4418 189.265 12.9646C188.989 13.3762 188.713 13.7879 188.441 14.1996C187.82 13.9485 187.202 13.6891 186.585 13.4339C185.21 14.5495 183.666 13.3474 182.147 13.6356C184.049 14.8336 186.329 14.2325 188.417 14.7142C188.478 15.8668 188.499 17.0236 188.511 18.1804C188.025 18.1804 187.544 18.1845 187.062 18.1887C187.058 17.3324 187.05 16.4761 187.042 15.6198C185.749 15.4963 184.444 15.4922 183.155 15.6363C183.143 16.4885 183.139 17.3365 183.135 18.1845C182.649 18.1804 182.167 18.1763 181.686 18.1722C181.706 16.9248 181.735 15.6816 181.883 14.4425C181.628 14.3725 181.122 14.2407 180.871 14.1708Z"
                fill="#C7CED6"
              />
              <path
                d="M61.4905 12.7255C63.3183 12.1739 65.2737 12.1863 67.1509 12.4127C68.5053 12.6391 68.1019 14.1334 68.2172 15.1173C67.4473 15.1544 66.6816 15.1873 65.9118 15.2203C65.7471 14.6933 65.5907 14.1623 65.4713 13.623C66.2535 13.6559 66.6816 14.1252 66.7557 15.0227C66.6775 14.5081 66.5993 13.9894 66.5252 13.4707C65.1749 13.5159 63.6435 13.1619 62.5114 14.1087C64.3022 14.8786 66.3317 15.1544 67.9784 16.2371C68.069 16.5994 68.2583 17.3198 68.3489 17.6779C66.694 18.4436 64.8168 18.2501 63.1248 17.7191C63.0301 17.9043 62.8408 18.2748 62.7461 18.4601C62.3262 18.4477 61.9063 18.4313 61.4905 18.4189C61.4946 17.5256 61.5028 16.6364 61.5152 15.7431C61.7539 15.7554 62.2274 15.7719 62.4661 15.7842C63.7258 16.8958 65.4055 17.3651 67.0645 17.1057C65.496 15.7966 63.2936 15.8089 61.6469 14.6522C61.5111 14.0223 61.5234 13.3719 61.4905 12.7255Z"
                fill="#C7CED6"
              />
              <path
                d="M87.0428 15.2529C87.1458 14.2361 86.6147 12.5894 88.0679 12.3877C89.9245 12.2107 91.8553 12.1489 93.646 12.7582C93.6707 13.4498 93.6954 14.1373 93.7201 14.8248C91.9211 15.8951 89.7393 15.9774 87.9444 17.0478C89.2123 17.1672 90.4885 17.1877 91.7606 17.1548C92.2258 16.525 92.8515 16.0845 93.6213 15.8992C93.6419 16.3726 93.6872 17.3277 93.7078 17.8053C91.5177 18.4186 89.1918 18.2704 86.9688 17.9246C87.0346 17.3359 87.0593 16.7349 87.2075 16.1545C88.6813 15.1911 90.4968 15.0429 92.1599 14.5942C92.1599 14.3184 92.164 13.7709 92.1681 13.4951C90.8796 13.4457 89.5952 13.5157 88.3108 13.4374C88.3602 13.9438 88.459 14.446 88.5043 14.9565C88.5413 14.6848 88.6154 14.1455 88.6525 13.8738C89.307 13.211 90.0398 14.693 89.2494 14.87C88.6072 15.2941 87.7633 15.1212 87.0428 15.2529Z"
                fill="#C7CED6"
              />
              <path
                d="M4.55647 22.379C4.89816 18.9169 8.03508 15.924 11.5384 15.887C14.3954 15.7429 17.3512 17.4266 18.4256 20.1313C20.1464 23.5934 18.545 28.3194 14.9758 29.8879C13.2592 30.8141 11.2585 30.5424 9.39771 30.423C12.5881 33.737 16.7872 36.2935 21.4473 36.701C23.5592 36.9645 25.6587 36.5322 27.7294 36.1453C25.4117 36.7998 23.0487 37.4791 20.6198 37.4914C16.8366 37.2568 13.0163 36.1658 9.96581 33.8481C6.4131 31.2546 4.07893 26.8251 4.55647 22.379Z"
                fill="#C7CED6"
              />
              <path
                d="M178.421 18.1392C178.994 18.1392 179.57 18.1392 180.15 18.1392C180.167 18.6002 180.183 19.0572 180.2 19.5183C179.586 19.5183 178.973 19.5183 178.364 19.5183C178.38 19.0572 178.401 18.5961 178.421 18.1392Z"
                fill="#C7CED6"
              />
              <path
                d="M56.8717 27.0068C58.1273 27.1303 59.6216 26.7269 60.6796 27.5873C61.0337 28.1883 61.0748 28.9046 61.2519 29.5674C60.3915 30.2714 59.3705 30.6872 58.2837 30.8889C58.2878 31.4158 58.2961 31.9428 58.3002 32.4697C57.942 32.4738 57.2216 32.4779 56.8635 32.4779C56.8635 30.6542 56.8593 28.8305 56.8717 27.0068ZM58.2837 28.0731C58.2919 28.6247 58.2961 29.1722 58.3043 29.7239C58.7242 29.6868 59.1441 29.6498 59.564 29.6127C59.5887 29.1681 59.6134 28.7276 59.6422 28.283C59.1894 28.213 58.7365 28.143 58.2837 28.0731Z"
                fill="#C7CED6"
              />
              <path
                d="M63.1785 26.916C63.6519 26.9819 64.1212 27.0683 64.5905 27.1671C65.4632 28.8344 65.9078 30.691 66.4924 32.4736C66.1301 32.4694 65.4056 32.4694 65.0433 32.4694C64.9692 32.1895 64.8252 31.6255 64.7511 31.3456C64.1706 31.3415 63.5943 31.3373 63.0138 31.3332C62.9232 31.6214 62.7421 32.1977 62.6474 32.4859C62.2893 32.4818 61.5688 32.4653 61.2107 32.4571C61.8694 30.6087 62.5075 28.7562 63.1785 26.916ZM63.3431 30.1476C63.6642 30.5881 63.9977 30.5963 64.3476 30.1723C64.9692 28.9208 62.8162 28.8756 63.3431 30.1476Z"
                fill="#C7CED6"
              />
              <path
                d="M79.7974 27.0564C80.3161 27.0481 80.8348 27.0358 81.3535 27.0234C81.9834 28.8307 82.6297 30.6338 83.2472 32.4451C82.8932 32.4575 82.1851 32.4863 81.8352 32.4986C81.6664 32.0952 81.5058 31.6918 81.3412 31.2925C80.9954 31.3172 80.3038 31.3666 79.958 31.3913C79.501 32.0705 78.8506 32.5275 78.0437 32.7045C78.5254 30.7902 79.1429 28.9171 79.7974 27.0564ZM80.5549 28.7977C80.3614 29.3123 80.1762 29.8269 79.9868 30.3415C80.2791 30.3415 80.8595 30.3415 81.1518 30.3374C80.9501 29.8228 80.7525 29.3082 80.5549 28.7977Z"
                fill="#C7CED6"
              />
              <path
                d="M87.0798 27.2C88.3478 26.6361 90.332 26.809 90.5379 28.505C89.4881 28.4351 88.0184 27.41 87.3474 28.6944C86.6352 29.8059 87.3145 31.0533 88.3231 31.6749C89.0558 31.4197 89.7927 31.1727 90.5337 30.938C90.2415 33.3957 86.4911 33.0211 85.8201 31.008C85.2397 29.6659 85.709 27.8587 87.0798 27.2Z"
                fill="#C7CED6"
              />
              <path
                d="M92.3492 27.0845C94.354 26.2447 96.5688 28.1466 96.0748 30.2544C95.9142 32.5803 92.6579 33.4983 91.2377 31.6993C90.4473 30.242 90.4596 27.6691 92.3492 27.0845ZM92.3574 31.1724C92.695 31.3041 93.3783 31.5635 93.72 31.6952C94.9303 30.7237 95.058 27.8708 93.0614 27.9943C91.7975 28.5624 92.1145 30.0938 92.3574 31.1724Z"
                fill="#C7CED6"
              />
              <path
                d="M96.927 27.0312C97.4251 27.0312 97.9232 27.0312 98.4255 27.0312C98.8207 27.8999 99.22 28.7726 99.6234 29.6412C100.056 28.7767 100.48 27.904 100.904 27.0312C101.418 27.0312 101.929 27.0312 102.447 27.0312C102.447 28.8508 102.447 30.6663 102.447 32.4859C101.995 32.4818 101.542 32.4818 101.093 32.4776C101.097 31.3414 101.105 30.2093 101.142 29.0731C100.677 29.987 100.253 30.9997 99.2529 31.4444C98.8948 30.6457 98.5407 29.8471 98.1826 29.0484C98.1414 30.1929 98.1291 31.3414 98.1167 32.4859C97.7215 32.4859 97.3222 32.4859 96.927 32.4818C96.927 30.6663 96.927 28.8467 96.927 27.0312Z"
                fill="#C7CED6"
              />
              <path
                d="M103.534 27.0276C104.094 27.0358 104.654 27.044 105.218 27.0523C105.613 27.9538 106.009 28.8513 106.4 29.7528C106.799 28.8636 107.198 27.9744 107.593 27.077C108.166 27.0564 108.738 27.0399 109.31 27.0234C109.314 28.8389 109.31 30.6544 109.31 32.4739C108.94 32.4739 108.203 32.4739 107.832 32.4739C107.816 31.3624 107.799 30.2509 107.762 29.1394C107.408 29.9257 107.05 30.712 106.68 31.4901C105.832 30.9055 105.284 30.0369 104.819 29.1435C104.86 30.255 104.876 31.3707 104.885 32.4822C104.432 32.4822 103.983 32.4781 103.534 32.4781C103.534 30.6585 103.534 28.843 103.534 27.0276Z"
                fill="#C7CED6"
              />
              <path
                d="M110.253 27.077C111.5 27.0399 112.748 27.0317 113.999 27.0234C114.003 27.2951 114.011 27.8344 114.016 28.102C113.242 28.1061 112.459 28.1102 111.686 28.1144C111.71 28.3614 111.764 28.8554 111.793 29.1024C112.513 29.1065 113.233 29.1106 113.958 29.1188C113.954 29.3411 113.946 29.7899 113.941 30.0163C113.205 30.0163 112.468 30.0163 111.735 30.0204C111.731 30.358 111.723 31.0372 111.718 31.3748C112.459 31.3748 113.209 31.3789 113.954 31.3789C113.974 31.6506 114.016 32.1981 114.032 32.4739C112.776 32.4781 111.521 32.4781 110.265 32.4616C110.265 30.6667 110.269 28.8718 110.253 27.077Z"
                fill="#C7CED6"
              />
              <path
                d="M115.069 27.0068C116.42 27.155 118.12 26.6487 119.157 27.7808C119.659 28.7317 118.935 29.5921 118.416 30.3331C118.988 31.0412 119.557 31.7493 120.129 32.4573C119.717 32.4656 118.89 32.4903 118.478 32.4985C117.811 31.6958 117.136 30.8971 116.436 30.1232C116.378 30.9136 116.337 31.6999 116.296 32.4903C115.884 32.4862 115.477 32.482 115.065 32.482C115.065 30.6542 115.061 28.8305 115.069 27.0068ZM116.333 29.5839C116.774 29.5715 117.21 29.5592 117.655 29.5468C117.683 29.271 117.749 28.7153 117.782 28.4394C117.321 28.2336 116.852 28.0442 116.374 27.8796C116.358 28.4477 116.346 29.0158 116.333 29.5839Z"
                fill="#C7CED6"
              />
              <path
                d="M121.211 27.4715C122.183 26.5988 123.562 27.0475 124.707 27.2328C124.719 27.5868 124.748 28.3031 124.76 28.6612C123.863 28.2702 122.623 27.5127 121.874 28.5748C121.109 29.7604 121.751 31.2342 123.019 31.7241C123.603 31.4153 124.188 31.0983 124.772 30.7813C124.744 31.1642 124.69 31.9299 124.661 32.3128C123.62 32.5021 122.434 32.9344 121.471 32.2634C119.812 31.2795 119.721 28.6489 121.211 27.4715Z"
                fill="#C7CED6"
              />
              <path
                d="M125.324 27.0312C126.308 27.0312 127.292 27.0312 128.276 27.0312C128.251 27.1959 128.198 27.5211 128.173 27.6858C127.107 28.9414 127.202 31.115 128.42 32.2512C127.255 32.527 125.958 32.7164 124.921 31.9466C125.238 31.786 125.872 31.4608 126.185 31.3003C126.028 30.09 126.592 28.5133 125.374 27.7023C125.361 27.5335 125.337 27.2 125.324 27.0312Z"
                fill="#C7CED6"
              />
              <path
                d="M130.441 27.0602C130.956 27.0479 131.466 27.0355 131.985 27.019C132.627 28.8222 133.282 30.617 133.862 32.4407C133.525 32.4572 132.841 32.4901 132.504 32.5066C132.318 32.1073 132.129 31.708 131.948 31.3128C131.549 31.321 130.758 31.3375 130.359 31.3457C130.281 31.6256 130.12 32.1814 130.038 32.4613C129.663 32.4654 128.91 32.4737 128.531 32.4778C129.181 30.6747 129.832 28.8757 130.441 27.0602ZM131.557 30.2218C131.75 29.7978 131.66 29.4561 131.289 29.1885C130.346 29.0774 130.795 30.9917 131.557 30.2218Z"
                fill="#C7CED6"
              />
              <path
                d="M134.352 27.0273C134.813 27.0273 135.278 27.0273 135.748 27.0273C135.748 28.4723 135.748 29.9173 135.743 31.3622C136.517 31.3705 137.295 31.3787 138.073 31.3869C138.082 31.6586 138.102 32.1979 138.111 32.4655C136.863 32.4737 135.612 32.4779 134.364 32.4861C134.344 30.6665 134.348 28.8469 134.352 27.0273Z"
                fill="#C7CED6"
              />
              <path
                d="M140.93 27.0024C142.215 27.0848 143.623 26.8378 144.8 27.4964C144.862 28.1551 144.8 28.8138 144.755 29.4725C145.158 30.1723 145.636 31.1932 144.956 31.8848C143.828 32.7988 142.256 32.3871 140.926 32.5024C140.918 30.6704 140.918 28.8385 140.93 27.0024ZM142.079 29.2502C142.643 29.1884 143.207 29.1225 143.771 29.0484C143.483 28.6615 143.199 28.2745 142.877 27.9122C142.392 28.2045 142.297 28.7767 142.079 29.2502ZM142.318 30.0282C142.305 30.5058 142.293 30.9792 142.285 31.4567C142.824 31.3908 143.359 31.325 143.903 31.255C143.861 30.8804 143.824 30.5058 143.787 30.1311C143.297 30.0941 142.803 30.0611 142.318 30.0282Z"
                fill="#C7CED6"
              />
              <path
                d="M147.315 27.0603C147.834 27.0479 148.357 27.0356 148.88 27.0273C149.506 28.8346 150.148 30.6377 150.773 32.449C150.415 32.4614 149.699 32.4861 149.345 32.4984C149.234 32.2061 149.016 31.6216 148.904 31.3252C148.341 31.3334 147.777 31.3375 147.213 31.3416C147.163 31.6092 147.068 32.1485 147.023 32.4202C146.657 32.4408 145.924 32.4861 145.558 32.5067C146.122 30.683 146.677 28.8593 147.315 27.0603ZM148.135 28.7111C147.916 29.2504 147.715 29.7938 147.501 30.3372C147.785 30.3372 148.353 30.3454 148.637 30.3495C148.468 29.802 148.299 29.2545 148.135 28.7111Z"
                fill="#C7CED6"
              />
              <path
                d="M151.333 26.9077C151.872 26.99 152.416 27.0477 152.947 27.1835C153.643 28.0604 154.104 29.0937 154.655 30.0611C154.676 29.0566 154.692 28.0563 154.705 27.0518C155.034 27.0477 155.689 27.0353 156.018 27.0271C156.018 28.8426 156.018 30.658 156.022 32.4735C155.685 32.4776 155.009 32.4817 154.668 32.4817C153.976 31.2755 153.284 30.0652 152.543 28.8837C152.511 30.0858 152.506 31.2838 152.502 32.4817C152.206 32.4817 151.613 32.4817 151.317 32.4817C151.308 30.6251 151.3 28.7643 151.333 26.9077Z"
                fill="#C7CED6"
              />
              <path
                d="M160.242 27.052C160.793 27.0479 161.345 27.0437 161.901 27.0396C161.242 27.8753 160.579 28.711 159.912 29.5385C160.612 30.5471 161.316 31.5515 161.987 32.5766C161.436 32.5313 160.888 32.4654 160.345 32.3708C159.616 31.7532 159.11 30.9134 158.492 30.193C158.467 30.9546 158.447 31.7162 158.43 32.4819C158.105 32.4819 157.459 32.486 157.134 32.486C157.125 30.6623 157.125 28.8427 157.134 27.019C157.463 27.0232 158.122 27.0355 158.451 27.0396C158.447 27.8177 158.443 28.5999 158.439 29.3779C159.044 28.604 159.645 27.83 160.242 27.052Z"
                fill="#C7CED6"
              />
              <path
                d="M175.14 27.1303C176.087 26.7557 177.104 27.085 178.067 27.2085C178.08 27.6613 178.088 28.1101 178.104 28.5629C177.289 28.39 176.301 27.5996 175.577 28.3488C174.753 29.0857 175.206 30.3001 175.227 31.247C176.219 31.5146 177.223 31.424 178.141 30.9259C178.109 31.2799 178.051 31.988 178.022 32.342C177.03 32.4985 175.943 32.8772 175.005 32.342C172.967 31.4322 173.07 27.9413 175.14 27.1303Z"
                fill="#C7CED6"
              />
              <path
                d="M66.9617 27.056C67.5421 27.0478 68.1267 27.0396 68.7113 27.0313C69.2917 28.044 69.8804 29.0526 70.4979 30.0447C70.5103 29.032 70.5185 28.0235 70.5267 27.0107C70.852 27.0272 71.5024 27.056 71.8276 27.0725C71.8194 28.8674 71.8235 30.6623 71.8235 32.4613C71.4736 32.4654 70.7737 32.4777 70.4238 32.486C69.7363 31.2468 69.0324 30.02 68.2708 28.8262C68.2708 30.0489 68.2708 31.2674 68.2708 32.4901C67.9456 32.4818 67.2992 32.4654 66.974 32.4571C66.9617 30.6581 66.9658 28.8591 66.9617 27.056Z"
                fill="#C7CED6"
              />
              <path
                d="M72.7534 27.0273C74.0049 27.085 75.3511 26.8709 76.5202 27.4267C78.2739 28.3118 78.2039 31.2799 76.4461 32.1279C75.2893 32.6384 73.9802 32.4367 72.7534 32.4943C72.7534 30.6706 72.7534 28.8469 72.7534 27.0273ZM74.2601 27.933C74.2354 29.1598 74.2437 30.3866 74.2766 31.6092C75.2111 31.3828 76.4543 31.0576 76.3679 29.8267C76.5408 28.5382 75.2481 28.1389 74.2601 27.933Z"
                fill="#C7CED6"
              />
              <path
                d="M164.585 27.0107C165.709 27.056 166.874 26.9202 167.961 27.2742C169.142 27.7764 169.171 29.7195 168.026 30.2876C167.38 30.5923 166.668 30.6993 165.985 30.8804C165.997 31.4115 166.009 31.9384 166.026 32.4695C165.663 32.4736 164.943 32.4736 164.585 32.4777C164.581 30.654 164.581 28.8344 164.585 27.0107ZM166.063 27.8711C166.038 28.501 166.017 29.1308 166.009 29.7607C166.503 29.6742 167.001 29.5878 167.504 29.5013C167.446 28.6286 166.878 28.1099 166.063 27.8711Z"
                fill="#C7CED6"
              />
              <path
                d="M169.55 27.0234C169.908 27.0317 170.62 27.044 170.978 27.0523C170.974 28.4972 170.974 29.9422 170.974 31.3871C171.711 31.383 172.448 31.3789 173.189 31.3748C173.205 31.6465 173.242 32.1981 173.263 32.4739C172.024 32.4781 170.789 32.4781 169.55 32.4781C169.55 30.6585 169.554 28.843 169.55 27.0234Z"
                fill="#C7CED6"
              />
              <path
                d="M178.899 30.9832C179.352 30.975 179.808 30.9667 180.265 30.9585C180.303 31.4649 180.335 31.9671 180.372 32.4734C179.862 32.4734 179.347 32.4734 178.841 32.4734C178.858 31.9753 178.874 31.4813 178.899 30.9832Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="229"
              height="30"
              viewBox="0 0 229 30"
              fill="none"
            >
              <path
                d="M118.553 0.178714C120.22 -0.347142 122.127 1.02202 121.996 2.80072C121.943 4.95019 118.994 6.04795 117.481 4.56246C115.904 3.3387 116.658 0.595522 118.553 0.178714Z"
                fill="#C7CED6"
              />
              <path
                d="M182.111 0.295355C183.655 -0.409825 185.659 0.610384 185.889 2.31154C186.221 4.17506 184.016 5.73808 182.332 5.05229C181.466 4.73969 180.708 4.0127 180.575 3.0773C180.337 1.93835 181.054 0.731549 182.111 0.295355Z"
                fill="#C7CED6"
              />
              <path
                d="M0.72961 0.86461C6.54553 0.862187 12.3614 0.862187 18.1774 0.86461C18.1798 8.13451 18.1798 15.4044 18.1774 22.6743C15.2694 22.6791 12.359 22.6767 9.45106 22.6743C9.45833 18.3148 9.45106 13.9528 9.45349 9.59091C6.54553 9.58122 3.63757 9.58849 0.732033 9.58849C0.727186 6.68053 0.72961 3.77257 0.72961 0.86461Z"
                fill="#C7CED6"
              />
              <path
                d="M24.3203 0.999957C26.7751 0.268121 29.5522 2.15587 29.7557 4.70761C30.1701 7.19391 27.9528 9.68506 25.4423 9.58328C23.1207 9.6681 21.0028 7.53317 21.0973 5.21407C21.0585 3.28997 22.4689 1.48462 24.3203 0.999957Z"
                fill="#C7CED6"
              />
              <path
                d="M34.1636 0.866543C37.5611 0.85685 40.9561 0.864119 44.3512 0.861695C41.4359 8.13159 38.5352 15.4063 35.6224 22.6762C32.2298 22.6762 28.8372 22.6762 25.4446 22.6762C28.355 15.4063 31.2653 8.13886 34.1636 0.866543Z"
                fill="#C7CED6"
              />
              <path
                d="M53.073 0.864126C58.4042 0.85928 63.7379 0.861703 69.0692 0.864126C69.0692 2.31811 69.0667 3.77209 69.0692 5.22606C67.1305 5.22849 65.1919 5.22607 63.2508 5.22849C63.2532 11.0444 63.2508 16.8579 63.2532 22.6738C61.7993 22.6762 60.3453 22.6762 58.8889 22.6762C58.8864 16.8603 58.8913 11.0444 58.8889 5.22849C56.9502 5.22607 55.0116 5.23091 53.073 5.22606C53.0681 3.77209 53.073 2.31811 53.073 0.864126Z"
                fill="#C7CED6"
              />
              <path
                d="M109.778 0.86713C111.232 0.85986 112.686 0.859859 114.14 0.864706C114.145 8.1346 114.142 15.4045 114.142 22.6744C112.686 22.6792 111.23 22.6768 109.776 22.6744C109.773 22.071 109.773 21.4676 109.78 20.8642C107.825 23.4765 103.773 23.6001 101.258 21.7463C97.4458 19.0419 96.6631 13.1387 99.4305 9.42622C100.611 7.80261 102.431 6.58127 104.456 6.3874C106.441 6.07722 108.564 6.8551 109.785 8.47386C109.771 5.93667 109.778 3.4019 109.778 0.86713ZM105.043 10.461C102.241 11.2364 101.454 14.9465 102.872 17.2341C103.921 19.0491 106.533 19.5702 108.28 18.4942C111.033 16.8657 110.876 12.0918 107.982 10.6936C107.081 10.2429 106.005 10.238 105.043 10.461Z"
                fill="#C7CED6"
              />
              <path
                d="M157.035 0.866667C158.729 0.859397 160.422 0.856973 162.119 0.869089C164.307 5.95317 166.478 11.0421 168.662 16.1262C170.61 11.0421 172.537 5.94832 174.482 0.864243C176.179 0.86182 177.873 0.854551 179.569 0.866667C176.654 8.13414 173.755 15.4089 170.843 22.6764C169.386 22.6739 167.93 22.6836 166.476 22.6715C163.338 15.3992 160.18 8.13414 157.035 0.866667Z"
                fill="#C7CED6"
              />
              <path
                d="M190.038 9.14509C193.008 5.4011 199.433 5.26297 202.578 8.84703C204.187 10.7469 204.526 13.3689 204.301 15.768C200.303 15.7728 196.304 15.7607 192.308 15.7752C192.507 16.9602 193.258 18.1113 194.433 18.5136C196.571 19.4708 199.365 18.8843 200.741 16.9263C201.798 17.7551 202.896 18.5305 203.967 19.3448C201.141 23.3747 194.87 24.1453 191.038 21.1453C187.483 18.3367 187.086 12.5256 190.038 9.14509ZM192.463 12.6104C194.967 12.6225 197.47 12.6225 199.971 12.6104C199.738 11.9658 199.498 11.2849 198.963 10.8269C196.968 9.08936 193.122 9.87451 192.463 12.6104Z"
                fill="#C7CED6"
              />
              <path
                d="M80.7419 9.15216C82.2443 7.18929 84.7645 6.05276 87.2339 6.37021C88.7072 6.46957 89.9431 7.39042 90.8664 8.4809C90.8737 7.88235 90.8737 7.28137 90.8712 6.68282C92.3277 6.67313 93.7841 6.67797 95.238 6.68282C95.2429 12.0141 95.2356 17.3453 95.2429 22.6742C93.7865 22.6742 92.3301 22.679 90.8761 22.6742C90.8737 22.0708 90.8688 21.4649 90.8712 20.8591C88.9278 23.4763 84.8809 23.595 82.3655 21.7533C78.4446 18.9883 77.7297 12.838 80.7419 9.15216ZM86.1482 10.4583C83.5335 11.1708 82.6587 14.481 83.7201 16.7565C84.5489 18.8139 87.3405 19.599 89.2283 18.5836C92.0732 17.0691 92.0393 12.2782 89.2137 10.7612C88.2904 10.2475 87.1587 10.2281 86.1482 10.4583Z"
                fill="#C7CED6"
              />
              <path
                d="M128.672 8.10306C129.758 6.60546 131.789 6.23954 133.521 6.42614C135.072 6.47945 136.577 7.25248 137.515 8.49079C138.576 9.81876 138.843 11.5781 138.86 13.2308C138.86 16.3786 138.857 19.5265 138.86 22.6744C137.406 22.6768 135.949 22.6792 134.495 22.6744C134.491 19.8415 134.498 17.0087 134.493 14.1758C134.474 13.0005 134.352 11.7114 133.536 10.7953C132.753 9.85753 131.287 9.91326 130.308 10.4852C129.113 11.1685 128.716 12.6346 128.692 13.9238C128.663 16.8415 128.689 19.7591 128.68 22.6768C127.223 22.6768 125.769 22.6768 124.315 22.6744C124.318 17.3431 124.318 12.0118 124.315 6.68058C125.769 6.68058 127.226 6.67816 128.68 6.68058C128.68 7.15555 128.677 7.62809 128.672 8.10306Z"
                fill="#C7CED6"
              />
              <path
                d="M146.236 6.83783C147.923 6.25381 149.896 6.03329 151.541 6.86691C152.3 7.22556 152.828 7.89439 153.395 8.49052C153.397 7.88954 153.397 7.28614 153.392 6.68516C154.849 6.67547 156.305 6.67789 157.762 6.68031C157.754 11.6093 157.769 16.5359 157.754 21.4624C157.747 23.6386 157.175 25.9601 155.566 27.5183C153.923 29.1491 151.505 29.6241 149.273 29.692C146.651 29.595 143.879 28.9383 141.913 27.099C142.701 26.0013 143.513 24.9253 144.303 23.8276C145.485 24.8672 146.939 25.6838 148.541 25.7759C149.905 25.9552 151.466 25.6547 152.37 24.5328C153.373 23.4035 153.434 21.7969 153.4 20.3647C152.794 21.1038 152.113 21.8065 151.226 22.1991C149.605 22.8122 147.756 22.8486 146.169 22.1022C141.841 20.2993 140.127 14.4349 142.357 10.4195C143.169 8.81282 144.581 7.53574 146.236 6.83783ZM148.655 10.4946C146.983 10.9962 145.74 12.6756 145.81 14.4349C145.73 16.6498 147.77 18.7338 150.014 18.506C152.433 18.5981 154.401 16.0318 153.87 13.7006C153.598 11.3209 150.889 9.7361 148.655 10.4946Z"
                fill="#C7CED6"
              />
              <path
                d="M69.0642 6.68304C70.5182 6.68062 71.9721 6.67577 73.4261 6.68304C73.4285 7.57724 73.4382 8.46901 73.4334 9.36321C74.141 7.35187 76.5158 6.46252 78.486 6.98595C78.5538 8.3745 78.5054 9.76548 78.5199 11.154C77.5118 10.8511 76.4019 10.7299 75.3866 11.0668C74.0804 11.6072 73.4237 13.0612 73.4407 14.4134C73.414 17.1662 73.4334 19.9215 73.431 22.6744C71.9746 22.6792 70.5182 22.6768 69.0618 22.6744C69.069 17.3431 69.0642 12.0119 69.0642 6.68304Z"
                fill="#C7CED6"
              />
              <path
                d="M117.048 6.68088C118.502 6.6833 119.958 6.67845 121.412 6.68088C121.41 12.0121 121.41 17.3434 121.412 22.6746C119.958 22.6771 118.502 22.6771 117.048 22.6771C117.045 17.3434 117.045 12.0121 117.048 6.68088Z"
                fill="#C7CED6"
              />
              <path
                d="M181.023 6.68004C182.477 6.67762 183.933 6.67762 185.387 6.68004C185.385 12.0113 185.385 17.3426 185.387 22.6738C183.931 22.6762 182.477 22.6787 181.023 22.6738C181.021 17.3426 181.021 12.0113 181.023 6.68004Z"
                fill="#C7CED6"
              />
              <path
                d="M204.289 6.68048C205.808 6.67321 207.325 6.68048 208.845 6.68048C209.851 10.0828 210.827 13.4948 211.83 16.8995C212.887 13.4948 213.941 10.0901 214.981 6.68048C216.078 6.67805 217.176 6.67805 218.276 6.6829C219.34 10.0901 220.389 13.4996 221.473 16.902C222.459 13.4948 223.433 10.0876 224.412 6.67805C225.941 6.67805 227.473 6.67805 229.004 6.68048C227.281 12.0117 225.561 17.343 223.838 22.6767C222.418 22.6767 220.998 22.6767 219.578 22.6743C218.611 19.6476 217.627 16.6257 216.648 13.6038C215.662 16.6233 214.712 19.6548 213.735 22.6767C212.313 22.6743 210.89 22.6839 209.47 22.667C207.747 17.3381 206.01 12.0117 204.289 6.68048Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="238"
              height="30"
              viewBox="0 0 238 30"
              fill="none"
            >
              <path
                d="M220.29 24.7867H237.562V28.3622H220.29V24.7867ZM221.532 10.1515H236.834V13.6361H221.532V10.1515ZM227.32 1.06128H231.047V26.5139H227.32V1.06128ZM209.412 6.3639H219.351V9.66668H209.412V6.3639ZM213.745 16.7267L217.351 12.424V29.2409H213.745V16.7267ZM213.745 0.788574H217.351V7.90924H213.745V0.788574ZM216.987 13.4543C218.048 14.1815 221.472 17.2116 222.29 17.9691L220.078 20.9082C218.896 19.3326 216.351 16.5449 214.987 15.2117L216.987 13.4543ZM218.018 6.3639H218.745L219.381 6.2427L221.411 7.57593C219.108 13.4846 214.533 18.7872 210.018 21.6355C209.685 20.6658 208.836 18.8781 208.291 18.1812C212.412 15.9389 216.412 11.4847 218.018 7.03052V6.3639Z"
                fill="#C7CED6"
              />
              <path
                d="M180.384 24.7564C185.808 24.6049 194.232 24.3019 201.867 23.9686L201.746 27.3017C194.322 27.7259 186.323 28.0895 180.869 28.3016L180.384 24.7564ZM180.233 15.7268H205.443V19.1205H180.233V15.7268ZM185.626 9.90906H199.837V13.2421H185.626V9.90906ZM187.656 17.9387L191.808 19.0902C190.383 21.9384 188.747 25.0897 187.384 27.1199L184.202 26.0593C185.444 23.8474 186.899 20.484 187.656 17.9387ZM195.171 21.0597L198.383 19.3932C201.11 21.7263 203.928 24.9079 205.322 27.2108L201.958 29.2409C200.686 26.938 197.777 23.4838 195.171 21.0597ZM192.717 4.30343C190.262 7.75771 185.535 11.6362 180.505 14.03C180.081 13.1512 179.112 11.7877 178.354 10.9999C183.535 8.72733 188.384 4.39433 190.626 0.818848H194.474C197.686 5.27305 202.504 8.72733 207.321 10.4545C206.443 11.3635 205.625 12.6664 204.958 13.7572C200.413 11.6362 195.292 7.81831 192.717 4.30343Z"
                fill="#C7CED6"
              />
              <path
                d="M168.9 2.75863L171.324 0.758789C172.688 1.69811 174.475 3.12224 175.324 4.12217L172.779 6.30382C171.991 5.27359 170.294 3.75856 168.9 2.75863ZM148.841 5.90991H176.112V9.45509H148.841V5.90991ZM149.962 12.9397H163.082V16.4545H149.962V12.9397ZM154.568 14.9395H158.204V25.2115H154.568V14.9395ZM148.811 24.6963C152.477 24.1509 158.416 23.1207 163.87 22.0905L164.143 25.3327C159.143 26.4235 153.78 27.5446 149.841 28.3627L148.811 24.6963ZM163.719 0.879992H167.628C167.355 13.3336 169.658 25.4236 172.536 25.4236C173.203 25.4236 173.536 23.757 173.718 19.9391C174.536 20.7875 175.869 21.6663 176.9 22.0299C176.354 27.8173 175.112 29.2414 172.233 29.2414C165.658 29.2414 163.749 14.9092 163.719 0.879992Z"
                fill="#C7CED6"
              />
              <path
                d="M129.418 13.4548H146.114V16.7576H129.418V13.4548ZM132.539 6.00081H145.357V9.30359H132.539V6.00081ZM136.085 0.758789H139.63V29.2111H136.085V0.758789ZM135.115 15.1516L137.933 16C136.206 20.8179 133.327 25.3024 129.843 27.8476C129.297 26.9689 128.206 25.6963 127.419 25.0599C130.691 23.0298 133.63 19.0907 135.115 15.1516ZM140.781 15.3031C142.023 18.8786 144.448 22.6662 146.962 24.7872C146.144 25.4236 144.932 26.6962 144.326 27.6355C141.811 24.9993 139.569 20.4239 138.236 16.0909L140.781 15.3031ZM131.509 2.24352L134.812 2.81924C134.236 6.72803 133.054 10.5762 131.479 13.0003C130.843 12.4852 129.418 11.5761 128.6 11.1822C130.115 9.03088 131.024 5.6372 131.509 2.24352ZM118.237 6.42502H129.176V9.7581H118.237V6.42502ZM122.177 0.758789H125.722V29.2111H122.177V0.758789ZM122.146 8.90968L124.085 9.6672C123.237 14.6971 121.419 20.6057 119.328 23.757C118.965 22.6965 118.177 21.03 117.571 20.0603C119.601 17.5151 121.358 12.7276 122.146 8.90968ZM125.57 10.455C126.267 11.3337 128.782 15.2728 129.297 16.1818L127.206 18.6362C126.54 16.8182 124.813 13.273 123.964 11.7276L125.57 10.455Z"
                fill="#C7CED6"
              />
              <path
                d="M91.3001 4.72766C93.6333 4.78826 98.2996 4.75796 101.633 4.27315C104.693 3.81864 107.753 3.15202 109.268 2.4248L111.783 5.75788C110.753 6.03059 109.935 6.24269 109.45 6.3639C107.572 6.84871 105.148 7.24261 102.814 7.57592C99.3601 8.06073 94.9059 8.18194 92.2698 8.24254L91.3001 4.72766ZM92.6031 25.6351C97.9663 23.1808 100.36 19.1811 100.36 13.5149C100.36 13.5149 100.36 11.5453 100.36 7.24262L104.329 6.7881C104.329 9.39397 104.329 13.424 104.329 13.424C104.329 19.5447 102.33 24.7261 96.4816 28.241L92.6031 25.6351ZM89.0276 11.9998C89.876 12.0604 90.8759 12.1513 91.8455 12.1513C93.7545 12.1513 109.965 12.1513 111.996 12.1513C112.632 12.1513 114.056 12.0604 114.723 11.9998V15.9086C113.904 15.848 112.905 15.7874 112.056 15.7874C110.147 15.7874 93.6636 15.7874 91.8759 15.7874C90.9365 15.7874 89.8154 15.848 89.0276 15.9086V11.9998Z"
                fill="#C7CED6"
              />
              <path
                d="M62.4842 3.27344C63.4538 3.39464 64.6961 3.48554 65.7263 3.48554C67.6353 3.48554 76.1195 3.48554 77.9072 3.48554C78.9071 3.48554 80.1192 3.39464 81.1494 3.27344V7.18223C80.1192 7.12163 78.9374 7.06102 77.9072 7.06102C76.1195 7.06102 67.6353 7.06102 65.7263 7.06102C64.6961 7.06102 63.3932 7.12163 62.4842 7.18223V3.27344ZM58.939 11.0001C59.848 11.1213 60.9085 11.1819 61.8478 11.1819C63.7568 11.1819 79.907 11.1819 81.6948 11.1819C82.3311 11.1819 83.6643 11.1516 84.4825 11.0001V14.9392C83.6946 14.9089 82.5432 14.8786 81.6948 14.8786C79.907 14.8786 63.7568 14.8786 61.8478 14.8786C60.9691 14.8786 59.7874 14.9089 58.939 14.9695V11.0001ZM74.4529 13.1212C74.4529 17.0906 73.756 20.1206 72.4531 22.6356C71.4229 24.7566 69.0594 27.0898 66.3929 28.4836L62.8175 25.9081C64.9688 25.0596 67.1202 23.3325 68.3019 21.5448C69.8169 19.2419 70.2411 16.3936 70.2411 13.1515L74.4529 13.1212Z"
                fill="#C7CED6"
              />
              <path
                d="M41.3646 8.51514C41.9403 9.78777 43.425 13.7572 43.8492 15.151L40.2434 16.3933C39.8495 14.9389 38.5163 11.2725 37.7891 9.69687L41.3646 8.51514ZM52.5455 10.6968C52.2122 11.6967 52.0304 12.333 51.8486 12.939C51.0608 15.9994 49.7579 19.1507 47.6974 21.7868C44.8795 25.4532 41.1222 27.9682 37.577 29.3317L34.3954 26.0895C37.6982 25.1805 41.9403 22.8777 44.4249 19.7264C46.4854 17.1811 47.9701 13.1511 48.3034 9.33326L52.5455 10.6968ZM34.3348 10.0908C35.0923 11.6361 36.5771 15.3328 37.1528 17.0296L33.4561 18.3932C32.9713 16.7872 31.4259 12.7269 30.6987 11.424L34.3348 10.0908Z"
                fill="#C7CED6"
              />
              <path
                d="M18.1542 1.9397C18.9117 3.06083 19.9723 4.96977 20.548 6.0909L18.1239 7.15142C17.4876 5.90909 16.5786 4.12135 15.7605 2.93963L18.1542 1.9397ZM21.7297 0.576172C22.5175 1.7276 23.6386 3.63654 24.1841 4.69707L21.76 5.75759C21.154 4.48496 20.1541 2.72752 19.3359 1.57609L21.7297 0.576172ZM5.06431 3.27293C4.94311 4.18195 4.85221 5.54549 4.85221 6.42421C4.85221 8.27255 4.85221 19.4232 4.85221 21.6958C4.85221 23.029 5.45822 23.3623 6.82175 23.6047C7.73077 23.7562 8.9731 23.7865 10.3063 23.7865C13.4879 23.7865 18.3663 23.4229 20.8813 22.7563V27.1802C18.0027 27.6044 13.4273 27.7862 10.0942 27.7862C8.12468 27.7862 6.27634 27.665 4.97341 27.4529C2.36755 26.9681 0.822212 25.5439 0.822212 22.9078C0.822212 19.4232 0.822212 8.30285 0.822212 6.42421C0.822212 5.72729 0.73131 4.18195 0.610107 3.27293H5.06431ZM3.06446 12.5752C6.97325 11.7268 12.1547 10.0906 15.3362 8.78766C16.3968 8.33315 17.427 7.84834 18.639 7.15142L20.245 10.9693C19.0935 11.4541 17.73 12.0298 16.7301 12.4237C13.1546 13.7873 7.21566 15.575 3.09476 16.5446L3.06446 12.5752Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="82"
              height="43"
              viewBox="0 0 82 43"
              fill="none"
            >
              <path
                d="M1.91151 4.63108C3.55705 2.22649 6.5711 1.23473 9.36353 1.1073C13.53 0.963246 17.6965 1.20703 21.8519 1.47298C21.8519 3.11852 21.8464 4.76406 21.8464 6.4096C17.9126 6.14365 13.9622 5.65608 10.0118 5.84446C7.51853 5.87771 6.66529 9.36825 8.55461 10.7977C12.372 13.9835 17.6522 15.0362 21.0485 18.8149C23.8243 21.7347 24.0072 26.6769 21.5915 29.8627C19.885 32.0069 17.0593 32.8546 14.4165 32.971C10.4606 33.0873 6.51015 32.7937 2.56529 32.5443C2.56529 30.9265 2.55975 29.3087 2.56529 27.6964C6.32732 27.9457 10.0949 28.3446 13.868 28.1839C15.785 28.1451 16.3446 25.868 16.0122 24.3277C15.5579 22.5935 13.8957 21.5962 12.4496 20.743C9.07542 18.8149 5.19705 17.5295 2.4988 14.6096C-0.0110618 11.9612 -0.0276834 7.60081 1.91151 4.63108Z"
                fill="#C7CED6"
              />
              <path
                d="M24.866 1.07984C26.8994 1.0743 28.9272 1.0743 30.9606 1.07984C30.955 3.93876 30.955 6.79214 30.955 9.65106C34.1409 8.93079 37.72 8.00552 40.7729 9.65106C42.629 10.6594 43.1996 12.9144 43.1442 14.8813C43.1498 20.9261 43.1498 26.9652 43.1387 33.0044C41.0942 33.0044 39.0498 33.0044 37.0109 32.9823C37.033 27.3309 37.0053 21.6796 37.0275 16.0282C36.9665 15.247 37.1161 14.3384 36.5621 13.6957C34.6894 13.2801 32.7613 13.8342 30.9218 14.2054C30.9994 20.4717 30.944 26.7381 30.9495 33.0044C28.9217 32.9989 26.8938 32.9989 24.866 33.0044C24.8604 22.3611 24.866 11.7177 24.866 1.07984Z"
                fill="#C7CED6"
              />
              <path
                d="M55.051 1.3128C57.0622 0.332123 59.6607 2.14942 59.3283 4.3878C59.1731 6.65388 56.209 7.88388 54.4637 6.48212C52.5965 5.25212 52.9622 2.09401 55.051 1.3128Z"
                fill="#C7CED6"
              />
              <path
                d="M47.1945 1.7059C48.9232 0.980085 51.0452 2.49819 50.7349 4.39306C50.5576 6.30454 48.0034 7.30738 46.574 6.03306C45.0337 4.94711 45.4714 2.36522 47.1945 1.7059Z"
                fill="#C7CED6"
              />
              <path
                d="M63.3394 1.7557C64.8797 1.02989 66.8466 2.14354 66.9685 3.85556C67.2067 5.87786 64.6359 7.40151 62.9849 6.18259C61.3061 5.22408 61.5332 2.44272 63.3394 1.7557Z"
                fill="#C7CED6"
              />
              <path
                d="M40.0859 2.23779C41.3436 1.61725 43.0003 2.39292 43.1665 3.82238C43.438 5.41806 41.4544 6.70346 40.0915 5.8613C38.6565 5.20752 38.6842 2.91927 40.0859 2.23779Z"
                fill="#C7CED6"
              />
              <path
                d="M70.5143 2.21549C72.8967 0.92454 74.8858 4.82508 72.4092 5.89995C70.06 7.19089 68.1374 3.37346 70.5143 2.21549Z"
                fill="#C7CED6"
              />
              <path
                d="M34.6672 3.7783C35.4263 2.81979 37.2879 2.76438 37.1439 4.35452C36.5012 5.68979 34.9609 4.95844 34.6672 3.7783Z"
                fill="#C7CED6"
              />
              <path
                d="M75.9109 3.27407C76.4262 2.7588 77.1132 3.27961 77.695 3.3461C77.5509 4.04975 77.562 5.38502 76.4428 5.04705C75.5064 5.2188 75.0854 3.66745 75.9109 3.27407Z"
                fill="#C7CED6"
              />
              <path
                d="M45.1223 9.04141C47.1668 9.03033 49.2112 9.03587 51.2557 9.05249C51.2446 17.0309 51.2446 25.0092 51.2557 32.9876C49.2112 32.9987 47.1668 33.0098 45.1223 32.9987C45.1223 25.0148 45.1223 17.0309 45.1223 9.04141Z"
                fill="#C7CED6"
              />
              <path
                d="M53.2337 9.03564C55.2726 9.03564 57.3115 9.04119 59.356 9.04119C59.3393 17.4683 59.3671 25.8955 59.3449 34.3227C59.3172 36.3505 58.9792 38.7274 57.1841 39.974C55.1175 41.3204 52.5134 40.9436 50.1919 40.8439C50.1864 39.7856 50.1864 38.7274 50.1753 37.6692C51.3443 37.309 53.2281 37.0819 53.1783 35.4585C53.3002 26.6546 53.1783 17.8396 53.2337 9.03564Z"
                fill="#C7CED6"
              />
              <path
                d="M61.3281 9.04119C63.367 9.04119 65.406 9.03564 67.4504 9.03564C67.4615 17.0251 67.456 25.0146 67.4504 33.004C65.4115 33.004 63.367 32.9985 61.3281 32.9985C61.3281 25.009 61.3281 17.0251 61.3281 9.04119Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M45.805 38.4798H42.8967L38.7161 31.5727L34.5355 38.4798H31.6272L37.262 29.2097L34.5355 24.8473L26.1743 38.4798L8.90649 9.03369H27.8102L34.5355 20.6667L37.262 17.0314L32.5361 9.03369H35.2626L38.7161 14.8502L42.3514 9.03369H45.0779L40.352 17.0314L42.8967 20.6667L49.6221 9.03369H68.344L51.985 38.4798L42.8967 24.8473L40.352 29.2097L45.805 38.4798ZM26.5379 11.0329H13.269L25.9926 34.299L33.6268 22.8477L26.5379 11.0329ZM43.8057 22.8477L51.4399 11.0329H64.7088L51.4399 34.299L43.8057 22.8477ZM38.7162 19.3942L36.1715 22.8477L38.7162 26.6648L41.0792 22.8477L38.7162 19.3942Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M114.331 8.85205H111.059L111.968 12.3056H115.785L114.331 8.85205ZM72.1611 9.57911V31.5728H75.4329V12.3056H91.9736V28.6645H90.5195V31.5728H95.4272V9.57911H72.1611ZM79.0682 14.305H76.16L78.1594 20.8486L76.16 27.2104H79.0682L79.7953 25.211L80.8859 27.2104H83.4306L81.4312 20.8486L83.4306 14.305H80.8859L79.7953 16.1227L79.0682 14.305ZM87.0659 14.305H84.5212L86.7024 21.2121L84.5212 27.2104H87.0659L87.793 25.211L89.0654 27.2104H91.4283L89.0654 20.8486L91.4283 14.305H89.0654L87.793 16.668L87.0659 14.305ZM98.5172 9.57911V13.0327H99.426V27.7557L98.3354 28.1192L98.5172 31.5728L105.424 29.9369V31.5728H109.423V29.3916H110.332V25.7563H109.423V13.0327H110.332V9.57911H98.5172ZM118.875 8.85205H122.874L121.238 13.9415H122.874V17.5768H118.875V21.2121H122.51V25.211H118.875L123.237 31.391L118.875 31.5728L116.694 27.7557L114.331 31.5728L109.787 31.7546L113.786 25.211H110.332V21.2121H114.876V17.2133H111.059V13.9415H116.694L118.875 8.85205ZM129.963 9.57911H125.782L129.963 16.3045H134.143L129.963 9.57911ZM135.234 8.85205H139.233V17.5768H149.23V21.2121H143.413V28.6645H145.595L146.685 24.4839H150.139L148.321 31.7546H139.778V21.2121H135.234L129.963 31.5728L125.237 31.7546L131.053 21.2121H125.782V17.5768H135.234V8.85205ZM149.23 8.85205H145.049L140.323 16.668H144.868L149.23 8.85205ZM154.501 8.85205L151.048 19.5762H153.774V31.7546H157.228V17.2133H155.592L158.5 8.85205H154.501ZM167.952 8.85205H163.953L166.679 14.4868H170.86L167.952 8.85205ZM159.227 10.6697H162.862V14.4868L166.861 19.9398H167.952L172.132 14.4868V10.6697H175.95V15.3956L169.951 23.0298L176.495 31.5728L172.132 31.7546L167.407 26.1198L162.862 31.7546H157.955L164.68 23.0298L159.227 15.7592V10.6697ZM102.88 15.3954V13.2142H105.606V15.3954H102.88ZM102.88 21.3937V19.0308H105.606V21.3937H102.88ZM102.88 24.302V27.0285H105.606V24.302H102.88Z"
                fill="#C7CED6"
              />
              <path
                d="M84.9059 36.4797C84.9041 35.8454 85.4681 35.3319 86.0862 35.3066C86.7943 35.2508 87.5421 35.1985 88.2268 35.4292C88.8088 35.8634 88.9962 36.7193 88.7242 37.3842C88.4232 38.1356 88.4395 38.9429 88.5205 39.7339C87.5205 39.8204 87.097 38.7915 86.4177 38.2636C86.3042 38.7483 86.2123 39.2366 86.1294 39.7267C85.7294 39.651 85.0969 39.7555 84.9474 39.2654C84.814 38.3464 84.8789 37.4059 84.9059 36.4797ZM85.879 35.7391C85.369 36.0184 85.2267 36.6419 85.342 37.177C85.5114 37.9789 85.0213 38.9104 85.7186 39.5483C85.9835 39.0204 86.0375 38.2636 86.6952 38.0672C87.1205 38.6384 87.5962 39.1771 88.1764 39.597C88.1512 38.8708 88.0809 38.1338 88.2701 37.4239C88.3746 36.8851 88.5223 36.1716 88.0178 35.786C87.3745 35.422 86.5439 35.4418 85.879 35.7391Z"
                fill="#C7CED6"
              />
              <path
                d="M168.552 35.2799C169.577 35.1159 170.622 35.1159 171.658 35.1591C171.988 35.1339 172.186 35.4258 172.408 35.6204C172.302 35.6114 172.091 35.5952 171.986 35.5862C170.972 35.4745 169.95 35.5321 168.939 35.6529C169.339 36.0727 169.909 36.4349 170.002 37.0511C170.125 37.8368 170.082 38.635 170.092 39.426C170.406 39.4819 170.716 39.5486 171.031 39.6044C170.667 39.817 170.242 39.8152 169.835 39.7954C169.754 38.853 169.777 37.9052 169.772 36.9592C169.336 36.7682 168.826 36.6655 168.501 36.2943C168.487 35.9556 168.523 35.6168 168.552 35.2799Z"
                fill="#C7CED6"
              />
              <path
                d="M72.4442 35.9787C72.4262 35.4238 73.1307 35.2526 73.5452 35.4616C73.9686 36.0742 74.2749 36.777 74.8534 37.2743C75.1975 36.7824 75.5633 36.3049 75.9922 35.8814C75.7002 36.4941 75.3633 37.0833 75.0065 37.6581C74.2984 37.2473 73.8371 36.5644 73.5055 35.8328C73.2551 35.8166 73.0064 35.8022 72.7559 35.7859C72.6532 37.0112 72.5794 38.2419 72.6064 39.4708C72.8587 39.4384 73.1127 39.4059 73.365 39.3735C73.3362 38.851 73.3127 38.3302 73.2911 37.8077C73.965 37.7194 74.4822 38.1356 74.9038 38.6059C75.2083 38.3194 75.3993 37.759 75.8192 37.6869C76.2642 38.1266 76.1201 38.8816 76.2552 39.4618C76.4408 39.46 76.6246 39.46 76.8102 39.4582C76.7868 38.2437 76.7814 37.0274 76.6967 35.8166C76.4624 35.8382 76.2282 35.8598 75.9922 35.8814C76.021 35.7679 76.0768 35.5373 76.1057 35.422C76.4156 35.476 76.9093 35.4057 76.9616 35.8346C77.1346 36.9211 77.2174 38.0365 77.1688 39.1357C77.1616 39.7069 76.4931 39.6708 76.0877 39.7123C75.994 39.1555 75.9075 38.5969 75.7993 38.0437C75.4912 38.3681 75.1921 38.6996 74.8912 39.0312C74.6263 38.7483 74.365 38.4654 74.102 38.1843C74.0263 38.1915 73.8749 38.2023 73.7992 38.2095C73.7344 38.6816 73.6965 39.1627 73.5308 39.6132C73.1704 39.678 72.8046 39.6744 72.4406 39.6834C72.2622 38.4545 72.3685 37.2094 72.4442 35.9787Z"
                fill="#C7CED6"
              />
              <path
                d="M89.9856 35.5105C91.0433 35.4385 92.1262 35.3754 93.1479 35.716C93.4686 36.9286 93.4614 38.2242 93.184 39.4459C92.1677 39.7054 91.0992 39.6783 90.0649 39.5846C89.791 39.3558 89.7621 38.9774 89.7567 38.6476C89.7351 37.5971 89.8198 36.5484 89.9856 35.5105ZM90.8217 36.17C90.4631 36.9503 90.3658 37.8404 90.5514 38.6801C90.8145 39.217 91.6433 39.2224 92.0542 38.8639C92.51 38.2152 92.6704 37.2223 92.0794 36.5989C91.8271 36.1304 91.2703 36.2079 90.8217 36.17Z"
                fill="#C7CED6"
              />
              <path
                d="M78.4301 35.8115C78.8463 35.8313 79.3851 35.7683 79.4464 36.3161C79.4518 37.3449 79.3058 38.372 79.3725 39.4045C79.0607 39.4243 78.7472 39.4387 78.4355 39.4514C78.3832 38.2387 78.3688 37.0224 78.4301 35.8115Z"
                fill="#C7CED6"
              />
              <path
                d="M118.427 35.4849C119.03 35.4597 119.634 35.4705 120.238 35.5066C120.247 35.575 120.267 35.712 120.276 35.7805C120.29 35.894 120.319 36.1228 120.335 36.2381C120.122 36.148 119.912 36.0561 119.701 35.966C119.704 36.2345 119.71 36.5048 119.706 36.7751C119.485 36.6706 119.25 36.5931 119.029 36.4922C118.998 36.8129 118.976 37.1355 118.96 37.458C118.692 37.4328 118.425 37.3968 118.158 37.3607C118.034 37.7391 117.899 38.1157 117.834 38.5103C117.767 38.8563 118.003 39.168 118.054 39.4924C117.946 39.5374 117.728 39.6257 117.62 39.6707C117.465 38.8419 117.677 38.0112 117.713 37.1823C117.742 36.5481 118.128 36.0201 118.427 35.4849Z"
                fill="#C7CED6"
              />
              <path
                d="M137.552 35.5498C138.372 35.3588 139.413 35.1372 140.109 35.748C139.669 36.3769 138.775 36.1264 138.119 36.2219C138.112 36.5733 138.11 36.9228 138.11 37.2742C138.734 37.3445 139.375 37.3607 139.977 37.5607C140.484 38.1553 140.123 39.0058 140.055 39.6978C139.094 39.6293 138.132 39.6617 137.172 39.6365C137.238 39.4563 137.307 39.2743 137.375 39.0941C138.011 39.0797 138.647 39.0671 139.285 39.0527C139.255 39.1446 139.192 39.3302 139.161 39.4221C139.413 39.3986 139.666 39.3716 139.918 39.3464C139.925 38.831 139.93 38.3175 139.93 37.8022C139.604 37.7679 139.28 37.7319 138.956 37.6941C139.127 37.9211 139.3 38.1481 139.46 38.3842C138.716 38.1625 137.91 38.1157 137.211 37.7625C136.386 37.2454 136.613 35.8057 137.552 35.5498ZM137.109 36.6742C137.022 37.094 137.401 37.3499 137.651 37.6148C137.736 36.9607 137.835 36.3102 137.983 35.6687C137.656 35.9588 137.119 36.1696 137.109 36.6742Z"
                fill="#C7CED6"
              />
              <path
                d="M173.77 35.4815C174.669 35.1932 175.632 35.3211 176.558 35.3626C176.448 35.6346 176.345 35.9103 176.241 36.1824C175.655 36.2455 175.071 36.3176 174.489 36.4022C174.576 36.6041 174.653 36.8131 174.763 37.0041C175.226 37.2816 175.792 37.287 176.306 37.4221C176.385 37.4672 176.545 37.5573 176.624 37.6023C176.659 38.2762 176.641 38.9501 176.677 39.6222C175.477 39.7123 174.224 39.9232 173.064 39.4961C173.161 39.3159 173.235 39.0745 173.48 39.0582C174.167 38.8907 174.95 39.0078 175.523 38.5051C174.787 38.2618 173.979 38.2474 173.278 37.9104C172.559 37.2545 172.765 35.7554 173.77 35.4815ZM173.433 36.0743C173.024 36.3788 172.976 37.0005 173.237 37.4167C173.565 37.5771 173.922 37.6726 174.269 37.7861C174.23 37.1951 174.21 36.6041 174.219 36.0148C174.857 35.932 175.496 35.8581 176.129 35.7373C175.241 35.4617 174.19 35.4815 173.433 36.0743ZM174.738 37.7068C175.086 37.914 175.46 38.0906 175.763 38.3663C175.999 38.642 175.778 39.1519 175.414 39.1664C174.758 39.3105 174.078 39.2547 173.415 39.3375C174.359 39.5357 175.329 39.4493 176.287 39.4601C176.385 38.8438 176.399 38.215 176.298 37.5987C175.778 37.6239 175.257 37.66 174.738 37.7068Z"
                fill="#C7CED6"
              />
              <path
                d="M81.122 35.676C82.0103 35.4364 82.9347 35.4328 83.8464 35.5085C83.8032 35.7986 83.7563 36.0905 83.7059 36.3788C83.1491 36.368 82.5941 36.3644 82.0373 36.3626C81.6878 37.2094 81.7778 38.1356 81.895 39.0185C82.5455 39.0672 83.1977 39.0762 83.85 39.0816C83.8464 39.1969 83.8374 39.4258 83.8338 39.5411C82.8986 39.6438 81.9472 39.66 81.0174 39.4961C80.8787 38.2311 80.7832 36.9175 81.122 35.676Z"
                fill="#C7CED6"
              />
              <path
                d="M141.46 35.4991C142.444 35.4396 143.433 35.4757 144.421 35.4811C144.513 36.2127 143.956 36.6325 143.415 36.9857C143.433 37.8055 143.448 38.6254 143.444 39.4453C143.089 39.5155 142.734 39.5894 142.377 39.6345C142.332 38.8182 142.402 37.9983 142.323 37.1857C142.271 36.4992 141.424 36.1928 141.46 35.4991Z"
                fill="#C7CED6"
              />
              <path
                d="M85.879 35.7388C86.5439 35.4415 87.3745 35.4217 88.0178 35.7857C88.5223 36.1713 88.3746 36.8848 88.2701 37.4236C88.0809 38.1335 88.1512 38.8705 88.1764 39.5967C87.5962 39.1768 87.1205 38.6381 86.6952 38.0669C86.0375 38.2633 85.9835 39.0201 85.7186 39.548C85.0213 38.9102 85.5114 37.9786 85.342 37.1767C85.2267 36.6416 85.369 36.0181 85.879 35.7388ZM86.3979 36.2596C86.333 36.5983 86.3186 36.9407 86.2916 37.2831C86.6394 37.384 86.9907 37.4993 87.3601 37.4921C87.8593 37.1894 87.5133 36.5785 87.4286 36.1443C87.0844 36.1731 86.7349 36.1803 86.3979 36.2596Z"
                fill="#C7CED6"
              />
              <path
                d="M97.339 35.638C98.3895 35.3803 99.5067 35.4001 100.55 35.683C100.827 36.9353 100.824 38.2471 100.579 39.5048C99.6022 39.6868 98.5985 39.6418 97.6111 39.6003C97.3084 39.6111 97.157 39.2616 97.1732 39.0021C97.1516 37.8813 97.0579 36.7371 97.339 35.638ZM97.9426 36.7767C97.7805 37.393 97.8201 38.0561 97.939 38.6759C98.3697 39.3246 99.2184 39.0507 99.831 38.8904C99.9445 38.1318 100.31 37.1876 99.6851 36.5569C99.2562 35.9785 98.1823 36.056 97.9426 36.7767Z"
                fill="#C7CED6"
              />
              <path
                d="M101.844 35.6854C102.837 35.3683 103.896 35.4098 104.914 35.5647C104.979 35.8044 105.042 36.044 105.105 36.2837C104.862 36.1611 104.619 36.0368 104.376 35.9125C104.43 36.3954 104.482 36.8783 104.511 37.3648C104.426 37.3774 104.258 37.4027 104.174 37.4153C104.114 37.0639 104.051 36.7143 103.992 36.363C103.649 36.3557 103.307 36.3467 102.965 36.3377C102.95 36.7269 102.943 37.1162 102.948 37.5054C102.716 37.5144 102.484 37.536 102.251 37.5504C102.127 38.0748 101.995 38.5973 101.849 39.1163C102.188 38.7775 102.543 38.4459 102.967 38.2135C102.824 38.6406 102.678 39.0676 102.538 39.4965C102.246 39.464 101.925 39.5289 101.664 39.3739C101.711 38.1468 101.498 36.8801 101.844 35.6854Z"
                fill="#C7CED6"
              />
              <path
                d="M106.392 35.6994C107.307 35.6219 108.226 35.593 109.145 35.6183C109.16 35.6904 109.187 35.8363 109.201 35.9084C108.814 36.2706 108.147 36.4381 108.037 37.0201C107.943 37.8238 108.023 38.6346 107.99 39.4419C107.862 39.4545 107.605 39.4815 107.477 39.496C107.39 38.6365 107.489 37.7679 107.399 36.9102C107.226 36.4039 106.731 36.094 106.392 35.6994Z"
                fill="#C7CED6"
              />
              <path
                d="M129.97 35.6906C130.141 35.6726 130.314 35.6528 130.485 35.6348C130.68 36.9682 130.781 38.3592 130.438 39.6782C130.271 39.6061 130.105 39.5341 129.939 39.462C129.919 38.2061 129.89 36.9465 129.97 35.6906Z"
                fill="#C7CED6"
              />
              <path
                d="M113.369 35.6526C114.331 35.4417 115.372 35.3102 116.313 35.6796C116.255 35.8147 116.14 36.0886 116.082 36.2238C115.511 36.3319 114.918 36.3337 114.361 36.4959C113.666 37.1193 114.051 38.0815 114.142 38.8654C114.848 38.9609 115.601 38.9717 116.225 39.3591C115.313 39.7879 114.28 39.633 113.314 39.5393C113.143 38.2527 113.114 36.9283 113.369 35.6526Z"
                fill="#C7CED6"
              />
              <path
                d="M122.128 39.4819C121.989 38.1791 121.807 36.7844 122.25 35.5249C122.495 35.7411 122.928 35.8961 122.91 36.2889C122.935 37.1106 122.885 37.9322 122.922 38.7539C123.663 39.0296 124.452 39.1035 125.238 39.1071C125.254 39.2026 125.288 39.3954 125.305 39.4909C124.258 39.6837 123.173 39.6927 122.128 39.4819Z"
                fill="#C7CED6"
              />
              <path
                d="M145.336 37.0073C145.114 36.2937 145.702 35.5315 146.424 35.4792C147.142 35.4234 147.87 35.4162 148.585 35.5135C149.091 36.2144 148.852 37.137 148.715 37.919C148.579 38.5064 148.826 39.1173 148.643 39.6957C147.565 39.8038 147.652 38.0145 146.54 38.3371C146.524 38.7317 146.515 39.1245 146.509 39.5191C146.109 39.5605 145.569 39.7894 145.314 39.3425C145.152 38.5641 145.572 37.7857 145.336 37.0073ZM146.706 37.2001C147.077 37.6253 148.183 37.6001 147.99 36.8379C148.093 36.1928 147.338 36.173 146.895 36.1315C146.801 36.4811 146.677 36.8325 146.706 37.2001Z"
                fill="#C7CED6"
              />
              <path
                d="M154.857 35.4849C155.257 35.7335 155.656 35.9912 155.996 36.3209C155.811 37.3823 155.171 38.3391 155.299 39.4526C155.174 39.4779 154.926 39.5283 154.802 39.5535C154.783 38.1985 154.764 36.8381 154.857 35.4849Z"
                fill="#C7CED6"
              />
              <path
                d="M157.285 37.2867C157.58 36.5767 157.804 35.6271 158.697 35.4722C159.024 35.8488 158.968 36.3659 158.973 36.8272C158.957 37.7552 159.006 38.685 158.944 39.6111C158.71 39.5877 158.478 39.5643 158.245 39.539C158.232 38.9895 158.213 38.4399 158.178 37.8903C157.555 38.2056 156.939 38.5372 156.285 38.7895C156.121 38.4615 155.87 38.193 155.582 37.9696C155.522 38.4507 155.499 38.9354 155.447 39.4165L155.299 39.4526C155.171 38.339 155.811 37.3822 155.996 36.3209C156.027 36.3803 156.088 36.4992 156.119 36.5587C156.187 36.811 156.247 37.065 156.31 37.3191C156.634 37.3101 156.959 37.2993 157.285 37.2867ZM157.946 36.4308C157.861 36.7191 157.834 37.0182 157.793 37.3137C158.025 37.3173 158.261 37.3227 158.497 37.3263C158.627 36.8596 158.701 36.3785 158.714 35.892C158.451 36.0578 158.135 36.1749 157.946 36.4308Z"
                fill="#C7CED6"
              />
              <path
                d="M159.995 35.6508L160.144 35.631C161.128 35.4562 162.132 35.4292 163.126 35.5391C163.126 35.658 163.128 35.8941 163.128 36.013C162.519 36.0671 161.912 36.1391 161.303 36.2004C161.305 36.5248 161.297 36.8491 161.335 37.1716C161.67 37.341 162.034 37.4473 162.393 37.5644C162.449 37.6545 162.559 37.8365 162.615 37.9284C162.18 38.0203 161.744 38.105 161.317 38.2275L161.296 38.3717C161.272 38.5465 161.252 38.7231 161.238 38.8979C161.863 39.0276 162.499 39.0871 163.139 39.0798C163.139 39.2114 163.137 39.4781 163.137 39.6114C162.143 39.6456 161.137 39.7249 160.15 39.5826C159.778 39.3303 159.874 38.8474 159.856 38.4654C159.912 37.5266 159.757 36.5734 159.995 35.6508Z"
                fill="#C7CED6"
              />
              <path
                d="M164.171 36.6525C164.152 36.2453 164.197 35.8363 164.344 35.4525C164.692 35.4651 165.042 35.4759 165.393 35.4849C165.447 35.6093 165.555 35.8597 165.609 35.9858C165.227 36.0237 164.845 36.0597 164.465 36.0976C164.528 36.4363 164.579 36.7823 164.71 37.103C165.042 37.2886 165.427 37.3409 165.795 37.4328C165.757 37.1589 165.719 36.885 165.683 36.6111C166.036 36.7553 166.309 37.0201 166.564 37.2958C166.727 37.2994 166.889 37.303 167.053 37.3084C167.146 36.7084 166.795 35.8759 167.309 35.4507C167.406 35.4597 167.597 35.4795 167.694 35.4885C168.098 36.0381 167.943 36.7462 167.968 37.3787C167.923 38.1553 168.083 38.9662 167.772 39.7032C167.48 39.6617 167.19 39.6221 166.903 39.5662C167.049 39.4761 167.345 39.2941 167.491 39.2022C167.469 38.7013 167.438 38.1986 167.402 37.6976C166.993 37.8472 166.593 38.0418 166.316 38.386C165.963 38.177 165.613 37.9607 165.267 37.7373C165.26 38.2905 165.29 38.8437 165.276 39.3968C165.103 39.8329 164.552 39.6041 164.206 39.6167C164.096 38.6328 164.204 37.6418 164.171 36.6525Z"
                fill="#C7CED6"
              />
              <path
                d="M168.939 35.6529C169.95 35.5321 170.972 35.4745 171.986 35.5862C171.975 35.8925 171.968 36.197 171.923 36.4998C171.637 36.6349 171.338 36.7394 171.037 36.8295C171.017 37.7539 171.055 38.6801 171.031 39.6044C170.716 39.5486 170.406 39.4819 170.092 39.426C170.082 38.635 170.125 37.8368 170.002 37.0511C169.909 36.4349 169.339 36.0727 168.939 35.6529Z"
                fill="#C7CED6"
              />
              <path
                d="M135.166 35.7803C135.267 35.8001 135.469 35.8415 135.57 35.8632C135.637 37.0975 135.615 38.3372 135.577 39.5715C134.539 39.53 134.453 38.3282 133.781 37.7768C133.537 37.566 133.06 37.2578 132.881 37.6975C132.746 38.2633 132.831 38.8525 132.844 39.4273C132.718 39.4543 132.463 39.5084 132.337 39.5336C132.136 38.6273 132.262 37.6957 132.413 36.7929C132.503 36.9966 132.593 37.2002 132.687 37.402C132.986 37.184 133.283 36.9641 133.581 36.7425C133.966 37.0957 134.31 37.647 134.892 37.6362C135.192 37.065 135.132 36.4019 135.166 35.7803ZM134.586 38.2903C134.541 38.8345 135.028 39.1552 135.422 39.4183C135.429 38.8399 135.363 38.2633 135.224 37.7029C134.997 37.8777 134.559 37.9281 134.586 38.2903Z"
                fill="#C7CED6"
              />
              <path
                d="M173.433 36.0741C174.19 35.4813 175.241 35.4615 176.129 35.7372C175.496 35.8579 174.857 35.9318 174.219 36.0147C174.21 36.6039 174.23 37.1949 174.269 37.7859C173.922 37.6724 173.565 37.5769 173.237 37.4166C172.976 37.0003 173.024 36.3787 173.433 36.0741Z"
                fill="#C7CED6"
              />
              <path
                d="M72.7558 35.7861C73.0062 35.8023 73.2549 35.8168 73.5053 35.833C73.8369 36.5646 74.2982 37.2475 75.0063 37.6583C75.3631 37.0835 75.7001 36.4943 75.992 35.8816C76.228 35.86 76.4623 35.8384 76.6965 35.8168C76.7812 37.0276 76.7866 38.2439 76.81 39.4584C76.6244 39.4602 76.4406 39.4602 76.255 39.462C76.1199 38.8818 76.2641 38.1268 75.819 37.6871C75.3991 37.7592 75.2081 38.3196 74.9036 38.6061C74.482 38.1358 73.9648 37.7196 73.2909 37.8079C73.3125 38.3304 73.336 38.8512 73.3648 39.3737C73.1125 39.4061 72.8585 39.4386 72.6062 39.471C72.5792 38.2421 72.6531 37.0114 72.7558 35.7861ZM72.954 36.0654C73.0008 36.4871 73.0585 36.9069 73.1233 37.325C73.4117 37.3232 73.7 37.3195 73.9901 37.3159C73.7882 36.7934 73.5612 36.215 72.954 36.0654ZM75.8388 37.5286C76.0694 37.561 76.2659 37.4168 76.4442 37.2925C76.5001 36.8889 76.4713 36.4781 76.4605 36.0726C76.0785 36.4492 75.41 36.9339 75.8388 37.5286Z"
                fill="#C7CED6"
              />
              <path
                d="M111.039 35.8363C111.214 35.8345 111.39 35.8309 111.565 35.8291C111.619 37.0382 111.612 38.2509 111.578 39.4617C111.392 39.4617 111.205 39.4635 111.021 39.4635C110.979 38.2545 110.97 37.0436 111.039 35.8363Z"
                fill="#C7CED6"
              />
              <path
                d="M120.275 35.7803C120.468 36.6398 120.504 37.5335 120.802 38.3696C120.928 38.7228 120.973 39.0976 121.009 39.4706C120.71 39.4597 120.412 39.4453 120.113 39.4273C120.032 39.121 119.942 38.8183 119.854 38.5138C119.457 38.4921 119.059 38.4669 118.664 38.521C118.409 38.8579 118.268 39.2651 118.182 39.6742C117.994 39.721 117.807 39.6363 117.619 39.6706C117.727 39.6255 117.946 39.5372 118.054 39.4922C118.003 39.1678 117.767 38.8561 117.834 38.5101C117.899 38.1155 118.034 37.7389 118.158 37.3605C118.425 37.3966 118.692 37.4326 118.96 37.4578C119.203 37.4777 119.447 37.4939 119.692 37.5083C119.699 37.2632 119.702 37.02 119.706 36.7749C119.71 36.5046 119.704 36.2344 119.701 35.9659C119.911 36.056 120.122 36.1479 120.335 36.238C120.319 36.1226 120.29 35.8938 120.275 35.7803Z"
                fill="#C7CED6"
              />
              <path
                d="M132.535 35.6597C132.973 35.9245 133.339 36.2885 133.581 36.7426C133.283 36.9642 132.986 37.1841 132.687 37.4021C132.593 37.2003 132.503 36.9967 132.413 36.7931C132.179 36.4273 132.453 36.0291 132.535 35.6597Z"
                fill="#C7CED6"
              />
              <path
                d="M166.316 38.3861C166.593 38.0419 166.993 37.8473 167.402 37.6978C167.438 38.1987 167.469 38.7014 167.491 39.2023C167.345 39.2942 167.049 39.4762 166.903 39.5663C166.687 39.1861 166.334 38.8492 166.316 38.3861Z"
                fill="#C7CED6"
              />
              <path
                d="M137.109 36.6744C137.119 36.1699 137.656 35.9591 137.982 35.6689C137.835 36.3104 137.736 36.9609 137.651 37.615C137.4 37.3501 137.022 37.0942 137.109 36.6744Z"
                fill="#C7CED6"
              />
              <path
                d="M150.248 35.7931C150.473 35.7859 150.699 35.7787 150.926 35.7715C150.969 36.667 150.915 37.5626 150.942 38.4581C150.809 37.6382 150.814 36.7968 150.563 35.9985C150.401 36.9067 150.263 37.8365 150.36 38.7572C150.374 39.1284 150.855 39.4672 151.16 39.4113C151.138 39.1627 150.982 38.8762 150.967 38.5969C151.03 38.687 151.16 38.8653 151.223 38.9554C151.6 38.9807 151.978 39.0077 152.356 39.0311C152.295 39.1482 152.232 39.2654 152.167 39.3825C152.223 39.4149 152.337 39.4834 152.392 39.5158C151.681 39.5699 150.969 39.5374 150.259 39.4852C150.045 38.2635 150.124 37.0202 150.248 35.7931Z"
                fill="#C7CED6"
              />
              <path
                d="M104.376 35.9116C104.619 36.036 104.862 36.1603 105.105 36.2828C105.111 36.9099 105.096 37.5351 105.06 38.1604C104.363 38.1946 103.664 38.1766 102.967 38.2126C102.543 38.4451 102.188 38.7766 101.849 39.1154C101.995 38.5964 102.127 38.0739 102.251 37.5495C102.484 37.5351 102.716 37.5135 102.949 37.5045C103.358 37.4757 103.767 37.4576 104.174 37.4144C104.259 37.4018 104.426 37.3766 104.511 37.3639C104.482 36.8774 104.43 36.3945 104.376 35.9116Z"
                fill="#C7CED6"
              />
              <path
                d="M152.356 39.0312C152.911 38.0077 152.524 36.786 152.911 35.7085C153.67 36.8185 153.288 38.2095 153.19 39.4492C152.924 39.4727 152.657 39.4961 152.392 39.5159C152.336 39.4835 152.223 39.415 152.167 39.3826C152.232 39.2654 152.295 39.1483 152.356 39.0312Z"
                fill="#C7CED6"
              />
              <path
                d="M157.946 36.4309C158.135 36.175 158.45 36.0579 158.713 35.8921C158.701 36.3786 158.627 36.8597 158.497 37.3264C158.261 37.3228 158.025 37.3174 157.793 37.3138C157.834 37.0183 157.861 36.7192 157.946 36.4309Z"
                fill="#C7CED6"
              />
              <path
                d="M164.465 36.098C164.845 36.0602 165.227 36.0242 165.609 35.9863C165.629 36.1953 165.654 36.4044 165.683 36.6116C165.719 36.8855 165.757 37.1594 165.795 37.4333C165.427 37.3414 165.042 37.2891 164.71 37.1035C164.579 36.7828 164.528 36.4368 164.465 36.098Z"
                fill="#C7CED6"
              />
              <path
                d="M72.9541 36.0649C73.5613 36.2145 73.7884 36.7929 73.9902 37.3155C73.7001 37.3191 73.4118 37.3227 73.1235 37.3245C73.0586 36.9064 73.001 36.4866 72.9541 36.0649Z"
                fill="#C7CED6"
              />
              <path
                d="M75.8388 37.5287C75.4099 36.9341 76.0784 36.4494 76.4605 36.0728C76.4713 36.4782 76.5001 36.889 76.4442 37.2926C76.2658 37.417 76.0694 37.5611 75.8388 37.5287Z"
                fill="#C7CED6"
              />
              <path
                d="M150.564 35.9985C150.814 36.7968 150.809 37.6383 150.942 38.4581L150.967 38.5969C150.982 38.8762 151.139 39.1627 151.16 39.4113C150.856 39.4672 150.375 39.1284 150.36 38.7572C150.263 37.8365 150.402 36.9067 150.564 35.9985Z"
                fill="#C7CED6"
              />
              <path
                d="M174.738 37.7067C175.257 37.6599 175.777 37.6239 176.298 37.5986C176.399 38.2149 176.385 38.8437 176.287 39.46C175.329 39.4492 174.359 39.5357 173.415 39.3375C174.078 39.2546 174.757 39.3104 175.413 39.1663C175.777 39.1519 175.999 38.6419 175.763 38.3662C175.46 38.0906 175.085 37.914 174.738 37.7067Z"
                fill="#C7CED6"
              />
              <path
                d="M134.586 38.2905C134.559 37.9284 134.997 37.8779 135.224 37.7031C135.363 38.2635 135.429 38.8401 135.422 39.4185C135.028 39.1555 134.541 38.8347 134.586 38.2905Z"
                fill="#C7CED6"
              />
              <path
                d="M138.956 37.6943C139.28 37.7322 139.604 37.7682 139.93 37.8024C139.93 38.3178 139.925 38.8313 139.918 39.3467C139.666 39.3719 139.413 39.3989 139.161 39.4224C139.192 39.3305 139.255 39.1449 139.285 39.053C139.345 38.8313 139.408 38.6097 139.46 38.3845C139.3 38.1484 139.127 37.9214 138.956 37.6943Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <g opacity="0.95">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M74.7493 18.0988H70.9004L70.1247 22.522H74.0024C74.6727 22.522 75.2521 22.3119 75.74 21.89C76.229 21.4691 76.5301 20.9424 76.6449 20.3104C76.7602 19.6977 76.6494 19.1755 76.3144 18.7452C75.9795 18.3144 75.4578 18.0988 74.7493 18.0988ZM75.9841 28.4105C76.0994 27.7208 75.9747 27.1569 75.611 26.7161C75.2472 26.2754 74.7012 26.0553 73.9737 26.0553H69.5216L68.6893 30.7946H73.0833C73.8301 30.7946 74.4666 30.57 74.9933 30.1193C75.52 29.6696 75.8505 29.1002 75.9841 28.4105ZM80.6091 20.1957C80.3596 21.8613 79.5075 23.1924 78.0521 24.1882C79.5269 25.2222 80.1301 26.7636 79.8618 28.8127C79.6128 30.4977 78.8422 31.8671 77.5502 32.9195C76.2573 33.9729 74.5292 34.4996 72.3652 34.4996H64.0642L67.5971 14.3936H75.2089C76.9709 14.3936 78.3781 14.9391 79.4315 16.0308C80.4844 17.122 80.8769 18.5108 80.6091 20.1957Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M92.5862 20.1382H96.694L88.9381 34.4997C87.7704 36.6831 86.4914 38.2095 85.1036 39.081C83.7148 39.9526 82.1208 40.3402 80.321 40.2448L80.9243 36.7973C81.9389 36.8162 82.7434 36.6299 83.3377 36.2374C83.9304 35.8448 84.486 35.1412 85.0032 34.1261L85.0892 33.983L81.7575 20.1382H85.5488L87.6745 29.8176L92.5862 20.1382Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M111.928 14.3936L111.268 18.1848H105.839L102.967 34.4996H99.0025L101.876 18.1848H96.4749L97.1357 14.3936H111.928Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M115.117 22.4361C116.15 20.6747 117.759 19.8131 119.942 19.8513L119.224 23.9874C118.553 23.9109 117.898 23.9541 117.256 24.1166C116.615 24.2791 115.997 24.6478 115.403 25.2222C114.81 25.7971 114.417 26.5533 114.226 27.4915L112.991 34.4996H109.286L111.813 20.138H115.519L115.117 22.4361Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M127.18 31.369C128.29 31.369 129.277 31.0202 130.138 30.3205C131 29.6219 131.555 28.6977 131.804 27.5489L131.862 27.0893C132.014 25.9216 131.77 24.9924 131.129 24.3027C130.487 23.614 129.621 23.2692 128.53 23.2692C127.399 23.2692 126.404 23.6285 125.542 24.346C124.681 25.0645 124.145 26.0458 123.934 27.29C123.723 28.497 123.924 29.4778 124.537 30.2346C125.15 30.9908 126.03 31.369 127.18 31.369ZM133.097 20.1378H136.802L134.274 34.4999H130.569L130.799 33.1786C129.554 34.3274 127.965 34.9018 126.031 34.9018C123.867 34.9018 122.259 34.1078 121.206 32.5183C120.152 30.9282 119.827 29.0813 120.229 26.974C120.592 24.9253 121.517 23.2071 123.001 21.8183C124.484 20.4305 126.261 19.7363 128.329 19.7363C130.358 19.7363 131.833 20.5115 132.752 22.0628L133.097 20.1378Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M144.643 31.369C145.811 31.369 146.83 30.9958 147.702 30.2485C148.573 29.5022 149.105 28.5352 149.296 27.3477C149.526 26.103 149.325 25.1122 148.693 24.3748C148.061 23.6379 147.171 23.2692 146.022 23.2692C144.892 23.2692 143.887 23.6285 143.006 24.346C142.125 25.0645 141.579 26.0458 141.368 27.29C141.158 28.497 141.363 29.4778 141.986 30.2346C142.608 30.9908 143.494 31.369 144.643 31.369ZM151.594 14.3936H155.299L151.766 34.4996H148.061L148.291 33.1208C147.046 34.3083 145.438 34.9016 143.465 34.9016C141.301 34.9016 139.693 34.1076 138.64 32.518C137.587 30.928 137.262 29.0811 137.663 26.9738C138.027 24.9251 138.951 23.2069 140.435 21.8181C141.919 20.4302 143.695 19.7361 145.763 19.7361C147.832 19.7361 149.325 20.5306 150.244 22.1202L151.594 14.3936Z"
                  fill="#C7CED6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M159.779 25.9399H167.076C167.056 24.9635 166.764 24.241 166.199 23.7715C165.634 23.3024 164.911 23.0679 164.031 23.0679C163.073 23.0679 162.226 23.3173 161.488 23.8147C160.751 24.3131 160.182 25.0207 159.779 25.9399ZM158.631 21.8183C160.258 20.4305 162.135 19.7363 164.261 19.7363C166.386 19.7363 168.062 20.4682 169.287 21.9336C170.513 23.3984 170.924 25.1936 170.522 27.3188C170.483 27.7402 170.35 28.248 170.12 28.8413H159.263C159.339 29.76 159.694 30.4403 160.326 30.8805C160.958 31.3213 161.752 31.5409 162.709 31.5409C164.146 31.5409 165.323 31.0529 166.242 30.0761L168.857 32.1729C167.248 33.9925 165.103 34.9018 162.422 34.9018C160.048 34.9018 158.243 34.1456 157.008 32.6331C155.773 31.12 155.337 29.2532 155.701 27.0316C156.026 24.9447 157.003 23.2071 158.631 21.8183Z"
                  fill="#C7CED6"
                />
              </g>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M48.3157 18.2861L45.7651 24.5394C46.3625 25.3218 46.8343 26.2032 47.1427 27.1559L48.3439 30.8673C48.3487 30.8821 48.3509 30.8968 48.3552 30.9116L53.5054 18.2861H48.3157Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.0678 5.91855C56.7307 5.68221 56.2107 5.69221 56.4187 5.88857C57.2668 6.68966 57.7959 7.82484 57.7959 9.08382C57.7959 11.5058 55.8375 13.4703 53.4177 13.4807H40.8569C42.3027 14.4134 43.4318 15.8236 43.9983 17.5444L44.2399 18.2782C44.2407 18.2808 44.2416 18.2838 44.2425 18.286H48.3157H53.3981V18.3012C57.0495 18.1765 59.9724 15.1785 59.9724 11.4967C59.9724 9.18852 58.8242 7.14973 57.0678 5.91855Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.0757 22.3525L34.7124 33.7372C35.4222 35.4802 37.0966 36.6062 38.9785 36.6062H43.2737C44.5801 36.6062 45.8187 35.9754 46.5872 34.9185C47.3561 33.8623 47.5742 32.4891 47.1723 31.2466L45.9711 27.5348V27.5344C44.9672 24.4347 42.1043 22.3521 38.846 22.3521L30.0757 22.3525ZM37.2214 27.1573H38.8461C40.0135 27.1573 41.0396 27.9037 41.3993 29.0145L42.117 31.2323C42.2078 31.5134 41.9988 31.801 41.7038 31.801H39.1129L37.2214 27.1573Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36.6816 18.2861C37.4031 18.2861 38.0387 18.7466 38.2646 19.4313L38.5057 20.1655C38.6747 20.679 38.59 21.2251 38.2729 21.663C37.9557 22.1009 37.464 22.352 36.9235 22.352H35.2618L33.9933 19.2475H28.8022L32.034 27.1572H36.9231H36.924C39.0236 27.1572 40.9346 26.1811 42.1658 24.4798C43.3974 22.7782 43.7267 20.6586 43.0703 18.6637L42.8288 17.9295C42.5607 17.1153 41.8813 16.833 41.0676 16.8325C39.2208 16.8325 36.6816 18.2861 36.6816 18.2861Z"
                fill="#C7CED6"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.3682 5.74121C25.2222 5.74469 25.0106 5.80116 24.8434 5.91846C23.087 7.14964 21.9392 9.18843 21.9392 11.4966C21.9392 15.1784 24.8616 18.176 28.5135 18.3007V18.2859H33.6003H36.6817C37.4033 18.2859 38.0389 18.7464 38.2648 19.4311L38.5059 20.1652C38.6492 20.6005 38.5997 21.0558 38.3907 21.4525L43.0483 22.7462C43.4945 21.4412 43.5188 20.0254 43.0704 18.6634L42.8289 17.9292C41.9531 15.2683 39.4833 13.4806 36.6817 13.4806H36.3359H28.5135H28.4961C26.075 13.4715 24.1153 11.5066 24.1153 9.08374C24.1153 7.82475 24.6449 6.68958 25.4929 5.88805C25.5906 5.79552 25.5272 5.74469 25.3969 5.74121H25.3682Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
          <div class="swiper-item-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="194"
              height="46"
              viewBox="0 0 194 46"
              fill="none"
            >
              <path
                d="M20.2443 7.73458C24.5533 7.40916 28.9657 8.97972 32.0875 11.9678C34.855 14.5408 36.6282 18.17 36.9398 21.9385C37.5011 26.7867 35.5044 31.8196 31.8669 35.0545C29.4883 37.2511 26.39 38.6355 23.1827 39.0368C20.8965 39.2546 18.5358 39.125 16.3641 38.3294C13.6394 37.4676 11.2167 35.7702 9.3524 33.6191C7.01519 30.8723 5.65422 27.3093 5.61423 23.6994C5.39913 18.5782 8.04935 13.5039 12.2343 10.5889C14.5743 8.9287 17.3789 7.91521 20.2443 7.73458ZM19.8968 8.91905C16.6288 9.21137 13.496 10.6826 11.1463 12.9661C9.44617 14.647 8.11002 16.7208 7.39438 19.0098C6.43054 21.9895 6.53671 25.2782 7.55571 28.2304C8.69467 31.508 11.0498 34.332 14.0503 36.068C17.6602 38.1556 22.1857 38.6134 26.1114 37.1794C28.559 36.3534 30.7431 34.8187 32.4171 32.8593C34.5571 30.3635 35.8133 27.1245 35.9084 23.8373C35.9112 21.5745 35.4865 19.2924 34.513 17.2406C33.2031 14.4649 31.0272 12.0891 28.3232 10.6247C25.7722 9.2293 22.7897 8.6088 19.8968 8.91905Z"
                fill="#C7CED6"
              />
              <path
                d="M95.0987 12.7829C96.2584 12.5637 97.447 12.6133 98.608 12.7995C98.608 14.0115 98.6053 15.2222 98.6094 16.4342C97.8924 16.3515 97.1574 16.3598 96.45 16.5087C95.9495 16.6038 95.5096 16.9982 95.3759 17.4918C95.249 17.9165 95.289 18.3661 95.2821 18.8032C96.3894 18.8004 97.498 18.8032 98.6066 18.8018C98.608 20.0304 98.6053 21.2576 98.6066 22.4862C97.498 22.4931 96.3894 22.4876 95.2821 22.4903C95.2835 26.2933 95.2821 30.0963 95.2821 33.8993C93.775 33.8993 92.2679 33.902 90.7607 33.8979C90.7621 30.0935 90.7635 26.2905 90.7607 22.4876C90.0037 22.4889 89.2467 22.4862 88.4897 22.4917C88.4856 21.2617 88.4842 20.0318 88.4883 18.8032C89.2453 18.8018 90.0024 18.8004 90.7607 18.8032C90.7111 17.4822 90.8683 16.0964 91.6019 14.963C92.3603 13.7578 93.7047 12.987 95.0987 12.7829Z"
                fill="#C7CED6"
              />
              <path
                d="M58.0079 12.9426C59.6239 12.9426 61.24 12.9385 62.856 12.9454C62.856 18.5161 62.856 24.0882 62.856 29.6603C65.8606 29.6575 68.8666 29.6562 71.8712 29.6617C71.8698 31.0737 71.8671 32.4856 71.8726 33.8976C67.2519 33.9018 62.6299 33.899 58.0079 33.899C58.0065 26.9135 58.0065 19.9281 58.0079 12.9426Z"
                fill="#C7CED6"
              />
              <path
                d="M111.343 12.9743C112.86 12.9757 114.378 12.9716 115.895 12.9771C115.892 15.397 115.898 17.8184 115.891 20.2397C116.685 19.1297 118.005 18.4678 119.356 18.3851C120.829 18.2886 122.401 18.454 123.674 19.2607C124.738 19.9212 125.296 21.1332 125.575 22.3122C125.878 23.6704 125.867 25.0713 125.862 26.4544C125.862 28.935 125.865 31.417 125.86 33.8976C124.355 33.9004 122.848 33.9004 121.342 33.8976C121.34 31.3246 121.343 28.7516 121.34 26.1786C121.336 25.2382 121.401 24.2247 120.91 23.3822C120.469 22.61 119.511 22.3522 118.678 22.3563C117.797 22.3218 116.791 22.5962 116.313 23.3987C115.789 24.2288 115.865 25.2437 115.862 26.1827C115.863 28.753 115.859 31.3246 115.865 33.8962C114.358 33.9045 112.851 33.8976 111.343 33.899C111.342 26.9246 111.339 19.9488 111.343 12.9743Z"
                fill="#C7CED6"
              />
              <path
                d="M102.051 18.8027C102.046 17.3314 102.055 15.8615 102.047 14.3916C103.554 14.3861 105.061 14.3889 106.57 14.3889C106.571 15.8601 106.568 17.3314 106.57 18.8013C107.717 18.8013 108.866 18.8013 110.014 18.8013C110.014 20.0299 110.014 21.2585 110.013 22.4885C108.864 22.4885 107.717 22.4899 106.57 22.4885C106.571 24.452 106.563 26.4155 106.575 28.3805C106.56 29.0561 106.778 29.8352 107.433 30.1537C108.253 30.4805 109.158 30.3785 110.013 30.2764C110.016 31.494 110.013 32.7102 110.014 33.9277C108.885 34.1925 107.712 34.1939 106.563 34.0767C105.074 33.9429 103.525 33.2231 102.761 31.8842C102.21 30.9631 102.054 29.8655 102.051 28.8065C102.048 26.701 102.05 24.5954 102.051 22.4885C101.293 22.4885 100.534 22.4885 99.7774 22.4871C99.7787 21.2585 99.7746 20.0299 99.7788 18.8013C100.536 18.8027 101.293 18.7986 102.051 18.8027Z"
                fill="#C7CED6"
              />
              <path
                d="M132.868 18.4885C134.637 18.2582 136.46 18.3092 138.192 18.7629C139.329 19.0856 140.428 19.7736 140.992 20.845C141.81 22.2942 141.697 24.0165 141.704 25.6215C141.705 28.3806 141.701 31.1398 141.704 33.899C140.326 33.9003 138.949 33.899 137.571 33.9003C137.573 33.2661 137.57 32.6331 137.571 31.9989C136.618 33.255 135.162 34.1072 133.592 34.2795C132.028 34.4436 130.316 34.3306 129.003 33.3736C128.02 32.731 127.471 31.5976 127.282 30.4683C127.094 29.1528 127.22 27.6884 128.079 26.6157C128.952 25.5277 130.329 25.0382 131.648 24.7431C133.157 24.4811 134.689 24.3832 136.209 24.1943C136.559 24.1185 136.969 24.0137 137.159 23.6773C137.421 23.2691 137.29 22.6996 136.967 22.3673C136.412 21.8861 135.639 21.8392 134.942 21.8075C134.261 21.8158 133.517 21.8406 132.949 22.2611C132.632 22.4914 132.533 22.8858 132.46 23.2484C130.997 23.2498 129.534 23.2553 128.071 23.2457C128.131 22.0433 128.54 20.8105 129.447 19.9805C130.343 19.0538 131.641 18.716 132.868 18.4885ZM134.824 27.5147C133.923 27.6802 132.929 27.6664 132.147 28.2055C131.288 28.7723 131.427 30.2876 132.363 30.7013C133.144 31.0019 134.012 30.9026 134.812 30.7275C135.765 30.5165 136.716 29.9746 137.145 29.0604C137.453 28.4606 137.386 27.7712 137.392 27.1217C136.549 27.3272 135.677 27.3616 134.824 27.5147Z"
                fill="#C7CED6"
              />
              <path
                d="M147.812 20.5182C148.525 19.1876 150.014 18.4774 151.476 18.3795C153.02 18.2913 154.671 18.4554 155.989 19.3351C156.881 19.9239 157.389 20.9263 157.696 21.9219C158.067 23.2608 158.084 24.6659 158.085 26.0461C158.086 28.6633 158.084 31.2804 158.086 33.8976C156.579 33.9017 155.072 33.8989 153.565 33.8989C153.565 31.0474 153.566 28.1958 153.564 25.3457C153.551 24.5335 153.441 23.6414 152.86 23.0222C152.288 22.4597 151.43 22.3383 150.662 22.3604C149.896 22.3728 149.063 22.6334 148.608 23.2884C148.185 23.8854 148.08 24.6369 148.085 25.3512C148.085 28.2 148.081 31.0474 148.087 33.8962C146.578 33.9017 145.07 33.9003 143.562 33.8962C143.565 28.866 143.561 23.8344 143.564 18.8028C144.981 18.8001 146.398 18.8001 147.815 18.8028C147.814 19.3737 147.816 19.9459 147.812 20.5182Z"
                fill="#C7CED6"
              />
              <path
                d="M161.894 19.4826C163.333 18.5395 165.115 18.3326 166.798 18.3644C168.436 18.4485 170.247 18.6346 171.499 19.815C172.478 20.6768 172.916 21.9798 173.013 23.2498C171.55 23.2553 170.087 23.2442 168.625 23.2539C168.49 22.7285 168.188 22.1894 167.63 22.0363C166.767 21.8019 165.801 21.8033 164.971 22.1535C164.352 22.3976 164.18 23.3091 164.683 23.7503C165.338 24.2274 166.187 24.2108 166.955 24.3322C168.191 24.4673 169.415 24.7128 170.59 25.1195C171.724 25.5304 172.759 26.3881 173.109 27.5753C173.478 28.8412 173.48 30.26 172.907 31.4666C172.479 32.4539 171.576 33.1447 170.63 33.6011C169.294 34.1678 167.824 34.3112 166.387 34.3416C164.545 34.3305 162.583 34.1292 161.037 33.033C159.853 32.1629 159.279 30.6847 159.265 29.2466C160.748 29.2438 162.231 29.2493 163.715 29.2438C163.792 29.9512 164.296 30.5703 165 30.7289C166.066 30.9784 167.251 30.9812 168.271 30.5496C169.079 30.2214 169.006 28.9349 168.199 28.6523C167.057 28.2041 165.797 28.2248 164.604 27.9931C163.245 27.7849 161.834 27.3506 160.869 26.3178C160.079 25.4698 159.838 24.2646 159.869 23.1395C159.872 21.682 160.648 20.2479 161.894 19.4826Z"
                fill="#C7CED6"
              />
              <path
                d="M180.16 18.4898C181.769 18.2775 183.422 18.3023 185.012 18.6484C186.264 18.8911 187.501 19.553 188.193 20.6561C188.843 21.6792 188.996 22.9243 189 24.1129C189 27.3754 189.001 30.6365 188.998 33.8989C187.621 33.9003 186.245 33.8989 184.867 33.9003C184.864 33.2715 184.869 32.6441 184.866 32.0167C183.899 33.2577 182.456 34.1127 180.885 34.2809C179.321 34.4408 177.607 34.3305 176.297 33.3694C174.673 32.2649 174.258 30.0435 174.63 28.2234C174.863 27.0155 175.728 25.991 176.83 25.467C178.504 24.5928 180.43 24.5542 182.265 24.3322C182.933 24.2343 183.666 24.2922 184.259 23.913C184.756 23.5076 184.673 22.672 184.181 22.3011C183.63 21.8778 182.904 21.8364 182.239 21.8088C181.558 21.8198 180.811 21.8364 180.244 22.2639C179.931 22.4955 179.838 22.8926 179.756 23.2498C178.297 23.2553 176.839 23.2401 175.383 23.2567C175.436 22.0225 175.881 20.7636 176.812 19.9184C177.701 19.0331 178.964 18.7146 180.16 18.4898ZM182.078 27.5216C181.188 27.6801 180.212 27.6719 179.445 28.2082C178.781 28.6357 178.728 29.6257 179.132 30.2476C179.494 30.824 180.247 30.8929 180.864 30.8902C182.134 30.8474 183.552 30.4641 184.291 29.3431C184.738 28.6881 184.703 27.8732 184.689 27.1175C183.833 27.3326 182.945 27.3644 182.078 27.5216Z"
                fill="#C7CED6"
              />
              <path
                d="M82.7687 18.8014C84.2745 18.8014 85.7816 18.8 87.2874 18.8027C87.2929 23.8343 87.2887 28.8659 87.2901 33.8988C85.8712 33.8988 84.4524 33.903 83.0335 33.8961C83.039 33.31 83.0307 32.7254 83.0418 32.1407C82.504 33.0977 81.5815 33.7982 80.5239 34.0808C78.9961 34.4904 77.3207 34.4145 75.8467 33.8354C74.7767 33.4396 73.9438 32.5434 73.515 31.4982C73.0296 30.2944 72.8918 28.9817 72.8904 27.6924C72.8835 24.7292 72.8931 21.766 72.8862 18.8014C74.3934 18.8 75.9005 18.8014 77.4076 18.8027C77.4145 21.6722 77.4035 24.5417 77.4131 27.4111C77.4173 28.1695 77.5234 28.99 78.0433 29.5829C78.5452 30.1482 79.345 30.3096 80.0703 30.3123C80.859 30.3247 81.7346 30.0876 82.213 29.4133C82.675 28.8107 82.7563 28.0247 82.766 27.2898C82.7701 24.4603 82.7646 21.6308 82.7687 18.8014Z"
                fill="#C7CED6"
              />
              <path
                d="M8.37476 19.4883C9.10695 19.793 9.84327 20.0853 10.5755 20.3887C10.8885 20.1474 11.2042 19.9075 11.5159 19.6634C13.5718 20.6052 15.625 21.5497 17.6823 22.4874C17.4616 22.6487 17.241 22.8114 17.0204 22.9741C16.0055 22.5356 14.9865 22.1027 13.973 21.66C13.8365 21.7855 13.6986 21.9124 13.5621 22.0392C14.9907 22.8748 16.4675 23.7628 18.1442 23.9614C19.5879 23.9807 21.0109 23.3519 22.0092 22.3136C22.7731 21.5732 23.3536 20.6631 23.8087 19.7061C27.3538 19.3931 30.9334 19.5958 34.4371 20.2136C34.1834 20.4563 33.9311 20.6976 33.6788 20.9403C31.3788 20.5169 29.0388 20.3873 26.7043 20.3859C26.5885 20.6038 26.4727 20.823 26.3582 21.0423C28.5989 21.012 30.8396 21.1829 33.0541 21.5208C32.8128 21.751 32.5729 21.9841 32.333 22.2143C30.2233 21.8462 28.0736 21.7883 25.9377 21.809C25.8081 22.0461 25.6757 22.2805 25.5433 22.5163C27.5965 22.4281 29.6579 22.5384 31.6918 22.8335C31.4587 23.0665 31.2216 23.294 30.9803 23.5174C28.9892 23.1851 26.9594 23.2306 24.9504 23.3133C24.7573 23.5436 24.5671 23.7752 24.3768 24.0069C26.3569 23.8938 28.3383 23.9628 30.3129 24.1379C30.0688 24.3861 29.8179 24.6288 29.5683 24.8701C28.57 24.757 27.5689 24.6426 26.5637 24.7157C24.7684 24.7419 22.8931 25.0176 21.357 26.0049C22.6904 27.4307 24.5478 28.2291 26.4217 28.662C27.5068 28.873 28.6155 28.9475 29.72 28.975C29.2787 29.386 28.8389 29.7982 28.399 30.2119C26.8436 29.8948 25.3103 29.4756 23.7976 28.9957C21.4066 28.1863 19.1494 27.0198 17.0342 25.6492C15.3919 24.5336 13.8021 23.3409 12.1143 22.2943C10.905 21.6076 9.67781 20.9513 8.40509 20.3915C8.39544 20.0895 8.38579 19.7889 8.37476 19.4883Z"
                fill="#C7CED6"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
@keyframes move-left {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes move-left {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes move-right {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-webkit-keyframes move-right {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
.app-home-wrapper {
  height: 100%;
  width: 100%;
  .section-item {
    display: flex;
    .inner-container {
      flex: 1;
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      @media screen and (max-width: 1280px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  .section-one {
    padding-top: 226px;
    min-height: 836px;
    background: linear-gradient(
        90deg,
        #248de2 36.46%,
        rgba(52, 162, 252, 0) 101.22%
      ),
      url("/images/section-one-bg.png") no-repeat top right;
    background-size: 100% 100%, 1324px 882px;
    @media screen and (max-width: 1324px) {
      background-size: 100% 100%, cover 100%;
    }
    .title-text-container {
      width: 709px;
      font-size: 70px;
      font-weight: 350;
      line-height: 70px;
      color: #ffffff;
      @media screen and (max-width: 930px) {
        width: 100%;
      }
      @media screen and (max-width: 540px) {
        font-size: 50px;
      }
    }
    .summary-text-container {
      margin-top: 40px;
      width: 580px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
      @media screen and (max-width: 930px) {
        width: 100%;
      }
    }
    .contact-us-button {
      display: block;
      margin-top: 20px;
      min-width: 130px;
      width: 0;
      height: 42px;
      padding: 0px 10px;
      border-radius: 8px;
      background: #000;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 1;
      }
    }
  }
  .section-two {
    min-height: 732px;
    background: #000;
    padding-top: 80px;
    padding-bottom: 80px;
    .title-text-container {
      font-size: 32px;
      font-weight: 800;
      line-height: 44px;
      color: #ffffff;
      text-align: left;
    }
    .summary-text-container {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
    }
    .content-container {
      margin-top: 40px;
      min-height: 450px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      @media screen and (max-width: 930px) {
        flex-direction: column-reverse;
      }
      .content-left-part {
        padding-top: 50px;
        padding-bottom: 50px;
        flex: 1;
        .paragraph-wrapper {
          margin-top: 40px;
          &:nth-child(1) {
            margin-top: 0 !important;
          }
          .paragraph-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            color: #ffffff;
          }
          .paragraph-content {
            margin-top: 20px;
            .content-row-item {
              font-size: 18px;
              font-weight: 400;
              line-height: 22px;
              color: #8c939d;
              margin-top: 10px;
              &:nth-child(1) {
                margin-top: 0 !important;
              }
            }
          }
        }
      }
      .content-right-part {
        width: 404px;
        height: 380px;
        flex-shrink: 0;
        border-radius: 20px;
        overflow: hidden;
        background: url("/images/section-two-bg.png") no-repeat center center;
        background-size: 100% 100%;
        @media screen and (max-width: 540px) {
          width: 269px;
          height: 253px;
        }
      }
    }
  }
  .section-three {
    min-height: 1732px;
    background: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    .title-text-container {
      font-size: 32px;
      font-weight: 800;
      line-height: 44px;
      color: #23262f;
      text-align: left;
    }
    .summary-text-container {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
      color: #8c939d;
    }
    .content-container {
      margin-top: 40px;
      width: 100%;
      .content-list-wrapper {
        margin-top: 30px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
        gap: 30px 40px;
        align-items: stretch;
        &:nth-child(1) {
          margin-top: 0 !important;
        }
        @media screen and (max-width: 1100px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 16px 20px;
        }
        .list-item-wrapper {
          width: 100%;
          min-height: 329px;
          overflow: hidden;
          border-radius: 10px;
          padding: 50px 20px;
          &.image-h {
            height: 329px;
          }
          &.rtl-row {
            @media screen and (max-width: 1100px) {
              &:nth-child(1) {
                order: 1;
              }
              &:nth-child(2) {
                order: 0;
              }
            }
          }
          &.bg-one {
            background: url("/images/section-third-one.png") no-repeat center
              center;
            background-size: 100% 100%;
          }
          &.bg-two {
            background: url("/images/section-third-two.png") no-repeat center
              center;
            background-size: 100% 100%;
          }
          &.bg-three {
            background: url("/images/section-third-three.png") no-repeat center
              center;
            background-size: 100% 100%;
          }
          &.bg-four {
            background: url("/images/section-third-four.png") no-repeat center
              center;
            background-size: 100% 100%;
          }
          .part-title {
            line-height: 34px;
            font-size: 24px;
            font-weight: 800;
            color: #23262f;
          }
          .part-summary {
            margin-top: 20px;
            color: #8c939d;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
          }
          .detail-info-wrapper {
            margin-top: 30px;
            .detail-title {
              color: #8c939d;
              font-size: 18px;
              font-weight: 400;
              line-height: 22px;
            }
            .detail-content {
              margin-top: 4px;
              .detail-row-item {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                .icon-wrapper {
                  flex-shrink: 0;
                  height: 22px;
                  width: 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .dot-icon {
                    flex-shrink: 0;
                    height: 4px;
                    width: 4px;
                    border-radius: 50%;
                    background: #8c939d;
                    overflow: hidden;
                  }
                }
                .text-wrapper {
                  flex: 1;
                  color: #8c939d;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
  .section-four {
    min-height: 1028px;
    background: #f6f6f6;
    padding-top: 80px;
    padding-bottom: 80px;
    .title-text-container {
      font-size: 32px;
      font-weight: 800;
      line-height: 44px;
      color: #23262f;
      text-align: left;
    }
    .summary-text-container {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
      color: #8c939d;
    }
    .content-container {
      padding-top: 32px;
      width: 100%;
      .content-list-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
        gap: 32px 32px;
        align-items: stretch;
        @media screen and (max-width: 1100px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 20px 20px;
        }
        .list-item-wrapper {
          min-height: 361px;
          width: 100%;
          border-radius: 10px;
          background: #fff;
          overflow: hidden;
          .item-up-bg {
            height: 220px;
            &.bg-one {
              background: url("/images/section-four-one.png") no-repeat center
                center;
              background-size: 100% 100%;
            }
            &.bg-two {
              background: url("/images/section-four-two.png") no-repeat center
                center;
              background-size: 100% 100%;
            }
            &.bg-three {
              background: url("/images/section-four-three.png") no-repeat center
                center;
              background-size: 100% 100%;
            }
            &.bg-four {
              background: url("/images/section-four-four.png") no-repeat center
                center;
              background-size: 100% 100%;
            }
          }
          .item-down-container {
            padding: 20px;
            .down-title {
              line-height: 28px;
              font-size: 20px;
              font-weight: 800;
              color: #23262f;
              text-align: left;
            }
            .down-summary {
              margin-top: 14px;
              line-height: 22px;
              font-size: 18px;
              font-weight: 400;
              color: #8c939d;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .section-five {
    min-height: 818px;
    padding-top: 80px;
    padding-bottom: 80px;
    background: url("/images/section-five-bg.png") no-repeat top right, #ffffff;
    background-size: 700px 692px, 100% 100%;
    background-position: calc(100% - 31px) 53px;
    @media screen and (max-width: 1280px) {
      background-size: 0px 0px, 100% 100%;
    }
    .title-text-container {
      font-size: 32px;
      font-weight: 800;
      line-height: 44px;
      color: #23262f;
      text-align: left;
    }
    .summary-text-container {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
      color: #8c939d;
    }
    .content-container {
      padding-top: 32px;
      width: 100%;
      .content-row-item {
        min-height: 246px;
        width: 100%;
        max-width: 654px;
        border-radius: 20px;
        background: rgba(232, 243, 255, 0.5);
        margin-top: 30px;
        padding: 30px 40px;
        &:nth-child(1) {
          margin-top: 0 !important;
        }
        .item-up-wrapper {
          height: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 30px;
          .up-left {
            flex-shrink: 0;
            width: 56px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
          .up-right {
            flex-shrink: 0;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            &.icon-one {
              background: url("/images/section-five-one.png") no-repeat center
                center;
              background-size: 100% 100%;
            }
            &.icon-two {
              background: url("/images/section-five-two.png") no-repeat center
                center;
              background-size: 100% 100%;
            }
          }
        }
        .item-down-wrapper {
          margin-top: 20px;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          color: #8c939d;
        }
      }
    }
  }
  .partners-show-container {
    height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #f6f6f6;
    position: relative;
    overflow: hidden;
    .swiper-outer-wrapper {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;
      cursor: pointer;
      &:hover {
        .swiper-inner-container {
          animation-play-state: paused !important;
          -webkit-animation-play-state: paused !important;
        }
      }
      .swiper-inner-container {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;
        animation: 40s move-left linear infinite;
        -webkit-animation: 40s move-left linear infinite;
        .swiper-item-wrapper {
          flex-shrink: 0;
        }
      }
    }
  }
}
</style>
